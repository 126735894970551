export default {
    listingGreece: {
        mostRated: '最高评分',
        mostViewed: '最多浏览',
        newListings: '最新房源',
        highRated: '高评分',
        openAdvanceFilter: '打开高级筛选',
        selectPropertyType: '选择物业类型',
        apartment: '公寓',
        condo: '共管公寓',
        family: '家庭',
        detachedHouse: '独立屋',
        villa: '别墅',
        selectPriceRange: '选择价格范围',
        lessThan100K: '少于€100K',
        from100KTo200K: '€100K - €200K',
        from200KTo300K: '€200K - €300K',
        from300KTo400K: '€300K - €400K',
        above500K: '超过€500K',
        selectRoomType: '选择房间类型',
        selectBathroomType: '选择浴室类型',
        minArea: '最小面积',
        maxArea: '最大面积',
        minPrice: '最低价格',
        maxPrice: '最高价格',
        findNewHome: '寻找新家',
        resetFilter: '重置筛选',
        beds: '床位',
        baths: '浴室',
        viewDetail1: '查看详情',
        viewDetail2: '查看详情',
        sortBy: 'Sort By',
    },    
    listingMalaysia: {
        mostRated: '最高评分',
        mostViewed: '最多浏览',
        newListings: '最新房源',
        highRated: '高评分',
        openAdvanceFilter: '打开高级筛选',
        selectPropertyType: '选择物业类型',
        apartment: '公寓',
        condo: '共管公寓',
        family: '家庭',
        detachedHouse: '独立屋',
        villa: '别墅',
        selectPriceRange: '选择价格范围',
        lessThan100K: '少于RM100K',
        from100KTo200K: 'RM100K - RM200K',
        from200KTo300K: 'RM200K - RM300K',
        from300KTo400K: 'RM300K - RM400K',
        above500K: '超过RM500K',
        selectRoomType: '选择房间类型',
        selectBathroomType: '选择浴室类型',
        minArea: '最小面积',
        maxArea: '最大面积',
        minPrice: '最低价格',
        maxPrice: '最高价格',
        findNewHome: '寻找新家',
        resetFilter: '重置筛选',
        beds: '床位',
        baths: '浴室',
        viewDetail1: '查看详情',
        viewDetail2: '查看详情',
        sortBy: 'Sort By',
    },    
    header: {
        login: '登录',
        logout: '登出',
        wechat: '微信',
        whatsapp: 'WhatsApp',
        country: '国家',
        residential: '住宅',
        commercial: '商业',
        land: '土地',
        study_overseas: '海外留学',
        join_us: '加入我们',
        global_visa: '环球签证服务',
        greece: '希腊',
        malaysia: '马来西亚',
        profile: '个人资料',
        welcome_user: '欢迎用户',
        my_profile: '我的资料',
        my_dashboard: '我的仪表板',
        change_my_password: '更改我的密码',
        saved_property: '已保存的物业',
        singapore:'新加玻',
        add_property: '加房产广告',
        compareSelectedProperty: '比较与已选物业',
        compareProperty: '比较物业',
        savedProperty: '保存的物业',
        viewCompareProperty: '查看并比较',
        viewSavedProperty: '查看保存的物业',
        dropMessage: '发送消息',
        subject: '主题',
        messages: '消息内容',
        sendMessage: '发送消息',
    },
    change_password: {
        change_password: '更改密码',
        edit_change_password: '编辑或更改密码',
        dashboard: '仪表板',
        my_profile: '我的资料',
        saved_property: '已保存的房产',
        my_properties: '我的房产',
        my_subscription: '我的订阅',
        add_new_property: '添加新房产',
        change_password_label: '更改密码',
        change_your_password: '更改您的密码',
        old_password: '旧密码',
        new_password: '新密码',
        confirm_password: '确认密码',
        save_changes: '保存更改',
    },
    user_dashboard: {
        dashboard: '仪表板',
        welcome_message: '您好，欢迎回来',
        my_profile: '我的资料',
        saved_property: '已保存的房产',
        my_properties: '我的房产',
        my_subscription: '我的订阅',
        submit_new_property: '提交新房产',
        change_password: '更改密码',
        current_subscription: '您的当前订阅:',
        membership_type: '铂金会员',
        listed_property: '已列出房产',
        earned_this_week: '本周收入',
        earned_this_month: '本月收入',
        extra_area_chart: '额外区域图表',
        notifications: '通知',
        listing_status: '房产状态',
        title: '标题',
        listing_id: '房产编号',
        status: '状态',
        price: '价格',
        date_created: '创建日期',
    },
    user_profile: {
        my_profile: '我的资料',
        my_account_profile: '我的账户和资料',
        dashboard: '仪表板',
        saved_property: '已保存的房产',
        my_properties: '我的房产',
        my_subscription: '我的订阅',
        submit_new_property: '提交新房产',
        change_password: '更改密码',
        full_name: '您的全名',
        email: '电子邮件',
        selectTitle: '选择称谓',
        mr: '先生',
        ms: '女士',
        mrs: '夫人',
        title: '您的头衔',
        country_code: '国家代码',
        searchHere: '搜索',
        select_country_code: '选择代码',
        us_code: '+1 (美国/加拿大)',
        uk_code: '+44 (英国)',
        china_code: '+86 (中国)',
        greece_code: '+30 (希腊)',
        australia_code: '+61 (澳大利亚)',
        japan_code: '+81 (日本)',
        sg_code: '+65 (新加坡)',
        my_code: '+60 (马来西亚)',
        phone_number: '电话号码',
        address: '地址',
        yes: 'Yes',
        no: 'No',
        city: '城市',
        state: '州/省',
        zipcode: '邮政编码',
        agency_name: '机构名称',
        agency_code: '机构代码',
        agent_code: '代理代码',
        property_outside_country: '您在海外有房产吗？',
        selectYesorNo: '有/没有',
        about_me: '关于我',
        social_accounts: '社交账户',
        facebook: 'Facebook链接',
        whatsapp: 'WhatsApp',
        instagram: 'Instagram',
        tiktok: '抖音',
        youtube: 'YouTube',
        save_changes: '保存更改',
        whatsapp_number: 'WhatsApp 号码',
        instagram_username: 'Instagram 代码',
    },
    visa: {
        enquire_now: '立即咨询',
        global_visa_services: '全球签证服务',
        greece_header: '希腊黄金签证计划',
        greece: '希腊',
        greece_description: '仅需投资25万欧元即可获得永久居留权，享受欧盟福利，移民成本低，性价比高。',
        greece_title1: '性价比高',
        greece_text1: '为三代人投资（申请人、配偶和子女），无需包括父母。',
        greece_title2: '投资门槛低',
        greece_text2: '仅需25万欧元购置房产即可直接获得希腊永久居留权。',
        greece_title3: '无居住要求',
        greece_text3: '获得永久居留权后无需居住在希腊。',
        greece_title4: '申请要求简单',
        greece_text4: '无需进行体检。',
        greece_title5: '低房产税',
        greece_text5: '交易税为3%，年度房产税率为0.1-0.7%，相对其他欧洲国家较低。',
        greece_title6: '投资风险低',
        greece_text6: '房产市场价值几乎减半，经济正在复苏，房价有较大的增长空间。',
        greece_title7: '旅行自由',
        greece_text7: '希腊永久居民可免签前往26个申根国家。',
        spain: '西班牙',
        spain_header: '西班牙黄金签证计划',
        spain_description: '仅需50万欧元即可为全家快速、安全地获得黄金签证，自由选择房产，房源透明。',
        spain_title1: '为子女的未来',
        spain_text1: '确保为您的子女提供更好的机会。',
        spain_title2: '投资回报',
        spain_text2: '确保您的投资获得可观回报。',
        spain_title3: '一人申请，三代受益',
        spain_text3: '一人申请，三代受益。',
        spain_title4: '便利旅行',
        spain_text4: '享受27个欧洲申根国家的免签旅行。',
        spain_title5: '适合没有时间暂时出行的客户',
        spain_text5: '适合无法频繁出行的客户。',
        spain_title6: '申请要求',
        spain_text6_1: '1. 申请人必须年满18岁。',
        spain_text6_2: '2. 申请人必须无犯罪记录。',
        spain_text6_3: '3. 申请人必须在西班牙购买价值50万欧元或以上的房产（房产类型不限，包括公寓、联排别墅、独立房屋、商业房产等）。可以购买多处房产，只要总净值超过50万欧元。',
        portugal: '葡萄牙',
        portugal_header: '葡萄牙黄金签证计划',
        portugal_description: '通过投资50万欧元的基金获取葡萄牙黄金签证。',
        portugal_title1: '安全投资',
        portugal_text1: '投资于房产安全可靠，回报更稳定。',
        portugal_title2: '欧洲身份',
        portugal_text2: '直接进入欧洲，享受26个申根国家的免签旅行。',
        portugal_title3: '居留身份',
        portugal_text3: '黄金签证持有人可以在葡萄牙生活、工作和经商。',
        portugal_title4: '家庭受益',
        portugal_text4: '一个申请可包括三代家庭成员。',
        portugal_title5: '教育和医疗',
        portugal_text5: '持证人及其家庭可享受葡萄牙的免费公共教育和免费公共医疗。',
        portugal_title6: '无居住要求',
        portugal_text6: '每年仅需居住7天，5年后可轻松转换为永久居留权，无需搬迁。',
        portugal_title7: '税收负担低',
        portugal_text7: '葡萄牙没有遗产税，也没有全球税。',
        portugal_title8: '宜居环境',
        portugal_text8: '葡萄牙气候温和，阳光充足，生活成本低，英语普及率高，适合长期居住。',
        portugal_title9: '申请要求',
        portugal_text9: '1. 申请人必须年满18岁。2. 申请人必须无犯罪记录。3. 申请人必须投资50万欧元的基金。4. 申请人每两年内至少需在葡萄牙居住14天。',
        cyprus: '塞浦路斯',
        cyprus_header: '塞浦路斯黄金签证计划',
        cyprus_description: '通过投资50万欧元的基金获取葡萄牙黄金签证。',
        cyprus_title1: '父母与子女的福利',
        cyprus_text1: '包括三代人的福利。',
        cyprus_title2: '移民成本低',
        cyprus_text2: '整体费用低。',
        cyprus_title3: '无需连续居住',
        cyprus_text3: '无需在塞浦路斯连续居住。',
        cyprus_title4: '申请要求',
        cyprus_text4: '1. 主申请人必须年满18岁且无犯罪记录。' +
            '2. 在塞浦路斯购买最低价值为30万欧元的房产。' +
            '3. 年收入必须至少为5万欧元，配偶加1.5万欧元，每个孩子加1万欧元。' +
            '（例如：一个三口之家申请需要的年收入为5万欧元 + 1.5万欧元 + 1万欧元 = 7.5万欧元/年；工资、养老金、租金、股息、股票和其他收入来源均可接受。）',
        cyprus_title5: '随行家庭成员',
        cyprus_text5: '1. 配偶（包括事实婚姻配偶）。' +
            '2. 子女：未婚18岁以下子女，以及年龄在18-25岁之间正在全日制学习的未婚子女。',
        uk: '英国',
        uk_header: '英国创新创始人签证',
        uk_description: '通过建立一个创新、可行且可扩展的企业实现英国移民身份。',
        uk_title1: '快速获得英国居留权',
        uk_text1: '快速获得英国绿卡的途径。',
        uk_title2: '低投资要求',
        uk_text2: '最低投资额为50,000英镑。',
        uk_title3: '无需商业经验',
        uk_text3: '不需要之前的创业经验。',
        uk_title4: '家庭成员包含在内',
        uk_text4: '主申请人可以在申请中包括其配偶和18岁以下的子女。',
        uk_title5: '永久居留权',
        uk_text5: '主申请人可在3年后申请永久居留权，配偶和子女可在5年后申请。',
        uk_title6: '申请要求',
        uk_text6_1: '1. 提供雅思5.5分或以上的成绩。如果申请人拥有英国认可的以英语授课的学士学位或更高学位，可以免除雅思要求。',
        uk_text6_2: '2. 至少拥有50,000英镑的可用投资资金。',
        uk_text6_3: '3. 获批后，在英国成立的企业必须展示出创新性、可行性和可扩展性。',
        uk_text6_4: '4. 通过体检，无重大健康问题。',
        uk_title7: '随行家属',
        uk_text7: '配偶、民事伴侣或未婚伴侣以及18岁以下的受抚养子女（或当前在英国的18岁以上子女）。',
        uk_title8: '处理时间',
        uk_text8: '如果申请人在英国境外，获得英国创新者和创始人签证的平均处理时间为3周；如果申请人在英国境内，平均处理时间为8周。',
        italy: '意大利',
        italy_header: '意大利黄金签证计划',
        italy_description: '通过多种投资选择为非欧盟投资者提供意大利居留权，享有在欧盟生活、工作和学习的权利。',
        italy_title1: '无需语言考试',
        italy_title2: '无需在意大利长期居留',
        italy_title3: '在意大利生活、工作和学习。',
        italy_title4: '居住在意大利，享受气候、遗产和美食均排名第一的国家的福利。',
        italy_title5: '享受世界上最好的教育和医疗系统之一。',
        italy_title6: '对意大利境外的赠与、继承和利润免税。',
        italy_title7: '持有人享有申根区内的旅行自由。',
        italy_title8: '多种投资形式。',
        italy_text8_1: '至少200万欧元的政府债券。',
        italy_text8_2: '至少50万欧元的公司债券或股票。',
        italy_text8_3: '至少25万欧元的创新初创公司。',
        italy_title9: '申请要求',
        italy_text9_1: '申请人必须年满18岁。',
        italy_text9_2: '申请人必须无犯罪记录。',
        italy_text9_3: '申请人必须持有覆盖意大利所有风险的有效健康保险。',
        italy_text9_4: '申请人必须提供投资资金的合法来源证明。',
        italy_text9_5: '申请人必须符合最低居住要求以维持和更新居留许可。',
        italy_title10: '随行家庭成员',
        italy_text10_1: '合法婚姻配偶。',
        italy_text10_2: '18岁以下的未成年子女或成年子女在校且未婚的子女。',
        italy_text10_3: '如果符合特定年龄和依赖性要求，经济上依赖于投资者的父母也可以包括在内。',
    },
    listing: {
        most_rated: '最受好评',
        most_viewed: '最多浏览',
        new_listings: '最新房源',
        high_rated: '高评分',
        select_type: '选择房产类型',
        apartment: '公寓',
        condo: '公寓楼',
        family: '家庭',
        detached_house: '独立屋',
        landed_house: '有地住宅',
        villa: '别墅',
        less_than: '少于',
        above: '高于',
        select_price_range: '选择价格范围',
        select_room_type: '选择房间类型',
        select_bath_type: '选择浴室类型',
        find_new_home: '寻找新家',
        reset_filter: '重置筛选',
        beds: '房',
        one_beds: '一房',
        two_beds: '二房',
        three_beds: '三房',
        bath: '浴室',
        view_detail: '查看详情',
        max_area: '最大面积',
        min_area: '最小面积',
        max_price: '最高价格',
        min_price: '最低价格',
        location: '地点',
        enter_price: '输入价格',
        sort_by: '排序方式：',
        open_advance_filter: '打开筛选',
    },
    not_found: {
        back_to_home: '返回首页',
        not_found_text: '此页面不可用。请使用导航菜单浏览我们的房产，或返回首页。如需进一步帮助，请随时联系客户支持。'
    },
    banner: {
        home_banner_header: '寻找您的下一所房子',
        home_banner_text: '在您所在的城市找到新房和精选房产。',
        buy: '购买',
        rent: '租赁',
        country: '国家/城市/街道',
        greece: '希腊',
        malaysia: '马来西亚',
        singapore: '新加坡',
        property_type: '房产类型',
        apartment: '公寓',
        condominium: '共管公寓',
        landed_house: '独栋别墅',
        price_range: '价格范围',
        less_than: '低于',
        from: '从',
        to: '至',
        above: '高于',
    },
    home: {
        singapore_top_viewed: '新加坡热门房产',
        singapore_top_viewed_label: '发现新加坡最受欢迎的房产。探索黄金地段、豪华设施和专属住宅，定义狮城的都市生活。',
        contact_us: '联系方式',
        home_banner_citizenship: '投资入籍',
        home_banner_successful_apps: '成功申请',
        home_banner_positive_reviews: '好评',
        home_banner_global_visa: '全球签证',
        featured_property: '特色房产类型',
        featured_property_title: '寻找所有类型的房产',
        family_house: '家庭住宅',
        all_property: '寻找所有类型的房产',
        property: '房产',
        house_villa: '住宅和别墅',
        apartment: '公寓',
        office_studio: '办公室和工作室',
        villa_condo: '别墅和公寓',
        greece_featured: '希腊特色房产',
        greece_featured_text: '探索这座位于希腊海岸的豪华别墅，体验地中海奢华的极致。',
        beds: '卧室',
        bath: '浴室',
        malaysia_featured: '马来西亚待售房产',
        malaysia_featured_text: '体验现代奢华与文化丰富的完美结合，这座位于马来西亚的精致房产。',
        citizenship_investment: '投资入籍',
        citizenship_investment_text: '我们为全球所有投资入籍申请提供专家协助，提供定制的服务体验，并通过长期合作伙伴关系提供支持和维护。',
        listings: '房源',
        dominica: '多米尼克',
        dominica_title: '多米尼克投资入籍',
        dominica_description: '只需投资10万美元即可获得国籍，享受144个国家的免签证旅行，终生持有不可撤销的国籍。',
        find_out_more: '了解更多',
        north_macedonia: '北马其顿',
        north_macedonia_title: '北马其顿投资入籍计划',
        north_macedonia_description: '快速轻松地获得北马其顿国籍，投资起步价为20万欧元，允许双重国籍，财富保护',
        grenada: '格林纳达',
        grenada_title: '格林纳达投资入籍计划',
        grenada_description: '快速且安全地为全家获得格林纳达国籍，享受资本增值、礼物、财富和遗产的免税优惠。',
        top_property_places: '顶级房产地',
        top_property_description: '在全球范围内，探索无缝和安全的房产交易，希腊、马来西亚和新加坡的顶级房产可供选择。',
        greece: '希腊',
        malaysia: '马来西亚',
        singapore: '新加坡',
        properties: '房产',
        global_visa_services: '全球签证服务',
        global_visa_text: '我们为全球范围内的各类签证申请提供专家级协助，确保流程顺畅，量身定制以满足您的具体需求。',
        greece_visa_text: '希腊黄金签证计划',
        greece_visa_description: '只需投资25万欧元即可获得永久居留权，享受欧盟福利，低移民成本，高性价比。',
        spain_visa_text: '西班牙黄金签证计划',
        spain_visa_description: '只需投资50万欧元即可快速且安全地为全家获得黄金签证，自由选择房产，透明的房源列表。',
        portugal_visa_text: '葡萄牙黄金签证计划',
        portugal_visa_description: '快速且安全地在欧洲的金融中心为全家获得黄金签证，提供透明的房产选择。',
        uk_visa_text: '英国创新者签证',
        uk_visa_description: '通过建立创新、可行且可扩展的业务，并最低投资5万英镑，获得英国移民身份。',
        italy_visa_text: '意大利黄金签证计划',
        italy_visa_description: '为非欧盟投资者提供通过多种投资选项获得意大利居留的机会，享受居住、工作和学习在欧盟的多重好处。',
        us_visa_text: '美国E-2投资者居留签证',
        us_visa_description: '美国E-2投资者居留签证是一种非移民签证，允许外国投资者及其家人进入并在美国工作。',
        cyprus_visa_text: '塞浦路斯黄金签证计划',
        cyprus_visa_description: '只需投资30万欧元即可为全家获得永久居留权，无需持续居住要求，适用于年长父母和子女。',
        portugal: '葡萄牙',
        uk: '英国',
        spain: '西班牙',
        italy: '意大利',
        us: '美国',
        cyprus: '塞浦路斯',
    },
    property: {
        submit_enquiry: '提交查询',
        reviews: '评论',
        buy_new_property: '购买新房产',
        buy_second_hand_property: '购买二手房产',
        rent_property: '租赁房产',
        property: '物业',
        about_property: '关于物业',
        property_information: '物业信息',
        open_to_foreigner: '开放外国人购买',
        tenure: '租期',
        units: '单位',
        towers: '塔楼',
        completed_in: '竣工于',
        furnished: '家具齐全',
        free_car_park: '提供免费停车位',
        title: '产权',
        airbnb_management: 'Airbnb 管理',
        facilities: '设施',
        unit_layout: '单位',
        type: '类型',
        beds: '卧室',
        baths: '浴室',
        area: '面积',
        property_video: '物业视频',
        location: '位置',
        bikeable_score: '自行车通行评分',
        some_bike_infrastructure: '部分自行车基础设施',
        view_detail: '查看详情',
        walk_scores: '步行评分',
        most_errands_accomplished_on_foot: '大部分日常事务可步行完成',
        some_transit: '部分交通',
        nearby_public_transport_options: '附近有少量公共交通选择',
        nearby: '附近',
        schools: '学校',
        walkscore: '步行评分',
        hotel_restaurant: '酒店与餐厅',
        health_medical: '健康与医疗',
        want_to_know_more: '想了解更多',
        similar_property: '类似物业',
        rent: '出租',
        sale: '出售',
        sale_price: '售价',
        down_payment: '首付款',
        loan_term: '贷款期限',
        interest_rate: '利率 (%)',
        mortgage_calculator: '按揭计算器',
        monthly_payment: '每月还款',
        calculate: '计算',
        for: '用于',
    },
    citizenship: {
        citizenship_investment: '投资入籍',
        list_of_countries: '国家列表',
        citizenship_investment_text: '投资入籍计划为外国个人及其家人通过直接投资获得归化证书并取得公民身份提供了机会。',
        dominica: '多米尼克',
        north_macedonia: '北马其顿',
        grenada: '格林纳达',
        stkitts_neviss: '圣基茨和尼维斯',
        antiguaBarbuda: '安提瓜和巴布达',
        montenegro: '黑山共和国',
        vanuatu: '瓦努阿图',
        malta: '​马耳他',
        saintLucia: '圣卢西亚',
        turkey: '土耳其',
        dominica_description: '多米尼克投资入籍计划由多米尼克联邦政府于1993年推出，允许外国投资者在无需居住要求或放弃现有国籍的情况下获得不可撤销的公民身份。',
        dominica_title1: '处理时间',
        dominica_text1: '获得多米尼克公民身份的平均处理时间为4个月。',
        dominica_title2: '免签国家',
        dominica_text2: '立即获得前往144个国家/地区的免签证旅行资格，包括申根国家、新加坡、香港和中国。',
        dominica_title3: '投资类型',
        dominica_text3: '捐赠给多米尼克政府基金或房地产投资。',
        dominica_title4: '投资费用',
        dominica_text4: '单个申请人需支付10万美元，四口之家需支付17.5万美元。',
        dominica_title5: '家庭受养人',
        dominica_text5: '配偶、30岁以下的子女、65岁以上的父母/祖父母。',
        dominica_title6: '护照有效期',
        dominica_text6: '16岁以上的人士护照有效期为10年，16岁以下的儿童护照有效期为5年。',
        dominica_title7: '良好的身份',
        dominica_text7: '多米尼克允许双重国籍，无任何限制。无需放弃当前国籍。',
        dominica_title8: '家庭福利',
        dominica_text8_1: '您和您的家人可以终生持有不可撤销的公民身份。此外，后代还可以通过血缘获得公民身份。',
        dominica_text8_2: '除了主申请人外，配偶、30岁以下的子女和65岁以上的父母/祖父母也可以获得多米尼克护照。',
        dominica_title9: '国际旅行福利',
        dominica_text9_1: '多米尼克共和国的声望护照可提供前往144个国家/地区的免签证通行权，包括英国和申根区。',
        dominica_text9_2: '多米尼克护照在英国延长居住权长达6个月，并且签证申请程序简便。',
        dominica_text9_3: '拥抱自由生活，并有权在多米尼克共和国和其他多个加勒比共同体成员国居住，时间不限。',
        dominica_title10: '税务和财务福利',
        dominica_text10_1: '无资本利得税、赠与税、财富税和遗产税；非居民无需纳税。',
        dominica_text10_2: '您可以在全球知名金融机构开设银行账户。',
        dominica_text10_3: '加勒比地区免关税贸易。',
        dominica_text10_4: '对利润回流和进口资本没有限制。',
        enquire_now: '立即咨询',
        north_macedonia_description: '北马其顿投资入籍计划使外国个人能够通过投资政府批准的基金来获得替代公民身份。北马其顿拥有欧洲最具吸引力和商业友好的投资入籍计划之一。但需注意，该计划仅接受全球1,000名申请人。北马其顿是北约成员国、欧盟成员国，并且是欧洲中心的一个完全民主共和国。',
        north_macedonia_title1: '处理时间',
        north_macedonia_text1: '获得北马其顿公民身份的平均处理时间为4个月。',
        north_macedonia_title2: '免签国家',
        north_macedonia_text2: '立即获得前往125个国家的免签证旅行资格，包括申根国家、中国和日本。',
        north_macedonia_title3: '投资类型',
        north_macedonia_text3: '投资于私人投资基金或直接投资于新设施。',
        north_macedonia_title4: '投资费用',
        north_macedonia_text4: '最低20万欧元。',
        north_macedonia_title5: '家庭受养人',
        north_macedonia_text5: '配偶和18岁以下的受抚养子女（配偶将在主申请人获得公民身份一年后获得公民身份，受抚养子女也将获得公民身份）。',
        north_macedonia_title6: '护照有效期',
        north_macedonia_text6: '北马其顿护照有效期为十（10）年。',
        north_macedonia_title7: '快速且简单',
        north_macedonia_text7: '无语言测试、经验或最低居住要求。',
        north_macedonia_title8: '自由与欧盟公民身份',
        north_macedonia_text8_1: '免签证前往包括欧盟、申根区、中国和日本在内的125个国家。',
        north_macedonia_text8_2: '欧盟成员候选国。',
        north_macedonia_text8_3: '允许双重国籍。',
        north_macedonia_title9: '财富保护',
        north_macedonia_text9_1: '固定货币为欧元。',
        north_macedonia_text9_2: '非居民零税收。',
        north_macedonia_text9_3: '制造业自由区内公司可100%持股，零税收。',
        grenada_description: '格林纳达投资入籍计划于2013年8月启动，为投资者提供多项福利，包括免签进入申根国家、英国和中国的资格，以及成为唯一一个与美国签署有条约的加勒比国家，格林纳达护照持有者可申请E-2美国“投资者签证”在美居住，无需居住在格林纳达或放弃当前国籍即可获得不可撤销的公民身份和护照。',
        grenada_title1: '处理时间',
        grenada_text1: '获得格林纳达公民身份的平均处理时间为5个月。',
        grenada_title2: '免签国家',
        grenada_text2: '立即获得前往145个国家的免签证旅行资格，包括申根国家、中国、英国、新加坡和香港。',
        grenada_title3: '投资类型',
        grenada_text3: '捐赠给格林纳达政府或投资于格林纳达政府批准的房地产项目。',
        grenada_title4: '投资费用',
        grenada_text4: '15万美元。',
        grenada_title5: '家庭受养人',
        grenada_text5: '配偶、30岁以下的子女、55岁以上的父母、18岁以上的兄弟姐妹。',
        grenada_title6: '护照有效期',
        grenada_text6: '格林纳达护照有效期为五（5）年。',
        grenada_title7: '良好的身份',
        grenada_text7_1: '格林纳达投资入籍为您提供终身不可撤销的公民身份。',
        grenada_text7_2: '后代也可以通过血缘获得公民身份。',
        grenada_text7_3: '格林纳达允许双重国籍，无任何限制，您可以保留当前国籍，无任何限制。',
        grenada_text7_4: '除主申请人外，配偶、30岁以下的子女、55岁以上的父母及18岁以上的兄弟姐妹也可受益于格林纳达公民身份。',
        grenada_title8: '良好信誉的护照',
        grenada_text8_1: '格林纳达护照持有人可免签进入145个国家，包括中国、申根国家、英国、新加坡、香港和澳大利亚。',
        grenada_text8_2: '生物特征电子护照，增强安全性和防伪措施。',
        grenada_text8_3: '与美国签订的商贸条约允许格林纳达公民申请E-2签证，赋予其在美国居住的权利。',
        grenada_title9: '税收优惠',
        grenada_text9_1: '格林纳达护照持有人享有资本利得税、赠与税、财富税和遗产税的豁免，非居民无需纳税。',
        grenada_text9_2: '格林纳达公民身份提供有吸引力的优惠，如企业税收优惠、完全免除进口关税、税收减免优惠和出口补贴。',
        stkitts_neviss_description: '圣基茨和尼维斯投资入籍计划成立于1984年，是现存最古老的CBI计划，向外国人提供了在无需放弃国籍或居住在该国的情况下获得不可撤销公民身份的机会。',
        stkitts_neviss_title1: '处理时间',
        stkitts_neviss_text1: '获得圣基茨和尼维斯公民身份的平均处理时间为4个月',
        stkitts_neviss_title2: '免签国家',
        stkitts_neviss_text2: '公民可以免签进入156个国家，包括申根国家、英国、瑞士、新加坡、香港和土耳其。',
        stkitts_neviss_title3: '投资类型',
        stkitts_neviss_text3: '捐赠给可持续岛屿国家（SISC）或房地产投资或公益选项捐赠',
        stkitts_neviss_title4: '投资金额',
        stkitts_neviss_text4: '250,000美元',
        stkitts_neviss_title5: '家庭受抚养人',
        stkitts_neviss_text5: '您可以在申请中包括您的配偶、25岁及以下的子女、兄弟姐妹和65岁及以上的父母。',
        stkitts_neviss_title6: '子女资格',
        stkitts_neviss_text6: '要在申请中包括受抚养子女，他们必须未婚且年龄在25岁以下，并且全日制在读。如果子女年龄超过25岁，必须提交单独的申请。',
        stkitts_neviss_title7: '资格要求',
        stkitts_neviss_text7a: '年龄：申请人必须年满18岁，并提供无犯罪记录的证明。',
        stkitts_neviss_text7b: '良好品格：必须进行犯罪背景调查。',
        stkitts_neviss_text7c: '财务能力：必须通过银行对账单和雇主推荐信证明出色的品格。',
        stkitts_neviss_text7d: '居住权：没有获得公民身份的居住要求。',
        stkitts_neviss_title8: '护照有效期',
        stkitts_neviss_text8: '您的圣基茨和尼维斯护照有效期为10年，并且可以无限期续签。对于16岁以下的儿童，护照有效期为5年。',
        stkitts_neviss_title9: '良好信誉的护照',
        stkitts_neviss_text9: '成功申请后，投资者及其家人将获得一本强大的护照，可免签或落地签进入166个国家，包括申根区、英国以及最近的加拿大。',
        stkitts_neviss_title10: '商业或家庭',
        stkitts_neviss_text10: '圣基茨和尼维斯投资入籍计划最显著的特点之一是其遗传性。一旦获得公民身份，可以代代相传，为家族提供持久的遗产。',
        antiguaBarbuda_description: '安提瓜和巴布达的投资入籍计划是一个合法项目，允许申请人在不需要居住在该国或放弃现有国籍的情况下获得不可撤销的公民身份和护照。',
        antiguaBarbuda_title1: '处理时间',
        antiguaBarbuda_text1: '获得安提瓜和巴布达公民身份的平均处理时间为6个月。',
        antiguaBarbuda_title2: '免签国家',
        antiguaBarbuda_text2: '立即享有对152个国家的免签旅行，包括申根国家、英国、新加坡、中国和香港。',
        antiguaBarbuda_title3: '投资类型',
        antiguaBarbuda_text3: '对安提瓜国家发展基金（NDF）的财政捐款、房地产投资或西印度大学（UWI）基金。',
        antiguaBarbuda_title4: '投资成本',
        antiguaBarbuda_text4: '财政捐款（四口之家为130,000美元）或房地产投资（200,000美元加上政府费用）。',
        antiguaBarbuda_title5: '家庭依赖者',
        antiguaBarbuda_text5: '配偶、30岁以下的子女、55岁及以上的父母和祖父母，以及没有年龄限制的兄弟姐妹。',
        antiguaBarbuda_title6: '护照有效期',
        antiguaBarbuda_text6: '首次签发对18岁及以上者有效期为5年，之后续签有效期为10年。',
        antiguaBarbuda_title7: '良好身份',
        antiguaBarbuda_text7_1: '安提瓜和巴布达的投资入籍计划提供不可撤销的公民身份，并通过后代公民身份扩展到未来的几代。',
        antiguaBarbuda_title8: '信誉良好的护照',
        antiguaBarbuda_text8_1: '安提瓜和巴布达公民身份提供对152个国家的免签旅行，包括申根区国家、英国、新加坡、中国和香港。',
        antiguaBarbuda_text8_2: '每次访问英国可以停留长达6个月。',
        antiguaBarbuda_title9: '个人和商业利益',
        antiguaBarbuda_text9_1: '没有资本利得税、赠与税、财富税和遗产税；非居民不缴纳税款。',
        antiguaBarbuda_text9_2: '使用安提瓜和巴布达护照可以在全球可靠的银行机构开设银行账户。',
        antiguaBarbuda_text9_3: '加勒比地区的免税贸易。',
        antiguaBarbuda_title10: '资格要求',
        antiguaBarbuda_text10_1: '年龄：申请人必须年满18岁，并提供无犯罪记录的证明。',
        antiguaBarbuda_text10_2: '品行良好：不得被拒绝进入与安提瓜和巴布达有签证豁免协议的国家。',
        antiguaBarbuda_text10_3: '健康：申请人必须身体健康，并提供医疗证明。',
        antiguaBarbuda_text10_4: '居住：申请人必须在获得公民身份的前五年内至少在安提瓜和巴布达居住五天。',
        antiguaBarbuda_title11: '尽职调查和背景调查',
        antiguaBarbuda_text11_1: '适用于所有选项',
        antiguaBarbuda_text11_2: '政府要求强制性背景调查以确保项目的完整性。政府指定的尽职调查机构负责进行这些调查，并利用国际和地方的各种资源，如国际刑警组织、World-Check和其他政府机构。该机构检查申请人的背景资料，以确保其品行良好，并且不对安提瓜和巴布达构成安全威胁。',
        antiguaBarbuda_text11_3: '尽职调查费用 主要申请人：7,500美元 配偶：7,500美元',
        antiguaBarbuda_text11_4: '12-17岁的受养子女：2,000美元',
        antiguaBarbuda_text11_5: '18-30岁的受养子女：4,000美元',
        antiguaBarbuda_text11_6: '55岁以上的受养父母：4,000美元',
        montenegro_description: '蒙特内哥罗投资入籍计划是蒙特内哥罗政府为吸引外商直接投资并增加国内经济活动而发起的一项举措。蒙特内哥罗经济公民计划允许外国人通过内政和公共管理部指定的投资途径获取蒙特内哥罗公民身份。',
        montenegro_title1: '处理时间',
        montenegro_text1: '居住许可：3周内，公民身份：6个月内。',
        montenegro_title2: '免签旅行',
        montenegro_text2: '享受全球移动性，可免签进入116个国家和地区。',
        montenegro_title3: '投资类型',
        montenegro_text3: '国家转型基金捐款，房地产投资',
        montenegro_title4: '投资成本',
        montenegro_text4: '申请人必须向欠发达城市发展基金捐款10万欧元，以政府资金形式进行投资；向欠发达地区投资25万欧元，向发达地区投资45万欧元。',
        montenegro_title5: '家庭依赖人',
        montenegro_text5: '主申请人18岁以下的子女也可获得公民身份。',
        montenegro_title6: '护照有效期',
        montenegro_text6: '蒙特内哥罗护照的有效期为十（10）年，无论是成人还是未成年人。',
        montenegro_title7: '快速申请通道：6个月',
        montenegro_text7_1: '移民前后无最低居住期要求。',
        montenegro_title8: '允许双重国籍',
        montenegro_title9: '知名护照',
        montenegro_text9_1: '免签进入123个国家，包括申根区、俄罗斯、巴西和土耳其。',
        montenegro_title10: '精英项目',
        montenegro_text10_1: '家庭成员包含在内。',
        montenegro_text10_2: '后代也可以成为公民。',
        montenegro_title11: '尽职调查和背景调查',
        montenegro_text11_1: '申请人必须投资政府批准的发展项目。投资可以在发达或欠发达地区进行：',
        montenegro_text11_2: '在蒙特内哥罗欠发达地区（蒙特内哥罗北部和中部地区，不包括波德戈里察）的发展项目需投资25万欧元；或',
        montenegro_text11_3: '在蒙特内哥罗发达地区（海滨和首都波德戈里察）的发展项目需投资45万欧元。',
        montenegro_text11_4: '政府批准的发展项目可以来自旅游业、农业和制造业。',
        montenegro_text11_5: '捐款申请人还需向政府基金支付10万欧元不可退还的捐款，以促进蒙特内哥罗经济。该基金将用于欠发达地区的开发。',
        montenegro_title12: '尽职调查和背景调查',
        montenegro_text12_1: '年龄：申请人必须年满18岁。',
        montenegro_text12_2: '健康状况：申请人必须身体健康，并拥有全球健康保险。',
        montenegro_text12_3: '品行良好：主申请人必须品行良好，无犯罪记录。',
        montenegro_text12_4: '合格投资：必须进行合格的投资，投资形式可以多种多样，如房地产收购、金融工具投资或对国家发展和社会基金的贡献。',
        vanuatu_description: '瓦努阿图永久居留计划移民法案于2011年推出。该计划允许一步获得永久居留权。最初用于香港投资客户作为第三国身份（大陆居民），后来广泛用于海外身份储备、就读国内纯外籍国际学校、持外国护照旅行、出境便利、瓦努阿图旅游和退休定居、以及提前更新中国护照。',
        vanuatu_title1: '处理时间',
        vanuatu_text1: '通过直接投资13万美元获得瓦努阿图公民身份的平均处理时间为10年。',
        vanuatu_title2: '免签国家',
        vanuatu_text2: '瓦努阿图护照持有人可以免签前往148个国家。',
        vanuatu_title3: '投资类型',
        vanuatu_text3: '捐赠',
        vanuatu_title4: '投资成本',
        vanuatu_text4: '瓦努阿图投资入籍计划要求政府捐赠13万美元，适用于最多四名申请人的家庭。其中5万美元投资于批准的基金中的股份，五年后可赎回。这实际上意味着该计划的净成本为8万美元，加上处理和尽职调查费用。',
        vanuatu_title5: '家庭受养人',
        vanuatu_text5: '配偶、经济上依赖的子女，以及65岁以上的受抚养父母。',
        vanuatu_title6: '护照有效期',
        vanuatu_text6: '瓦努阿图护照对成人和未成年人有效期为十（10）年。',
        vanuatu_title7: '家庭福利',
        vanuatu_text7_1: '通过投资瓦努阿图公民身份，您和您的家人可以享受终身、不可撤销的公民身份福利。',
        vanuatu_text7_2: '您未来的家庭成员也可以通过血统获得公民身份。',
        vanuatu_title8: '投资灵活性',
        vanuatu_text8_1: '该计划在资金转移方面非常灵活。',
        vanuatu_text8_2: '瓦努阿图公民身份获得后才需要支付投资款，而不是之前。',
        vanuatu_title9: '税收优势',
        vanuatu_text9_1: '资本收益、赠与、财富和继承不征税，非居民无需缴税。',
        vanuatu_text9_2: '此外，瓦努阿图公民身份还提供了利润汇出和资本进口的无限制权利。',
        vanuatu_title10: '全球银行访问',
        vanuatu_text10_1: '作为瓦努阿图公民，您可以在全球知名银行机构开设银行账户。',
        vanuatu_title11: '尽职调查和背景调查',
        vanuatu_text11_1: '除了所需的投资外，瓦努阿图投资入籍计划还涉及其他费用，如投资入籍专业费用、正式申请表格、公民身份和护照认证、快递费用及其他相关费用。此外，您将收到一个针对您的情况量身定制的详细报价，无任何隐藏费用。',
        vanuatu_text11_2: '每个申请的尽职调查费用：5,000美元',
        vanuatu_title12: '资格要求',
        vanuatu_text12_1: '年龄：申请人必须年满18岁',
        vanuatu_text12_2: '良好品格：申请人必须无犯罪记录且品德良好。',
        vanuatu_text12_3: '健康状况：申请人必须身体健康，并提供健康证明。',
        malta_description: '马耳他投资入籍计划为外国个人及其家庭提供通过直接投资获得马耳他公民身份的机会。该计划受《马耳他国籍法188》和《通过直接投资授予国籍条例》（SL 188.06）管辖。成功申请者将为马耳他的经济增长和发展做出贡献。',
        malta_title1: '处理时间',
        malta_text1: '1年',
        malta_title2: '免签国家',
        malta_text2: '持有马耳他护照的人可以免签前往184个国家。',
        malta_title3: '投资类型',
        malta_text3: '不可退还的投资 + 房产购买或租赁 + 慈善捐款。',
        malta_title4: '投资成本',
        malta_text4: '要获得马耳他公民身份，您需要：一年后投资75万欧元或三年后投资60万欧元的不退还投资、70万欧元的房产或五年内每年1.6万欧元的房租、1万欧元的慈善捐款，以及每位额外受养人5万欧元。',
        malta_title5: '家庭受养人',
        malta_text5: '配偶、28岁以下未婚且经济依赖的子女，以及55岁以上的受抚养父母和祖父母。',
        malta_title6: '护照有效期',
        malta_text6: '马耳他护照对成人和未成年人有效期为十（10）年。',
        malta_title7: '快速通道至欧洲公民身份',
        malta_text7_1: '马耳他投资入籍计划允许您在等待公民身份签发期间，获得申根区三周的快速通道居留卡。',
        malta_text7_2: '从初次居留之日起通常需要14到38个月才能获得入籍证书。',
        malta_title8: '信誉良好的护照',
        malta_text8_1: '持有马耳他护照的人享有免签或落地签前往184个目的地的待遇，包括加拿大和欧盟。',
        malta_text8_2: '有权在包括挪威、冰岛、瑞士和列支敦士登在内的28个欧盟成员国中的任何一个国家居住、工作或学习。以英语为母语的马耳他还为您和您的家人开辟了新的机会。',
        malta_title9: '精英计划',
        malta_text9_1: '马耳他的投资入籍计划拥有全球最严格的尽职调查标准，确保只有信誉良好的申请人会被考虑。',
        malta_text9_2: '此外，马耳他是一个具有吸引力的居住地或第二家园，拥有战略性位置、良好的航空连接以及讲英语的国家和劳动力。',
        malta_title10: '尽职调查和背景调查',
        malta_text10_1: '适用于所有投资选项，政府要求进行背景调查和支付费用，以确保马耳他投资入籍计划的完整性。政府指定一家首选的尽职调查机构，该机构通过国际和本地的不同来源（如国际刑警组织、World-Check和其他政府实体）进行检查。',
        malta_text10_2: '作为流程的一部分，申请人必须提交护照、出生证明和银行对账单等文件，机构会彻底检查每位申请人的过去，以确保他们品行良好，不会对马耳他的安全构成威胁。',
        malta_text10_3: '尽职调查费用',
        malta_text10_4: '主申请人：15,000美元',
        malta_text10_5: '配偶：10,000美元',
        malta_text10_6: '28岁以下的子女和55岁及以上的父母：10,000美元',
        malta_title11: '资格要求',
        malta_text11_1: '年龄：申请人必须年满18岁，并提供无犯罪记录的证明。',
        malta_text11_2: '健康状况：申请人必须身体健康，并拥有全球健康保险。',
        malta_text11_3: '合法预算：还需要明确的与马耳他的法律联系，这可以通过房产所有权或租赁，或通过向国家发展和社会基金的不可退还捐款来证明。',
        malta_text11_4: '合资格的投资：合资格的投资必须进行，可以采取各种形式，例如房地产收购、金融工具投资或对国家发展和社会基金的捐款。',
        saintLucia_description: '圣卢西亚投资入籍计划于2015年成立，提供了一种快速高效的方式来获得不可撤销的公民身份，无需居住在该国或放弃现有的国籍。',
        saintLucia_title1: '处理时间',
        saintLucia_text1: '获得圣卢西亚公民身份的平均处理时间为3到4个月。',
        saintLucia_title2: '免签国家',
        saintLucia_text2: '即时免签前往146个国家，包括申根国家、英国、新加坡和香港。',
        saintLucia_title3: '投资类型',
        saintLucia_text3: '对圣卢西亚政府基金的捐款、房地产投资、政府债券或基础设施选项。',
        saintLucia_title4: '投资成本',
        saintLucia_text4: '起价为10万美元。',
        saintLucia_title5: '家庭成员',
        saintLucia_text5: '配偶、30岁以下的子女、55岁以上的年长父母和18岁以下的兄弟姐妹。',
        saintLucia_title6: '护照有效期',
        saintLucia_text6: '圣卢西亚护照的有效期为五（5）年。',
        saintLucia_title7: '家庭福利',
        saintLucia_text7_1: '圣卢西亚投资入籍计划为您和您的家庭成员提供不可撤销的公民身份优势，而未来的世代也可以通过血统获得公民身份。',
        saintLucia_text7_2: '除了主申请人外，配偶、30岁以下的子女、55岁以上的年长父母和18岁及以上的兄弟姐妹也可以受益于该计划。',
        saintLucia_title8: '免签旅行',
        saintLucia_text8_1: '您可以免签前往146个国家，包括申根区国家、英国和许多英联邦成员国。',
        saintLucia_text8_2: '在英国的延长停留，持有圣卢西亚护照，您每次入境可以在英国停留长达6个月。',
        saintLucia_title9: '个人和商业利益',
        saintLucia_text9_1: '个人利益：资本收益、赠与、财富和继承税免税，且非居民无需缴税。',
        saintLucia_text9_2: '您可以在全球可靠的银行机构开设银行账户。',
        saintLucia_text9_3: '在加勒比地区享受免税贸易。',
        saintLucia_title10: '尽职调查和背景调查',
        saintLucia_text10_1: '必须由政府进行强制性背景调查，以确保圣卢西亚投资入籍计划的完整性。政府指定了一家首选的尽职调查机构，该机构通过国际刑警组织、World-Check和其他政府机构等多种国际和本地来源进行检查。',
        saintLucia_text10_2: '尽职调查费用 主申请人：8000美元 配偶：5000美元。',
        saintLucia_text10_3: '16岁以上的受养人：5000美元。',
        saintLucia_title11: '资格要求',
        saintLucia_text11_1: '年龄：申请人必须年满18岁，并提供无犯罪记录的证明。健康状况：申请人必须身体健康，并拥有全球健康保险。',
        saintLucia_text11_2: '良好品格：您不得被拒绝进入与圣卢西亚有免签协议的国家。',
        saintLucia_text11_3: '健康：符合健康和品格要求。',
        saintLucia_text11_4: '投资：在圣卢西亚进行合资格投资。',
        turkey_description: '土耳其政府于2017年1月推出了土耳其投资入籍计划，以鼓励外国直接投资并发展该国的房地产行业。该计划允许外国投资者获得土耳其公民身份和不可撤销的土耳其护照，无需居住在该国或放弃现有的国籍。',
        turkey_title1: '处理时间',
        turkey_text1: '获得土耳其公民身份的平均处理时间为2-5个月。',
        turkey_title2: '免签国家',
        turkey_text2: '土耳其护照持有人可免签进入111个国家，包括日本、韩国和新加坡。',
        turkey_title3: '投资类型',
        turkey_text3: '房地产投资。',
        turkey_title4: '投资成本',
        turkey_text4: '40万美元。',
        turkey_title5: '家庭成员',
        turkey_text5: '配偶和18岁以下的子女。',
        turkey_title6: '护照有效期',
        turkey_text6: '土耳其护照对16岁以上的个人有效期为十（10）年，对16岁以下的儿童有效期为五（5）年。',
        turkey_title7: '家庭福利',
        turkey_text7_1: '您和您的家人可以享受终身不可撤销的土耳其公民身份，未来的家庭成员也可以通过血统获得公民身份。',
        turkey_text7_2: '除了主申请人外，配偶和18岁以下的子女也可以获得土耳其护照。',
        turkey_title8: '良好身份',
        turkey_text8_1: '该过程简单，无需放弃现有国籍。',
        turkey_title9: '旅行福利',
        turkey_text9_1: '免签前往111个国家，包括日本、韩国和新加坡。',
        turkey_title10: '信誉良好的护照',
        turkey_text10_1: '拥有良好信誉的土耳其护照可以轻松获得所需签证。',
        turkey_title11: '商业和安全福利',
        turkey_text11_1: '土耳其与美国之间的E-2条约允许土耳其公民在美国经营重大业务并居住。',
        turkey_title12: '投资回报',
        turkey_text12_1: '投资者可以在获得土耳其公民身份三年后回收投资资金。',
        turkey_text12_2: '土耳其的房价每年增长15-25%，三年后出售房产可获得额外利润。',
        turkey_title13: '投资类型和成本',
        turkey_text13_1: '为了符合土耳其投资入籍计划的资格，申请人必须在土耳其房地产市场进行房地产投资或在土耳其批准的行业进行资本转移。',
        turkey_text13_2: '选项1',
        turkey_text13_3: '房地产投资：投资者必须购买拥有产权契约的永久产权物业或土地，且投资必须在申请公民身份之前进行。房地产投资必须持有3年。',
        turkey_text13_4: '最低投资额：40万美元。',
        turkey_text13_5: '选项2',
        turkey_text13_6: '资本转移：投资者可以通过在土耳其批准的行业进行资本转移来获得公民身份。投资必须保持3年。政府债券：50万美元，土耳其银行存款：50万美元，投资基金：50万美元，固定资本出资：50万美元。',
        turkey_title14: '资格要求',
        turkey_text14_1: '年龄：申请人必须年满18岁，并提供无犯罪记录的证明。',
        turkey_text14_2: '良好品格：申请人必须有清白的犯罪记录和良好的声誉。',
        turkey_text14_3: '健康状况：申请人必须身体健康，并提供健康证明。',
        turkey_text14_4: '维持：投资必须持有三年才能获得公民身份。',

    },
    savedProperty: {
        propertyCategorySavedProperties: '已保存的房产',
        propertyCategoryAllSavedProperties: '您所有已保存的房产',
        propertyCategoryDashboard: '仪表盘',
        propertyCategoryProfile: '我的资料',
        propertyCategorySavedProperty: '已保存的房产',
        propertyCategoryMyProperties: '我的房产',
        propertyCategorySubscription: '我的订阅',
        propertyCategorySubscriptionDaysLeft: '剩余10天',
        propertyCategorySubmitNewProperty: '提交新房产',
        propertyCategoryChangePassword: '更改密码',
        propertyCategoryBookmarkProperty: '收藏房产',
        propertyCategoryProperty: '房产',
        propertyCategoryDelete: '删除',
    },
    userProperty:{
        propertyCountry: '国家',
        propertyCategoryMyProperties: '我的房产',
        propertyCategoryAllProperties: '我的所有房产',
        propertyCategoryDashboard: '仪表盘',
        propertyCategoryProfile: '我的资料',
        propertyCategorySavedProperty: '已保存的房产',
        propertyCategoryMySubscription: '我的订阅',
        propertyCategorySubscriptionDaysLeft: '剩余10天',
        propertyCategorySubmitNewProperty: '提交新房产',
        propertyCategoryChangePassword: '更改密码',
        propertyCategoryListNewProperty: '列出新房产',
        propertyCategoryProperty: '房产',
        propertyCategoryLeads: '潜在客户',
        propertyCategoryStats: '统计数据',
        propertyCategoryPostedOn: '发布时间',
        propertyCategoryStatus: '状态',
        propertyCategoryAction: '操作',
        propertyCategoryTotalViews: '总浏览量',
        propertyCategoryDaysAgo: '天前',
        propertyCategoryActive: '活跃',
        propertyCategoryExpired: '已过期',
        propertyCategoryEmailAddress: '电子邮件地址',
    },
    propertySubmit: {
        propertyCategory: '房产类别',
        advertiseYourProperty: '推广您的房产',
        submitYourProperty: '提交您的房产',
        propertyListingCountries: '房产上市国家',
        propertyCountriesText: '我们的平台使房产所有者能够将其房地产出售给全球客户，提供无与伦比的曝光率，确保您的房产能够吸引全球投资者的关注。',
        malaysia: '马来西亚',
        singapore: '新加坡',
        greece: '希腊',
        basicInformation: '基本信息',
        propertyTitle: '房产标题',
        propertyType: '房产类型',
        propertyCategoryText: '如果您没有账户，可以通过以下方式创建一个账户',
        clickHere: '点击这里',
        greecePropertyBasicInformation: '希腊房产基本信息',
        propertyStatus: '状态',
        forRent: '出租',
        forSale: '出售',
        condominium: '共管公寓',
        apartment: '公寓',
        landedHouse: '独立屋',
        commercial: '商业用地',
        offices: '办公室',
        land: '土地',
        new: '新建',
        secondHand: '二手',
        surfaceArea: '面积',
        plotSurfaceArea: '地块面积',
        price: '价格',
        pricePerM2: '每平方米价格',
        bedrooms: '卧室',
        bathrooms: '浴室',
        gallery: '画廊',
        uploadGallery: '上传画廊',
        dragAndDropImage: '拖放图像至此或',
        choose: '选择',
        dropImagesHere: '将图像拖放到这里',
        propertyVideo: '房产视频',
        youtubeEmbedLink: 'YouTube 嵌入链接',
        locationInGreece: '希腊位置',
        address: '地址',
        city: '城市',
        state: '州/省',
        zipCode: '邮政编码',
        greecePropertyDetailedInformation: '希腊房产详细信息',
        description: '描述',
        buildingAgeOptional: '建筑年龄（可选）',
        years: '年',
        garage: '车库（可选）',
        rooms: '房间',
        heatingSystemOptional: '供暖系统（可选）',
        centralPetrol: '中央供暖（石油）',
        electricHeating: '电加热',
        heatPumps: '热泵',
        notAvailable: '不可用',
        energyClassOptional: '能效等级（可选）',
        completionYearOptional: '竣工年份（可选）',
        internalFeaturesOptional: '内部特征（可选）',
        fullFurnished: '全家具',
        partialFurnished: '部分家具',
        noFurnished: '无家具',
        elevator: '电梯',
        internalStaircase: '内部楼梯',
        nightPower: '夜电',
        secureDoor: '安全门',
        doubleGlass: '双层玻璃',
        painted: '粉刷过的',
        wifi: 'Wi-Fi',
        fireplace: '壁炉',
        woodFloorType: '木地板类型',
        alarm: '警报',
        windowScreens: '窗纱',
        airConditioning: '空调',
        petsWelcome: '欢迎宠物',
        aluminiumFrames: '铝框',
        mannedReception: '有人接待',
        storageSpace: '存储空间',
        marbleFloorType: '大理石地板',
        airy: '通风的',
        underfloorHeating: '地暖',
        solarWaterHeating: '太阳能热水',
        attic: '阁楼',
        playroom: '游戏室',
        satelliteReceiver: '卫星接收器',
        chargingFacilitiesForElectricCar: '电动车充电设施',
        balcony: '阳台',
        woodenFrames: '木框',
        waterCloset: '卫生间',
        externalFeaturesOptional: '外部特征（可选）',
        privateGarden: '私人花园',
        awning: '遮阳篷',
        sharedGarden: '共享花园',
        facade: '外观',
        roadTypeAsphalt: '道路类型沥青',
        residentialZone: '住宅区',
        accessForPeopleWithDisabilities: '残疾人通道',
        swimmingPool: '游泳池',
        builtInBBQ: '内置烧烤',
        parkingSpace: '停车位',
        garden: '花园',
        balconyDuplicate: '阳台',
        suitableForOptional: '适合人群（可选）',
        student: '学生',
        holidayHome: '度假屋',
        investment: '投资',
        shortTermRental: '短期出租',
        commercialUse: '商业用途',
        medicalOffice: '医疗办公室',
        contactInformation: '联系信息',
        name: '姓名',
        email: '电子邮件',
        phone: '电话',
        submit: '提交',
        malaysiaPropertyBasicInformation: '马来西亚房产基本信息',
        lotType: '地块类型',
        internationalLot: '国际地块',
        bumiReleased: 'Bumi-放行',
        bumiLot: 'Bumi 地块',
        pricePerFt2: '每平方英尺价格',
        locationInMalaysia: '马来西亚位置',
        malaysiaPropertyDetailedInformation: '马来西亚房产详细信息',
        conditionOptional: '状态（可选）',
        likeNew: '如新',
        renovated: '翻新',
        needRenovation: '需要翻新',
        landTenure: '土地年限',
        freehold: '永久产权',
        leasehold: '租赁权',
        ninetyNineYearsLeasehold: '99年租赁',
        nineHundredNinetyNineYearsLeasehold: '999年租赁',
        landTitle: '土地所有权',
        commercialStrata: '商业分层',
        residentialStrata: '住宅分层',
        individualTitle: '个人所有权',
        masterTitle: '主产权',
        landSizeOptional: '土地面积（可选）',
        totalUnitsOptional: '总单位数（可选）',
        totalCarParkOptional: '总停车位（可选）',
        totalTowersOptional: '总塔楼数（可选）',
        totalFloorsOptional: '总楼层数（可选）',
        startingUnitPriceOptional: '起始单价（可选）',
        highestUnitPriceOptional: '最高单价（可选）',
        carParkOptional: '停车位（可选）',
        furnishingOptional: '家具配置（可选）',
        fullFurnishedDuplicate: '全家具',
        partialFurnishedDuplicate: '部分家具',
        notFurnished: '无家具',
        airConditioningDuplicate: '空调',
        fullyFittedKitchen: '全配备厨房',
        waterHeaters: '热水器',
        builtInWardrobes: '内置衣柜',
        balconyDuplicate2: '阳台',
        enSuiteBathrooms: '套间浴室',
        highQualityFlooring: '高品质地板',
        openPlanLayout: '开放式布局',
        highCeilings: '高天花板',
        smartHomeFeatures: '智能家居功能',
        utilityStorageRoom: '实用储物间',
        privateLiftLobby: '私人电梯大堂',
        homeOfficeSpace: '家庭办公室',
        dualKeyAccess: '双钥匙访问',
        pantryArea: '茶水间',
        laundryArea: '洗衣区',
        highQualityFinishes: '高品质装饰',
        largeWindowsNaturalLight: '大窗户/自然光',
        soundproofing: '隔音',
        walkInCloset: '步入式衣柜',
        guestPowderRoom: '客用化妆间',
        smartWiring: '智能布线',
        privateGardenDuplicate: '私人花园',
        emergencyEscapePlan: '紧急逃生计划',
        facilitiesOptional: '设施（可选）',
        swimmingPoolDuplicate: '游泳池',
        gymnasium: '健身房',
        twentyFourHourSecurity: '24小时保安',
        parkingSpaces: '停车位',
        childrensPlayground: '儿童游乐场',
        multipurposeHall: '多功能厅',
        bbqArea: '烧烤区',
        joggingTrack: '跑道',
        wadingPool: '浅水池',
        saunaSteamRoom: '桑拿/蒸汽房',
        readingStudyRoom: '阅读/学习室',
        clubhouse: '会所',
        managementOffice: '管理办公室',
        gardenOrGreenSpaces: '花园或绿地',
        functionRoom: '功能室',
        tennisCourt: '网球场',
        badmintonCourt: '羽毛球场',
        surau: '祈祷室',
        coveredCarPark: '有盖停车场',
        liftLobby: '电梯大堂',
        airbnbManagement: 'Airbnb 管理',
        singaporePropertyBasicInformation: '新加坡房产基本信息',
        nearestMRT: '最近的地铁站',
        locationInSingapore: '新加坡位置',
        district: '区',
        neighbourhood: '社区',
        singaporePropertyDetailedInformation: '新加坡房产详细信息',
        typeOfProperty: '房产类型',
        publicHousingHDBFlats: '公共住房（HDB 公寓）',
        privateResidentialProperties: '私人住宅物业',
        executiveCondominiums: '行政共管公寓',
        commercialProperties: '商业物业',
        industrialProperties: '工业物业',
        mixedUseDevelopments: '混合用途开发',
        servicedApartments: '服务式公寓',
        hotels: '酒店',
    },
    cta: {
        do_you_have_questions: '您有任何疑问？',
        help_to_find_home: '我们会帮助您找到您的下一个家。',
        contact_us_now: '立即联系我们',
        about_us: '关于我们',
        services: '我们的服务'
    },
    footer: {
        footer: '环球房产投资',
        hotline: '热线',
        greece: '希腊',
        singapore: '新加坡',
        malaysia: '马来西亚',
        indonesia: '印度尼西亚',
        japan: '日本',
        germany: '德国',
        need_help: '您需要帮助？',
        offices: '公司地址',
        email_address: '电子邮件地址',
        subscribe: '订阅',
        services: '服务',
        buy_property: '购买物业',
        sell_property: '出售物业',
        property_for_rent: '物业出租',
        global_partners: '全球合作伙伴',
        study_overseas: '海外留学',
        global_visa: '全球签证',
        company: '公司',
        about: '关于',
        countries: '国家',
        property_listings: '物业列表',
        blog_news: '博客或新闻',
        our_team: '我们的团队',
        contact_us: '联系我们',
        email: '电子邮件',
    },
    modals: {
        scan_qr_to_connect: '扫描二维码连接',
        dream_home_anywhere: '帮助您在世界各地寻找您的梦想之家',
        login: '登录',
        login_with_google: 'Google 登录',
        register: '注册',
        username: '用户名',
        password: '密码',
        remember_me: '记得我',
        lost_password: '忘记密码？',
        forgot_password: '忘记密码',
        send_reset_email: '发送重置邮件',
        enter_email_address: '输入你的邮箱地址',
        full_name: '全名',
        email: '电子邮件',
        phone_number: '电话号码',
        terms_conditions: '使用本网站即表示您接受条款和条件。',
        enter_phone_number: '输入您的电话号码',
        enter_email: '输入您的电子邮件',
        enter_full_name: '输入您的全名',
        enter_password: '输入您的密码',
        enter_username: '输入您的用户名',
    },
    form: {
        name: '姓名',
        phone_number: '电话号码',
        messages: '留言',
        get_latest_offer: '获取最新优惠',
    },
    settings: {
        language: '语言',
        currency: '货币',
        measurement: '测量',
        confirm: '确认',
    },
    services: {
        our_services: '我们的服务',
        title: '环球之家服务',
        greece_property_management: '希腊物业管理',
        existing_clients: '超过100个现有客户',
        malaysia_property_investment: '马来西亚物业投资房源',
        more_listings: '超过100个房源',
        singapore_property_investment: '新加坡物业投资房源',
        global_visa_services: '全球签证服务',
        successful_visa_cases: '处理超过100个成功的签证申请案例',
        global_real_estate_investment: '全球房产投资',
        real_estate_description: '我们精选全球热门及潜力地区的优质房产项目，包括但不限于北美、欧洲、亚洲及大洋洲等地区的豪宅、商业地产、学区房、度假别墅等，为客户提供详尽的市场分析、项目评估及一站式购房服务，确保投资安全高效。',
        immigration_planning_services: '移民规划服务',
        immigration_description: '依托专业的移民顾问团队，我们深入研究各国移民政策，包括投资移民、技术移民、家庭团聚等多种途径，为客户量身定制移民方案，全程指导申请流程，助力客户顺利获得海外身份，享受更广阔的生活空间和发展机遇。',
        citizenship_by_investment: '投资入籍服务',
        high_net_worth_investor: '高净值投资者',
        dual_citizenship: '双重国籍',
        asset_allocation: '资产配置与财富管理',
        asset_allocation_description: '结合客户的财务状况、风险偏好及未来规划，我们提供跨地域、跨币种、跨市场的资产配置建议，以及税务筹划、遗产规划等增值服务，帮助客户实现财富的稳健增长与传承。',
        contact_us_now: '立即联系我们',
        connect_through_apps: '通过应用程序连接',
        app_description: '在多种平台上探索我们的应用程序，享受无缝体验。无论您是在使用我们官方网站、浏览Google Play商店、下载iOS应用程序，还是访问我们的微信小程序，我们都能满足您的需求。与我们保持联系，方便地访问您所需的服务。',
        official_website: '官方网站',
        google_play_store: 'Google Play Store',
        ios_app: 'iOS',
        wechat_mini_program: '微信小程序',
        connect_through_social_media: '通过社交媒体连接',
        social_media_description: '在社交媒体上关注我们，获取全球最新的房地产投资机会。无论您是在浏览Facebook、在TikTok上与我们互动，还是在小红书上查看我们的见解，我们的平台都能让您保持信息灵通和连接。加入我们的社区，方便地获取最佳投资选择和房地产趋势。',
        facebook: 'Facebook',
        tiktok: 'TikTok',
        xiaohongshu: '小红书',
        explore_by_countries: '按国家探索',
        countries_description: '发现各国多样的房地产机会，从繁华的城市中心到宁静的乡村风光。无论您是想投资还是寻找下一个家，都可以轻松探索您所期望的目的地的房产。',
        athens_greece: '希腊雅典',
        johor_bahru_malaysia: '马来西亚柔佛州',
        singapore: '新加坡',
        how_it_works: '如何运作？',
        how_it_works_description: '遵循这些简单步骤，轻松找到并确保您理想的房产。',
        create_an_account: '创建账户',
        account_description: '轻松注册，以获取个性化的房源列表和更新。',
        find_and_search_property: '寻找和搜索房产',
        search_property_description: '探索和筛选广泛的房源，找到满足您需求的完美选择。',
        book_your_property: '预订您的房产',
        book_property_description: '通过在线预订，只需几次点击即可确保您的房产。',
        searching_perfect_place: '您在寻找理想的梦幻家园吗？',
        searching_description: '找到理想的梦幻家园是一段旅程，我们将在此为您提供便利。探索广泛的房源，以满足您独特的需求，无论您是在寻找一个宁静的度假地、一个充满活力的城市公寓，还是一个豪华别墅。我们的平台旨在将您与最佳房地产机会联系起来，提供详细的房源列表、专家建议和个性化推荐，帮助您自信地做出正确的选择。让我们引导您找到理想的家，享受舒适和便利。',
        find_properties: '查找房产',
    },
    aboutUs: {
        aboutUs: '关于我们',
        whoWeAre: '关于我们 - 我们是谁？',
        companyOverview: '公司概述',
        visionAndMission: '我们的愿景和使命',
        companyDescription: 'GlobalAHome是一家全球房地产和移民投资公司，提供整合全球房地产投资、移民规划和资产管理的全面国际服务。自成立以来，公司始终坚持“专业、诚信、创新、共赢”的核心价值观，致力于为全球高净值客户提供一站式、个性化的海外生活和投资解决方案。',
        globalPerspective: '我们深入了解全球房地产市场趋势，准确把握各国移民政策，以全球视角和本地化服务，帮助客户实现资产保值增值、家庭规划升级以及生活质量的飞跃。',
        moreAboutServices: '更多关于我们服务的信息',
        specialAboutUs: '我们的特别之处',
        scalableApplication: '我们的可扩展应用程序通过合作与伙伴关系，满足与家居服务相关的所有需求',
        propertyListings: '房产列表',
        homeServices: '家居服务',
        userDashboard: '用户仪表板',
        customizedServices: '定制化服务',
        meetOurTeam: '认识我们的团队',
        professionalAndDedicatedTeam: '专业且敬业的团队',
        ceo: '创始人, CEO',
        cto: '创始人, CTO',
        greecePropertyDirector: '希腊物业管理总监',
        graphicDesigner: '平面设计师',
        professionalAdvantages: '专业优势',
        globalNetwork: '全球网络',
        globalNetworkDescription: '我们拥有遍布全球的合作伙伴网络，能够迅速获取第一手市场资讯，为客户提供最前沿的投资与移民信息。',
        professionalTeam: '专业团队',
        professionalTeamDescription: '汇聚了来自法律、金融、税务、房地产等多个领域的资深专家，以专业的知识和丰富的经验为客户提供全方位、高质量的服务。',
        customizedService: '定制化服务',
        customizedServiceDescription: '坚持“以客户为中心”的服务理念，深入了解客户需求，提供一对一的定制化服务方案，确保每位客户都能获得最适合自己的解决方案。',
        oneStopService: '一站式服务',
        oneStopServiceDescription: '从项目筛选、尽职调查、合同签订到后期管理、移民申请、税务规划等，我们提供全程跟踪服务，让客户省心省力。',
        lookingToTheFuture: '展望未来',
        lookingToTheFutureDescription: '面对日益全球化的经济趋势和不断变化的客户需求，環球房產移民投資公司将持续深化国际合作，拓宽服务领域，提升服务质量，努力成为全球高净值客户信赖的海外生活与投资伙伴。我们坚信，通过不懈的努力与创新，能够为客户开启更加精彩纷呈的海外生活篇章，共创美好未来www.globalahome.com',
        aboutGlobalahome: '关于Globalahome.com',
        dreamHouseSearch: '您在寻找梦想家的理想住所吗？',
        dreamHouseDescription: '寻找梦想家的理想住所是一个旅程，我们在此为您提供帮助。无论您是在寻找宁静的度假胜地、充满活力的城市公寓，还是奢华的别墅，我们的平台都能为您提供量身定制的房产选择，结合专家建议和个性化推荐，帮助您自信地做出正确的选择。让我们带您找到理想的家园，舒适与便利在那里等待着您。',
        findProperties: '寻找房产'
    },
    contactUs:{
        getInTouch: '取得联系',
        getHelpSupport: '获得帮助和友好的支持',
        supportMessage: '需要帮助或支持？我们全天 24 小时为您服务。',
        contactSupport: '联系支持',
        contactSales: '联系销售',
        startLiveChat: '开始在线聊天',
        fillForm: '填写表格',
        startChat: '开始在线聊天',
        name: '姓名',
        email: '电子邮件',
        phoneNumber: '电话号码',
        subject: '主题',
        message: '消息',
        submitRequest: '提交请求',
    },
    subscription:{
        chooseSubscription: '选择订阅',
        selectSubscription: '选择您的订阅',
        dashboard: '仪表板',
        myProfile: '我的资料',
        myProperties: '我的物业',
        savedProperty: '已保存的物业',
        submitRequest: '提交请求',
        submitNewProperty: '提交新物业',
        changePassword: '更改密码',
        mySubscription: '我的订阅',
        daysLeft: '剩余10天',
        goldPackage: '黄金套餐',
        yearlySubscription: '年',
        unlimitedListings: '无限量列表',
        featured: '精选',
        unlimitedImages: '无限图片',
        helpSupport: '帮助与支持',
        switchPackage: '切换到此套餐',
        premiumPackage: '高级套餐',
        listings: '列表',
        imagesPerList: '每列表的图片',
        standardPackage: '标准套餐',
        payWithPaypal: '使用Paypal支付',
        payWithWechatPay: '使用微信支付',
        wireTransfer: '电汇',
    },
};
