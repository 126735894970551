export default {
    listingGreece: {
        mostRated: 'Most Rated',
        mostViewed: 'Most Viewed',
        newListings: 'New Listings',
        highRated: 'High Rated',
        openAdvanceFilter: 'Open Advance Filter',
        selectPropertyType: 'Select Property Type',
        apartment: 'Apartment',
        condo: 'Condo',
        family: 'Family',
        detachedHouse: 'Detached House',
        villa: 'Villa',
        selectPriceRange: 'Select Price Range',
        lessThan100K: 'Less Than €100K',
        from100KTo200K: '€100K - €200K',
        from200KTo300K: '€200K - €300K',
        from300KTo400K: '€300K - €400K',
        above500K: 'Above €500K',
        selectRoomType: 'Select Room Type',
        selectBathroomType: 'Select Bathroom Type',
        minArea: 'Min Area',
        maxArea: 'Max Area',
        minPrice: 'Min Price',
        maxPrice: 'Max Price',
        findNewHome: 'Find New Home',
        resetFilter: 'Reset Filter',
        beds: 'Beds',
        baths: 'Baths',
        viewDetail1: 'View Detail',
        viewDetail2: 'View Detail',
        sortBy: 'Sort By',
    },    
    listingMalaysia: {
        mostRated: 'Most Rated',
        mostViewed: 'Most Viewed',
        newListings: 'New Listings',
        highRated: 'High Rated',
        openAdvanceFilter: 'Open Advance Filter',
        selectPropertyType: 'Select Property Type',
        apartment: 'Apartment',
        condo: 'Condo',
        family: 'Family',
        detachedHouse: 'Detached House',
        villa: 'Villa',
        selectPriceRange: 'Select Price Range',
        lessThan100K: 'Less Than €100K',
        from100KTo200K: 'RM100K - RM200K',
        from200KTo300K: 'RM200K - RM300K',
        from300KTo400K: 'RM300K - RM400K',
        above500K: 'Above RM500K',
        selectRoomType: 'Select Room Type',
        selectBathroomType: 'Select Bathroom Type',
        minArea: 'Min Area',
        maxArea: 'Max Area',
        minPrice: 'Min Price',
        maxPrice: 'Max Price',
        findNewHome: 'Find New Home',
        resetFilter: 'Reset Filter',
        beds: 'Beds',
        baths: 'Baths',
        viewDetail1: 'View Detail',
        viewDetail2: 'View Detail',
        sortBy: 'Sort By',
    },    
    header: {
        login: 'Login',
        logout: 'Logout',
        wechat: 'Wechat',
        whatsapp: 'Whatsapp',
        country: 'Country',
        residential: 'Residential',
        commercial: 'Commercial',
        land: 'Land',
        study_overseas: 'Study Overseas',
        join_us: 'Join us',
        global_visa: 'Global Visa',
        greece: 'Greece',
        malaysia: 'Malaysia',
        singapore: 'Singapore',
        profile: 'Profile',
        welcome_user: 'Welcome User',
        my_profile: 'My Profile',
        my_dashboard: 'My Dashboard',
        change_my_password: 'Change My Password',
        saved_property: 'Saved Property',
        add_property: 'Add Property',
        compareSelectedProperty: 'Compare & Selected Property',
        compareProperty: 'Compare Property',
        savedProperty: 'Saved Property',
        viewCompareProperty: 'View & Compare',
        viewSavedProperty: 'View Saved Property',
        dropMessage: 'Drop Message',
        subject: 'Subject',
        messages: 'Messages',
        sendMessage: 'Send Message',
    },
    user_dashboard: {
        dashboard: 'Dashboard',
        welcome_message: 'Hello User, Welcome',
        my_profile: 'My Profile',
        saved_property: 'Saved Property',
        my_properties: 'My Properties',
        my_subscription: 'My Subscription',
        submit_new_property: 'Submit New Property',
        change_password: 'Change Password',
        current_subscription: 'Your Current Subscription:',
        membership_type: 'Platinum Membership',
        listed_property: 'Listed Property',
        earned_this_week: 'Earned This Week',
        earned_this_month: 'Earned This Month',
        extra_area_chart: 'Extra Area Chart',
        notifications: 'Notifications',
        listing_status: 'Listing Status',
        title: 'Title',
        listing_id: 'Listing ID',
        status: 'Status',
        price: 'Price',
        date_created: 'Date Created',
    },
    user_profile: {
        my_profile: 'My Profile',
        my_account_profile: 'My Account & Profile',
        dashboard: 'Dashboard',
        saved_property: 'Saved Property',
        my_properties: 'My Properties',
        my_subscription: 'My Subscription',
        submit_new_property: 'Submit New Property',
        change_password: 'Change Password',
        full_name: 'Your Full Name',
        email: 'Email',
        selectTitle: 'Select Title',
        mr: 'Mr.',
        ms: 'Ms.',
        mrs: 'Mrs.',
        country_code: 'Country Code',
        searchHere: 'Search Here',
        yes: 'Yes',
        no: 'No',
        select_country_code: 'Select Code',
        us_code: '+1 (USA/Canada)',
        uk_code: '+44 (UK)',
        china_code: '+86 (China)',
        greece_code: '+30 (Greece)',
        australia_code: '+61 (Australia)',
        japan_code: '+81 (Japan)',
        sg_code: '+65 (Singapore)',
        my_code: '+60 (Malaysia)',
        title: 'Your Title',
        phone_number: 'Phone Number',
        address: 'Address',
        city: 'City',
        state: 'State',
        zipcode: 'Zipcode',
        agency_name: 'Agency Name',
        agency_code: 'Agency Code',
        agent_code: 'Agent Code',
        property_outside_country: 'Do you have property outside of your home country?',
        selectYesorNo: 'Select Option',
        about_me: 'About Me',
        social_accounts: 'Social Accounts',
        facebook: 'Facebook Link',
        whatsapp: 'Whatsapp',
        instagram: 'Instagram',
        tiktok: 'Tiktok',
        youtube: 'Youtube',
        save_changes: 'Save Changes',
        whatsapp_number: 'WhatsApp Number',
        instagram_username: 'Instagram Username',
    },
    change_password: {
        change_password: 'Change Password',
        edit_change_password: 'Edit or Change Password',
        dashboard: 'Dashboard',
        my_profile: 'My Profile',
        saved_property: 'Saved Property',
        my_properties: 'My Properties',
        my_subscription: 'My Subscription',
        add_new_property: 'Add New Property',
        change_password_label: 'Change Password',
        change_your_password: 'Change Your Password',
        old_password: 'Old Password',
        new_password: 'New Password',
        confirm_password: 'Confirm Password',
        save_changes: 'Save Changes',
    },
    not_found: {
        back_to_home: 'Back To Home',
        not_found_text: 'This page is not available. Use the navigation menu to explore our properties or return to the homepage. For further assistance, feel free to reach out to our customer support.'
    },
    banner: {
        home_banner_header: 'Search Your Next Home',
        home_banner_text: 'Find new & featured property located in your local city.',
        buy: 'Buy',
        rent: 'Rent',
        country: 'Country/City/Street',
        greece: 'Greece',
        malaysia: 'Malaysia',
        singapore: 'Singapore',
        property_type: 'Property Type',
        apartment: 'Apartment',
        condominium: 'Condominium',
        landed_house: 'Landed house',
        price_range: 'Price Range',
        less_than: 'Less than',
        from: 'From',
        to: 'To',
        above: 'Above',
    },
    listing: {
        most_rated: 'Most Rated',
        most_viewed: 'Most Viewed',
        new_listings: 'New Listings',
        high_rated: 'High Rated',
        select_type: 'Select Property Type',
        apartment: 'Apartment',
        condo: 'Condo',
        family: 'Family',
        detached_house: 'Detached House',
        landed_house: 'Landed House',
        villa: 'Villa',
        less_than: 'Less Than',
        above: 'Above',
        select_price_range: 'Select Price Range',
        select_room_type: 'Select Room Type',
        select_bath_type: 'Select Bathroom Type',
        find_new_home: 'Find New Home',
        reset_filter: 'Reset Filter',
        beds: 'Beds',
        one_beds: '1 Bed',
        two_beds: '2 Beds',
        three_beds: '3 Beds',
        bath: 'Bath',
        view_detail: 'View Detail',
        max_area: 'Max Area',
        min_area: 'Min Area',
        max_price: 'Max Price',
        min_price: 'Min Price',
        location: 'Location',
        enter_price: 'Enter Price',
        sort_by: 'Sort By :',
        open_advance_filter: 'Open Advance Filter',
    },
    visa: {
        enquire_now: 'Enquire Now',
        global_visa_services: 'Global Visa Services',
        greece_header: 'Greece Golden Visa Program',
        greece: 'Greece',
        greece_description: 'Obtain Permanent Residency with an Investment of Just €250,000, Enjoy EU Benefits, Low Immigration Costs, and High Cost-Effectiveness.',
        greece_title1: 'High Cost-Effectiveness',
        greece_text1: 'Investment for three generations (applicant, spouse, and children) without requiring the inclusion of parents.',
        greece_title2: 'Low Investment Threshold',
        greece_text2: 'Only €250,000 needed to purchase property and directly obtain Greek permanent residency.',
        greece_title3: 'No Residency Requirements',
        greece_text3: 'No requirement to live in Greece after obtaining permanent residency.',
        greece_title4: 'Simple Application Requirements',
        greece_text4: 'No need for medical examinations.',
        greece_title5: 'Low Property Tax',
        greece_text5: 'Transaction tax is 3%, with an annual property tax rate of 0.1-0.7%, relatively low compared to other European countries.',
        greece_title6: 'Low Investment Risk',
        greece_text6: 'Property market value has nearly halved, economic recovery is ongoing, and property prices have significant room for growth.',
        greece_title7: 'Travel Freedom',
        greece_text7: 'Greek permanent residents can travel visa-free to 26 Schengen countries.',
        spain: 'Spain',
        spain_header: 'Spain Golden Visa Program',
        spain_description: 'Obtain a Golden Visa for the Whole Family Quickly and Securely with Just €500,000, Free Choice of Property, Transparent Listings.',
        spain_title1: 'For Children\'s Future',
        spain_text1: 'Ensure better opportunities for your children.',
        spain_title2: 'For Investment Returns',
        spain_text2: 'Secure profitable returns on your investment.',
        spain_title3: 'One Application, Three Generations',
        spain_text3: 'One application covers three generations.',
        spain_title4: 'Convenient Travel',
        spain_text4: 'Enjoy visa-free travel to 27 European Schengen Area countries.',
        spain_title5: 'For Clients Without Time to Travel Temporarily',
        spain_text5: 'Suitable for clients who are unable to travel frequently.',
        spain_title6: 'Application Requirements',
        spain_text6_1: '1. The applicant must be at least 18 years old.',
        spain_text6_2: '2. The applicant must have no criminal record.',
        spain_text6_3: '3. The applicant must purchase property in Spain worth 500,000 Euros or more (there is no restriction on property type, including apartments, row houses, independent houses, commercial properties, etc.). It is possible to purchase multiple properties as long as the total net value exceeds 500,000 Euros.',
        portugal: 'Portugal',
        portugal_header: 'Portugal Golden Visa Program',
        portugal_description: 'Obtain a Portugal Golden Visa through investment of 500,000 Euros in funds',
        portugal_title1: 'Safe Investment',
        portugal_text1: 'Investment in property is safe and reliable, offering more stable returns.',
        portugal_title2: 'European Identity',
        portugal_text2: 'With direct access to Europe, enjoy visa-free travel to 26 Schengen countries.',
        portugal_title3: 'Residency Status',
        portugal_text3: 'Holders of the Golden Visa can live, work, and conduct business in Portugal.',
        portugal_title4: 'Family Benefits',
        portugal_text4: 'One application can include three generations of the family.',
        portugal_title5: 'Education and Healthcare',
        portugal_text5: 'The holder and their family can enjoy free public education and free public healthcare in Portugal.',
        portugal_title6: 'No Residency Requirement',
        portugal_text6: 'Only 7 days of residence are required each year, and after 5 years, it is easy to convert to permanent residency without the need to relocate.',
        portugal_title7: 'Low Tax Burden',
        portugal_text7: 'Portugal offers no inheritance tax and no global tax.',
        portugal_title8: 'Favorable Living Environment',
        portugal_text8: 'Portugal has a mild climate, plenty of sunshine, low cost of living, a high proficiency of English, and is suitable for long-term residence.',
        portugal_title9: 'Application Requirements',
        portugal_text9: '1. The applicant must be at least 18 years old. 2. The applicant must have no criminal record. 3. The applicant must invest 500,000 Euros in a fund. 4. The applicant must reside in Portugal for at least 14 days within every 2-year period.',
        cyprus: 'Cyprus',
        cyprus_header: 'Cyprus Golden Visa Program',
        cyprus_description: 'Obtain a Portugal Golden Visa through investment of 500,000 Euros in funds',
        cyprus_title1: 'Benefits for Elderly Parents and Children',
        cyprus_text1: 'Inclusive benefits for three generations.',
        cyprus_title2: 'Low Immigration Costs',
        cyprus_text2: 'Minimal overall expenses.',
        cyprus_title3: 'No Continuous Residency Requirement',
        cyprus_text3: 'No need to reside continuously in Cyprus.',
        cyprus_title4: 'Application Requirement',
        cyprus_text4: '1. The main applicant must be at least 18 years old with no criminal record. ' +
            '2. Purchase property in Cyprus with a minimum value of €300,000. ' +
            '3. Annual income must be at least €50,000, plus €15,000 for a spouse, and €10,000 for each additional child. ' +
            '(Example: A family of three applying requires an annual income of €50,000 + €15,000 + €10,000 = €75,000/year; ' +
            'wages, pensions, rent, dividends, shares, and other sources of income are acceptable.)',
        cyprus_title5: 'Accompanying Family Members',
        cyprus_text5: '1. Spouse (including common-law spouse). ' +
            '2. Children: unmarried children under 18 years old, and unmarried children aged 18-25 who are currently studying full-time.',
        uk: 'United Kingdom',
        uk_header: 'UK Innovator Founder Visa',
        uk_description: 'Achieve immigrant status in the UK by establishing an innovative, feasible, and scalable business.',
        uk_title1: 'Quick Pathway to UK Residency',
        uk_text1: 'Fast track to obtaining a UK green card.',
        uk_title2: 'Low Investment Requirement',
        uk_text2: 'A minimum investment of 50,000 GBP.',
        uk_title3: 'No Prior Business Experience Needed',
        uk_text3: 'No previous entrepreneurial experience is required.',
        uk_title4: 'Family Inclusion',
        uk_text4: 'The main applicant can include their spouse and children under 18 years old in the application.',
        uk_title5: 'Permanent Residency',
        uk_text5: 'The main applicant can apply for permanent residency after 3 years, and the spouse and children can apply after 5 years.',
        uk_title6: 'Application Requirement',
        uk_text6_1: '1. Provide an IELTS score of 5.5 or above. If the applicant has a bachelor\'s degree or higher from a recognized English-taught program in the UK, IELTS may be waived.',
        uk_text6_2: '2. Have at least 50,000 GBP available for investment.',
        uk_text6_3: '3. After approval, the business established in the UK must demonstrate innovation, viability, and scalability.',
        uk_text6_4: '4. Pass a health examination with no serious medical conditions.',
        uk_title7: 'Accompanying Family Members',
        uk_text7: 'Spouse, civil or unmarried partner, and dependent children aged 18 and under (or over 18 if currently in the UK).',
        uk_title8: 'Processing time',
        uk_text8: 'The average processing time for obtaining a UK Innovator and Founder visa is 3 weeks if the applicant is located outside the UK; the average processing time is 8 weeks if the applicant is located in the UK.',
        italy: 'Italy',
        italy_header: 'Italy Golden Visa Program',
        italy_description: 'Offers non-EU investors Italian residency through several investment options, with benefits such as the right to live, work, and study in the EU.',
        italy_title1: 'No language test required',
        italy_title2: 'No minimum stay in Italy',
        italy_title3: 'Live, work and study in Italy.',
        italy_title4: 'Live in Italy and enjoy the benefits of a country ranked number one for climate, heritage and food.',
        italy_title5: 'Access to one of the best education and healthcare systems in the world.',
        italy_title6: 'Tax exemption on gifts, inheritances and profits outside Italy.',
        italy_title7: 'Holders enjoy the freedom to travel within the Schengen Zone.',
        italy_title8: 'Several Investment Forms.',
        italy_text8_1: 'A minimum of €2 million in government bonds.',
        italy_text8_2: 'At least €500,000 in corporate bonds or shares.',
        italy_text8_3: 'A minimum of €250,000 in innovative startups.',
        italy_title9: 'Application Requirements',
        italy_text9_1: 'The applicant must be at least 18 years old.',
        italy_text9_2: 'The applicant must have no criminal record.',
        italy_text9_3: 'The applicant must hold a valid health insurance that covers all risks in Italy.',
        italy_text9_4: 'The applicant must provide proof of the legal source of the invested funds.',
        italy_text9_5: 'The applicant must comply with the minimum stay requirements to maintain and renew the residency permit.',
        italy_title10: 'Accompanying Family Members',
        italy_text10_1: 'Legally married spouse.',
        italy_text10_2: 'Minor children under 18 years old or dependent adult children who are students and are not married.',
        italy_text10_3: 'Parents who are financially dependent on the investor can be included if they meet specific age and dependency requirements.',
    },
    citizenship: {
        citizenship_investment: 'Citizenship by Investment',
        list_of_countries: 'List of Countries',
        citizenship_investment_text: 'The Citizenship by Investment Programme offers foreign individuals and their families the opportunity to obtain citizenship through a certificate of naturalization by direct investment.',
        dominica: 'Dominica',
        north_macedonia: 'North Macedonia',
        grenada: 'Grenada',
        stkitts_neviss: 'Saint Kitts and Nevis',
        antiguaBarbuda: 'Antigua and Barbuda',
        montenegro: 'Montenegro',
        vanuatu: 'Vanuatu',
        malta: 'Malta',
        saintLucia: 'Saint Lucia',
        turkey: 'Turkey',
        dominica_description: 'The Dominica Citizenship by Investment Program was launched in 1993 by the Government of the Commonwealth of Dominica and allows foreign investors to obtain irrevocable citizenship without residency requirements or the need to renounce their current nationality.',
        dominica_title1: 'Processing time',
        dominica_text1: 'The average processing time for obtaining Dominica citizenship is 4 months.',
        dominica_title2: 'Visa-free countries',
        dominica_text2: 'Instant visa-free travel to 144 countries/regions, including Schengen countries, Singapore, Hong Kong and China.',
        dominica_title3: 'Investment type',
        dominica_text3: 'Donation to Dominica government funds or real estate investment.',
        dominica_title4: 'Investment cost',
        dominica_text4: ' US$100,000 for a single applicant and US$175,000 for a family of four.',
        dominica_title5: 'Family dependents',
        dominica_text5: 'Spouse, children under 30 years old, parents/grandparents over 65 years old.',
        dominica_title6: 'Passport validity',
        dominica_text6: 'For people over 16, it is valid for 10 years. For children under 16, it is valid for five years.',
        dominica_title7: 'Good identity',
        dominica_text7: 'Dominica allows dual citizenship without any restrictions. There is no need to renounce your current citizenship.',
        dominica_title8: 'Family Benefits',
        dominica_text8_1: 'You and your family can hold irrevocable citizenship for life. In addition, future generations can also benefit from citizenship by descent.',
        dominica_text8_2: 'In addition to the main applicant, spouse, children under 30 years old, and parents/grandparents over 65 years old can also obtain a Dominica passport.',
        dominica_title9: 'International Travel Benefits',
        dominica_text9_1: 'The Dominican Republic\'s prestigious passport provides visa-free access to over 144 countries, including the UK and the Schengen Area.',
        dominica_text9_2: 'Residency Rights Dominican passports offer extended residency in the UK for up to 6 months and an easy visa application process.',
        dominica_text9_3: 'Embrace a life of freedom and the right to reside in the Dominican Republic and many other CARICOM member countries for as long as you wish.',
        dominica_title10: 'Tax and financial benefits',
        dominica_text10_1: 'There are no capital gains, gift, wealth and inheritance taxes; non-residents pay no tax.',
        dominica_text10_2: 'You can open bank accounts with reputable financial institutions around the world.',
        dominica_text10_3: 'Duty-free trade in the Caribbean.',
        dominica_text10_4: 'There are no restrictions on the repatriation of profits and imported capital.',
        enquire_now: 'Enquire Now',
        north_macedonia_description: 'The North Macedonia Citizenship by Investment Program enables foreign individuals to invest in the country and seek alternative citizenship by investing in a government-approved fund. North Macedonia has one of the most attractive and business-friendly citizenship investment programs in Europe. However, it is important to note that only a limited number of individuals, specifically 1,000 applicants worldwide, will be accepted into the program. North Macedonia is a NATO member, EU member, and a fully democratic republic in the heart of Europe.',
        north_macedonia_title1: 'Processing time',
        north_macedonia_text1: 'The average processing time for obtaining North Macedonian citizenship is 4 months.',
        north_macedonia_title2: 'Visa-free countries',
        north_macedonia_text2: 'Instant visa-free travel to 125 countries, including Schengen countries, China and Japan.',
        north_macedonia_title3: 'Investment type',
        north_macedonia_text3: 'Investment in a private investment fund or direct investment in a new facility.',
        north_macedonia_title4: 'Investment cost',
        north_macedonia_text4: 'From 200,000 EURO.',
        north_macedonia_title5: 'Family dependents',
        north_macedonia_text5: 'Spouse and dependent children under 18 years of age (spouse will be granted citizenship one year after the principal applicant and dependent children).',
        north_macedonia_title6: 'Passport validity',
        north_macedonia_text6: 'North Macedonian passports are valid for ten (10) years.',
        north_macedonia_title7: 'Fast and easy',
        north_macedonia_text7: 'There are no language tests, experience, or minimum stay requirements.',
        north_macedonia_title8: 'Freedom and EU citizenship',
        north_macedonia_text8_1: 'Visa-free travel to over 125 countries including the EU, Schengen, China, and Japan.',
        north_macedonia_text8_2: 'Candidate for EU membership.',
        north_macedonia_text8_3: 'Dual citizenship is allowed.',
        north_macedonia_title9: 'Wealth Protection',
        north_macedonia_text9_1: 'Fixed currency is Euro.',
        north_macedonia_text9_2: 'Zero tax for non-residents.',
        north_macedonia_text9_3: '100% company ownership, zero tax in manufacturing free zones.',
        grenada_description: 'Launched in August 2013, the Grenada Citizenship by Investment Program offers investors numerous benefits, including visa-free access to Schengen countries, the United Kingdom, and China, as well as being the only Caribbean country with a CBI program and a treaty with the United States, allowing Grenadian passport holders to apply for an E-2 US "Investor Visa" to reside in the U.S., without the need to reside in Grenada or renounce their current citizenship to obtain irrevocable citizenship and passports.',
        grenada_title1: 'Processing time',
        grenada_text1: 'The average processing time for obtaining Grenada citizenship is 5 months.',
        grenada_title2: 'Visa-free countries',
        grenada_text2: 'Instant visa-free travel to 145 countries, including Schengen countries, China, UK, Singapore, and Hong Kong.',
        grenada_title3: 'Investment type',
        grenada_text3: 'Donate to the Grenada government or invest in real estate projects approved by the Grenada government.',
        grenada_title4: 'Investment cost',
        grenada_text4: '$150,000.',
        grenada_title5: 'Family dependents',
        grenada_text5: 'Spouse, children under 30 years old, elderly parents over 55 years old, brothers and sisters over 18 years old.',
        grenada_title6: 'Passport validity',
        grenada_text6: 'Grenadian passports are valid for five (5) years.',
        grenada_title7: 'Good identity',
        grenada_text7_1: 'Grenada Citizenship by Investment offers you irrevocable citizenship for life.',
        grenada_text7_2: 'Descendants can also benefit from citizenship by descent.',
        grenada_text7_3: 'Grenada allows dual citizenship without any restrictions, so you can keep your current citizenship without any limitations.',
        grenada_text7_4: 'In addition to the main applicant, the spouse, children under 30 years of age, elderly parents over 55 years of age, and siblings aged 18 years and above can also benefit from Grenadian citizenship.',
        grenada_title8: 'A passport in good standing',
        grenada_text8_1: 'Grenadian passport holders can travel visa-free to 145 countries, including China, Schengen countries, the United Kingdom, Singapore, Hong Kong, and Australia.',
        grenada_text8_2: 'Biometric e-passports for enhanced security and anti-counterfeiting measures.',
        grenada_text8_3: 'The Treaty of Commerce and Navigation with the United States allows citizens of Grenada to apply for an E-2 visa, which gives them the right to reside in the United States.',
        grenada_title9: 'Tax incentives',
        grenada_text9_1: 'Grenadian passport holders enjoy tax exemptions on capital gains, gifts, wealth, and inheritance, while non-residents are not subject to tax.',
        grenada_text9_2: 'Grenadian citizenship offers attractive benefits such as corporate tax incentives, complete exemption from import duties, tax relief incentives, and export subsidies.',
        stkitts_neviss_description: 'Established in 1984, the St. Kitts and Nevis Citizenship by Investment Program is the oldest CBI program in existence, offering foreigners the opportunity to obtain irrevocable citizenship without having to renounce their nationality or reside in the country.',
        stkitts_neviss_title1: 'Processing Time',
        stkitts_neviss_text1: 'The average processing time for obtaining St. Kitts and Nevis citizenship is 4 months',
        stkitts_neviss_title2: 'Visa-free countries',
        stkitts_neviss_text2: 'Citizens can enter 156 countries without a visa, including the Schengen countries, the United Kingdom, Switzerland, Singapore, Hong Kong, and Turkey.',
        stkitts_neviss_title3: 'Investment type',
        stkitts_neviss_text3: 'Donation to Sustainable Island Nations (SISC) or real estate investment or public benefit option donation',
        stkitts_neviss_title4: 'Investment cost',
        stkitts_neviss_text4: '$250,000',
        stkitts_neviss_title5: 'Family Dependents',
        stkitts_neviss_text5: 'You can include your spouse, children 25 years and under, siblings, and parents 65 years and over in your application.',
        stkitts_neviss_title6: 'Children\'s Eligibility',
        stkitts_neviss_text6: 'To include dependent children in your application, they must be unmarried and under the age of 25, in full-time education. If the child is over 25, a separate application must be submitted.',
        stkitts_neviss_title7: 'Eligibility Requirements',
        stkitts_neviss_text7a: 'Age: Applicants must be 18 years of age or older and provide evidence of a clean criminal record.',
        stkitts_neviss_text7b: 'Good character: Must undergo criminal background check.',
        stkitts_neviss_text7c: 'Financial Capability: Outstanding character must be demonstrated through bank statements and employer references.',
        stkitts_neviss_text7d: 'Right of residence: There are no residency requirements to obtain citizenship.',
        stkitts_neviss_title8: 'Passport Validity',
        stkitts_neviss_text8: 'Your St. Kitts and Nevis passport is valid for 10 years and can be renewed indefinitely. For children under 16 years old, the passport is valid for 5 years.',
        stkitts_neviss_title9: 'A passport in good standing',
        stkitts_neviss_text9: 'Upon successful application, investors and their families will receive a powerful passport that provides visa-free or visa-on-arrival travel to 166 countries, including the Schengen Area, the United Kingdom, and most recently, Canada.',
        stkitts_neviss_title10: 'Business or Family',
        stkitts_neviss_text10: 'One of the most notable features of the St. Kitts and Nevis Citizenship by Investment Program is its hereditary nature. Once a person is granted citizenship, it can be passed down from generation to generation, providing a lasting legacy for a family.',
        antiguaBarbuda_description: 'Antigua and Barbuda Citizenship by Investment is a legal program that allows applicants to obtain irrevocable citizenship and passport without having to reside in the country or renounce their current nationality.',
        antiguaBarbuda_title1: 'Processing time',
        antiguaBarbuda_text1: 'The average processing time for obtaining Antigua and Barbuda citizenship is 6 months.',
        antiguaBarbuda_title2: 'Visa-free countries',
        antiguaBarbuda_text2: 'Instant visa-free travel to 152 countries, including Schengen countries, the United Kingdom, Singapore, China and Hong Kong',
        antiguaBarbuda_title3: 'Type of investment',
        antiguaBarbuda_text3: 'Financial contribution to Antigua\'s National Development Fund (NDF), real estate investment or University of the West Indies (UWI) Fund.',
        antiguaBarbuda_title4: 'Investment cost',
        antiguaBarbuda_text4: 'Financial contribution ($130,000 for a family of 4) or real estate investment ($200,000 plus government fees)',
        antiguaBarbuda_title5: 'Family dependents',
        antiguaBarbuda_text5: 'Spouse, children under 30 years old, parents and grandparents 55 years old and above, and siblings with no age limit.',
        antiguaBarbuda_title6: 'Passport validity',
        antiguaBarbuda_text6: 'Initially valid for five (5) years for persons 18 years and older, and renewed for 10 years',
        antiguaBarbuda_title7: 'Good identity',
        antiguaBarbuda_text7_1: 'Antigua and Barbuda Citizenship by Investment offers irrevocable citizenship, extended to future generations through citizenship by descent',
        antiguaBarbuda_title8: 'A passport in good standing',
        antiguaBarbuda_text8_1: 'Antigua and Barbuda citizenship provides visa-free travel to 152 countries, including Schengen Area countries, the United Kingdom, Singapore, China and Hong Kong.',
        antiguaBarbuda_text8_2: 'You can stay in the UK for up to 6 months per visit.',
        antiguaBarbuda_title9: 'Personal and business interests',
        antiguaBarbuda_text9_1: 'There are no capital gains, gift, wealth and inheritance taxes; non-residents pay no tax.',
        antiguaBarbuda_text9_2: 'Ability to open bank accounts with reliable banking institutions worldwide using Antigua and Barbuda passport.',
        antiguaBarbuda_text9_3: 'Duty-free trade in the Caribbean.',
        antiguaBarbuda_title10: 'Eligibility Requirements',
        antiguaBarbuda_text10_1: 'Age: Applicants must be 18 years of age or older and provide evidence of a clean criminal record.',
        antiguaBarbuda_text10_2: 'Good character: Must not be denied entry to a country with which Antigua and Barbuda has a visa waiver agreement.',
        antiguaBarbuda_text10_3: 'Healthy: Applicants must be in good health and provide a medical certificate to prove this.',
        antiguaBarbuda_text10_4: 'Residential: Applicants must reside in Antigua and Barbuda for at least five days within the first five years of citizenship.',
        antiguaBarbuda_title11: 'Due Diligence and Background Checks',
        antiguaBarbuda_text11_1: 'Applicable to all options',
        antiguaBarbuda_text11_2: 'The government requires mandatory background checks to ensure the integrity of the program. The government-appointed Due Diligence Agency is responsible for conducting these checks and utilizes different international and local resources such as Interpol, World-Check and other government agencies. The agency checks applicants’ profiles to ensure that they are of good character and do not pose a security risk to Antigua and Barbuda.',
        antiguaBarbuda_text11_3: 'Due Diligence Fee Principal Applicant: USD 7,500 Spouse: USD 7,500',
        antiguaBarbuda_text11_4: 'Dependent children ages 12-17: $2,000',
        antiguaBarbuda_text11_5: 'Dependent children ages 18-30: $4,000',
        antiguaBarbuda_text11_6: 'Dependent parents over 55: $4,000',
        montenegro_description: 'The Montenegrin Citizenship by Investment Program was initiated by the Montenegrin Government as part of its ongoing efforts to attract foreign direct investment and increase economic activity in the country. The Montenegrin Economic Citizenship Program allows foreigners to obtain Montenegrin citizenship through designated investments by the Ministry of Internal Affairs and Public Administration.',
        montenegro_title1: 'Processing time',
        montenegro_text1: 'Residency within 3 weeks, citizenship within 6 months.',
        montenegro_title2: 'Visa-free travel',
        montenegro_text2: 'Enjoy global mobility with visa-free access to 116 countries and territories.',
        montenegro_title3: 'Investment type',
        montenegro_text3: 'National Transformation Fund donation, real estate investment',
        montenegro_title4: 'Investment cost',
        montenegro_text4: 'Applicants must donate 100,000 euros to the underdeveloped urban development fund in the form of government funds, 250,000 euros to underdeveloped areas, and 450,000 euros to developed areas',
        montenegro_title5: 'Family Dependents',
        montenegro_text5: 'Children under the age of 18 of the principal applicant may also obtain citizenship.',
        montenegro_title6: 'Passport Validity',
        montenegro_text6: 'Montenegrin passports are valid for ten (10) years for adults and minors.',
        montenegro_title7: 'Fast track application: 6 months',
        montenegro_text7_1: 'No minimum residence period before and after immigration',
        montenegro_title8: 'Dual citizenship allowed',
        montenegro_title9: 'Famous Passports',
        montenegro_text9_1: 'Visa-free access to 123 countries, including the Schengen Area, Russia, Brazil and Türkiye',
        montenegro_title10: 'Elite Program',
        montenegro_text10_1: 'Family members included',
        montenegro_text10_2: 'Descendants can also become citizens',
        montenegro_title11: 'Due Diligence and Background Checks',
        montenegro_text11_1: 'Applicants must invest in a government-approved development project. Investments can be made in developed or undeveloped areas:',
        montenegro_text11_2: 'EUR 250,000 for development projects in the less developed regions of Montenegro (northern and central Montenegro, excluding Podgorica); or',
        montenegro_text11_3: 'Development projects in developed areas of Montenegro (seaside and capital Podgorica) for €450,000.',
        montenegro_text11_4: 'Government-approved development projects can come from the tourism, agriculture and manufacturing sectors.',
        montenegro_text11_5: 'Donation applicants are also required to pay a non-refundable 100,000 euros donation to a government fund to contribute to the Montenegrin economy. The fund will be used to develop underdeveloped areas.',
        montenegro_title12: 'Due Diligence and Background Checks',
        montenegro_text12_1: 'Age: Applicants must be 18 years of age or older',
        montenegro_text12_2: 'Healthy: Applicants must be in good health and have a global health insurance policy.',
        montenegro_text12_3: 'Good character: The main applicant must be of good character and have no criminal record',
        montenegro_text12_4: 'Eligible Investments: Qualifying investments must be made and can take various forms such as real estate acquisitions, investments in financial instruments or contributions to national development and social funds.',
        vanuatu_description: 'The Vanuatu Permanent Residence Program Immigration Act was launched in 2011. It allows permanent residence in one step. It was initially used for Hong Kong investment clients as a third-country identity (mainland residents), and was later widely used for overseas identity reserves, enrollment in domestic purely foreign international schools, travel with foreign passports, exit convenience, tourism and retirement settlement in Vanuatu, and early renewal of Chinese passports.',
        vanuatu_title1: 'Processing time',
        vanuatu_text1: 'Average processing time for obtaining Vanuatu citizenship is 10 years for a direct investment of $130,000.',
        vanuatu_title2: 'Visa-free countries',
        vanuatu_text2: 'Vanuatu passport holders can travel to 148 countries without a visa.',
        vanuatu_title3: 'Investment type',
        vanuatu_text3: 'Donation',
        vanuatu_title4: 'Investment costs',
        vanuatu_text4: 'Vanuatu’s Citizenship by Investment Program requires a government donation of US$130,000 for a family of up to four applicants. US$50,000 of this is invested in shares in approved funds and can be redeemed after five years. This effectively means the net cost of the program is US$80,000, plus processing and due diligence fees.',
        vanuatu_title5: 'Family Dependents',
        vanuatu_text5: 'Spouse\'s financially dependent children, and dependent parents over 65 years of age.',
        vanuatu_title6: 'Passport Validity',
        vanuatu_text6: 'The Anuwatu passport is valid for ten (10) years for adults and minors.',
        vanuatu_title7: 'Family Benefits',
        vanuatu_text7_1: 'By investing in Vanuatu citizenship, you and your family can enjoy the benefits of lifelong, irrevocable citizenship.',
        vanuatu_text7_2: 'Your future family members may also acquire citizenship by descent.',
        vanuatu_title8: 'Investment Flexibility',
        vanuatu_text8_1: 'The plan is very flexible in terms of fund transfers.',
        vanuatu_text8_2: 'The investment payment is only required after obtaining Vanuatu citizenship, not before.',
        vanuatu_title9: 'Tax advantages',
        vanuatu_text9_1: 'Capital gains, gifts, wealth and inheritances are not taxed and non-residents are not required to pay tax.',
        vanuatu_text9_2: 'In addition, Vanuatu citizenship also provides the right to remit profits and import capital without restrictions.',
        vanuatu_title10: 'Global Banking Access',
        vanuatu_text10_1: 'As a citizen of Vanuatu, you can open bank accounts with reputable banking institutions worldwide.',
        vanuatu_title11: 'Due Diligence and Background Checks',
        vanuatu_text11_1: 'In addition to the required investment, there are other costs associated with the Vanuatu Citizenship by Investment Program, such as Citizenship by Investment professional fees, official application forms, citizenship and passport certification, courier fees, and other related expenses. In addition, you will receive a detailed quote tailored to your situation with no hidden fees.',
        vanuatu_text11_2: 'Due diligence fee per application: US$5,000',
        vanuatu_title12: 'Eligibility Requirements',
        vanuatu_text12_1: 'Age: Applicants must be 18 years of age or older',
        vanuatu_text12_2: 'Good character: Applicants must have no criminal record and be of good reputation.',
        vanuatu_text12_3: 'Healthy: Applicants must be in good health and provide a medical certificate to prove this.',
        malta_description: 'The Malta Citizenship by Investment Programme offers foreign individuals and their families the opportunity to obtain Maltese citizenship through a certificate of naturalization by direct investment. The programme is governed by the Malta Citizenship Act 188 and the Grant of Citizenship by Direct Investment Regulations (SL 188.06). Successful applicants will have contributed to Malta\'s economic growth and development.',
        malta_title1: 'Processing period',
        malta_text1: '1 year',
        malta_title2: 'Visa-free countries',
        malta_text2: 'Maltese passport holders can travel to 184 countries without a visa.',
        malta_title3: 'Investment Type',
        malta_text3: 'Non-refundable investment + property purchase or lease + charitable donation.',
        malta_title4: 'Investment costs',
        malta_text4: 'To obtain Maltese citizenship you need: a non-refundable investment of €750,000 after one year or €600,000 after three years, €700,000 in real estate or €16,000 in annual property rent for five years, €10,000 in charitable donations, and €50,000 for each additional dependent.',
        malta_title5: 'Family Dependents',
        malta_text5: 'Spouse, unmarried and financially dependent children under 28, and dependent parents and grandparents over 55.',
        malta_title6: 'Passport Validity',
        malta_text6: 'Maltese passports are valid for ten (10) years for adults and minors.',
        malta_title7: 'Fast track to European citizenship',
        malta_text7_1: 'Malta Citizenship by Investment allows you to obtain a three-week fast-track residence card in the Schengen area while you wait for your citizenship to be issued.',
        malta_text7_2: 'It usually takes 14 to 38 months from the date of initial residence to obtain a naturalization certificate.',
        malta_title8: 'A passport in good standing',
        malta_text8_1: 'Maltese passport holders enjoy visa-free or visa-on-arrival travel to 184 destinations, including Canada and the European Union.',
        malta_text8_2: 'The right to live, work or study in any of the 28 EU member states, including Norway, Iceland, Switzerland and Liechtenstein. English-speaking Malta also opens up a world of new opportunities for you and your family.',
        malta_title9: 'Elite Program',
        malta_text9_1: 'Malta’s Citizenship by Investment Program has the most stringent due diligence standards in the world, ensuring only reputable applicants are considered.',
        malta_text9_2: 'Furthermore, Malta is an attractive place to live or own a second home, with a strategic location, good air links and an English-speaking country and workforce.',
        malta_title10: 'Due Diligence and Background Checks',
        malta_text10_1: 'Applicable to all investment options The government requires background checks and payment to ensure the integrity of the Malta Citizenship by Investment Program. The government appoints a preferred due diligence agency which conducts checks using different international and local sources such as Interpol, World-Check and other government entities.',
        malta_text10_2: 'As part of the process, applicants must submit documents such as their passports, birth certificates and bank statements, and the agency thoroughly checks each applicant’s past to ensure they are of good character and do not pose a threat to Malta’s security.',
        malta_text10_3: 'Due Diligence Fees',
        malta_text10_4: 'Main applicant: USD 15,000',
        malta_text10_5: 'Spouse: $10,000',
        malta_text10_6: 'Children 28 and parents 55 and older: $10,000',
        malta_title11: 'Eligibility Requirements',
        malta_text11_1: 'Age: Applicants must be 18 years of age or older and provide evidence of a clean criminal record.',
        malta_text11_2: 'Healthy: Applicants must be in good health and have global health insurance.',
        malta_text11_3: 'Legal budget: Clear legal links with Malta are also required, which can be demonstrated through property ownership or leases, or through non-refundable contributions to the National Development and Social Fund.',
        malta_text11_4: 'Qualifying Investments: Qualifying investments must be made and can take various forms, such as real estate acquisitions, investments in financial instruments or contributions to national development and social funds.',
        saintLucia_description: 'Established in 2015, the Saint Lucia Citizenship by Investment Program offers a fast and efficient way to obtain irrevocable citizenship without the need to reside in the country or renounce an existing citizenship.',
        saintLucia_title1: '​Processing Time',
        saintLucia_text1: 'The average processing time for obtaining Saint Lucia citizenship is 3 - 4 months.',
        saintLucia_title2: 'Visa-free countries',
        saintLucia_text2: 'Instant visa-free travel to 146 countries, including Schengen countries, the United Kingdom, Singapore and Hong Kong',
        saintLucia_title3: 'Investment Type',
        saintLucia_text3: 'Contribution to Saint Lucia Government Fund or Real Estate Investment or Government Bonds or Infrastructure Options',
        saintLucia_title4: 'Investment cost',
        saintLucia_text4: 'Starting from $100,000',
        saintLucia_title5: 'Family members',
        saintLucia_text5: 'Spouse, children under 30, elderly parents over 55, and siblings under 18',
        saintLucia_title6: 'Passport Validity',
        saintLucia_text6: 'Saint Lucia passports are valid for five (5) years.',
        saintLucia_title7: 'Family Benefits',
        saintLucia_text7_1: 'Saint Lucia Citizenship by Investment offers the advantages of irrevocable citizenship for you and your family members, while future generations can also benefit from citizenship by descent.',
        saintLucia_text7_2: 'In addition to the main applicant, spouse, children below 30 years old, elderly parents above 55 years old, siblings aged 18 years and above can also benefit from the scheme.',
        saintLucia_title8: 'Visa-free travel',
        saintLucia_text8_1: 'You can travel visa-free to 146 countries, including the Schengen Area countries, the UK and many Commonwealth member states.',
        saintLucia_text8_2: 'Extended stay in the UK With a Saint Lucia passport you can stay in the UK for up to 6 months per entry.',
        saintLucia_title9: 'Personal and business interests',
        saintLucia_text9_1: 'Personal benefits capital gains, gifts, wealth and inheritance are tax-free, and non-residents are not taxed.',
        saintLucia_text9_2: 'You can open a bank account with reliable banking institutions around the world.',
        saintLucia_text9_3: 'Duty-free trade in the Caribbean.',
        saintLucia_title10: 'Due Diligence and Background Checks',
        saintLucia_text10_1: 'A mandatory background check must be carried out with the government to ensure the integrity of the Saint Lucia Citizenship by Investment Program. The government appoints a preferred due diligence agency which conducts checks using a variety of international and local sources such as Interpol, World-Check and other government agencies.',
        saintLucia_text10_2: 'Due Diligence Fee Principal Applicant: USD 8,000 Spouse: USD 5,000',
        saintLucia_text10_3: 'Dependents over 16 years old: USD 5,000',
        saintLucia_title11: 'Eligibility Requirements',
        saintLucia_text11_1: 'Age: Applicants must be 18 years of age or older and provide evidence of a clean criminal record.Healthy: Applicants must be in good health and have global health insurance.',
        saintLucia_text11_2: 'Good character: You may not be denied entry to a country that has a visa-free agreement with Saint Lucia.',
        saintLucia_text11_3: 'Healthy: Meet health and character requirements.',
        saintLucia_text11_4: 'Invest: Make a qualifying investment in Saint Lucia',
        turkey_description: 'The Turkish government launched the Turkey Citizenship by Investment Program in January 2017 to encourage foreign direct investment and develop the country\'s real estate industry. The program allows foreign investors to obtain Turkish citizenship and an irrevocable Turkish passport without having to reside in the country or give up their current citizenship.',
        turkey_title1: '​Processing Time',
        turkey_text1: 'The average processing time for obtaining Turkish citizenship is 2-5 months',
        turkey_title2: 'Visa-free countries',
        turkey_text2: 'Turkish passport holders can enter 111 countries without a visa, including Japan, South Korea and Singapore.',
        turkey_title3: 'Investment type',
        turkey_text3: 'Real estate investment',
        turkey_title4: 'Investment cost',
        turkey_text4: '$400,000',
        turkey_title5: 'Family members',
        turkey_text5: 'Spouse and children under 18 years old.',
        turkey_title6: 'Passport Validity',
        turkey_text6: 'Turkish passports are valid for ten (10) years for individuals over sixteen years of age and five (5) years for children under sixteen years of age.',
        turkey_title7: 'Family Benefits',
        turkey_text7_1: 'You and your family benefit from irrevocable Turkish citizenship for life, and future family members can obtain citizenship by descent.',
        turkey_text7_2: 'In addition to the main applicant, spouse and children under 18 years old can also obtain a Turkish passport.',
        turkey_title8: 'Good identity',
        turkey_text8_1: 'The effortless process does not require giving up current citizenship.',
        turkey_title9: 'Travel Benefits',
        turkey_text9_1: 'Visa-free travel to 111 countries, including Japan, South Korea and Singapore.',
        turkey_title10: 'A passport in good standing',
        turkey_text10_1: 'A Turkish passport in good standing can easily obtain a visa when needed.',
        turkey_title11: 'Business and Security Benefits',
        turkey_text11_1: 'The E-2 treaty between Turkey and the United States allows Turkish citizens to operate substantial businesses and reside in the United States.',
        turkey_title12: 'Return on investment',
        turkey_text12_1: 'Investors can recover their invested funds three years after obtaining Turkish citizenship.',
        turkey_text12_2: 'Housing costs in Türkiye increase by 15-25% every year, providing extra profit for selling the property after three years.',
        turkey_title13: 'Investment Types and Costs',
        turkey_text13_1: 'To qualify for the Turkish Citizenship by Investment Program, applicants must make a real estate investment in the Turkish real estate market or a capital transfer in one of Turkey’s approved sectors.',
        turkey_text13_2: 'Option 1',
        turkey_text13_3: 'Real estate investment: Investors must purchase freehold property with title deeds or land and the investment should be made before applying for citizenship. Real estate investments must be kept for 3 years.',
        turkey_text13_4: 'Minimum investment: $400,000',
        turkey_text13_5: 'Option 2',
        turkey_text13_6: 'Capital Transfer: Investors can obtain citizenship by making a capital transfer in one of Turkey’s approved sectors. The investment must be maintained for 3 years. Government bonds: $500,000 Turkish bank deposits: $500,000 Investment funds: $500,000 Fixed capital contribution: $500,000',
        turkey_title14: 'Eligibility Requirements',
        turkey_text14_1: 'Age: Applicants must be 18 years of age or older and provide evidence of a clean criminal record.',
        turkey_text14_2: 'Good character: Applicants must have a clean criminal record and a good reputation.',
        turkey_text14_3: 'Healthy: Applicants must be in good health and provide a medical certificate to prove this.',
        turkey_text14_4: 'Maintain: The investment must be held for three years to obtain citizenship.',
    },
    home: {
        singapore_top_viewed: 'Top Viewed Singapore Properties',
        singapore_top_viewed_label: 'Discover the most sought-after properties in Singapore. Explore prime locations, luxurious amenities, and exclusive residences that define urban living in the Lion City.',
        contact_us: 'Contact Us',
        home_banner_citizenship: 'Citizenship by Investment',
        home_banner_successful_apps: 'Successful Applications',
        home_banner_positive_reviews: 'Positive Reviews',
        home_banner_global_visa: 'Global Visa',
        featured_property: 'Featured Property Types',
        featured_property_title: 'Find All Type of Property',
        family_house: 'Family House',
        all_property: 'Find All Type of Property',
        property: 'Property',
        house_villa: 'House & Villa',
        apartment: 'Apartment',
        office_studio: 'Office & Studio',
        villa_condo: 'Villa & Condo',
        greece_featured: 'Greece Featured Listed Property',
        greece_featured_text: 'Discover the epitome of Mediterranean luxury with this stunning villa located on the pristine coast of Greece.',
        beds: 'Beds',
        bath: 'Bath',
        malaysia_featured: 'Malaysia Listed Sale Property',
        malaysia_featured_text: 'Experience the perfect blend of modern luxury and cultural richness with this exquisite property in Malaysia.',
        citizenship_investment: 'Citizenship by Investment',
        citizenship_investment_text: 'We provide expert assistance for all citizenship by investment applications worldwide, providing a customized service experience supported and sustained by long-term partnerships.',
        listings: 'Listings',
        dominica: 'Dominica',
        dominica_title: 'Dominica Citizenship by Investment',
        dominica_description: 'Obtain Citizenship with an Investment of Just US$100,000, Enjoy Instant visa-free travel to 144 countries, Hold irrevocable citizenship for life.',
        find_out_more: 'Find out more',
        north_macedonia: 'North Macedonia',
        north_macedonia_title: 'North Macedonia Citizenship by Investment Program',
        north_macedonia_description: 'Obtain a North Macedonia Citizenship fast and easy starting with €200,000, Dual citizenship is allowed, Wealth Protection',
        grenada: 'Grenada',
        grenada_title: 'Grenada Citizenship by Investment Program',
        grenada_description: 'Obtain a Grenada Citizenship for the Whole Family Quickly and Securely in Europe, Enjoy tax exemptions on capital gains, gifts, wealth and inheritance',
        top_property_places: 'Top Property Places',
        top_property_description: 'Explore seamless and secure home transactions globally, with top properties available in Greece, Malaysia and Singapore',
        greece: 'Greece',
        malaysia: 'Malaysia',
        singapore: 'Singapore',
        properties: 'Properties',
        global_visa_services: 'Global Visa Services',
        global_visa_text: 'We provide expert assistance for all types of visa applications worldwide, ensuring a smooth and hassle-free process tailored to your specific needs.',
        greece_visa_text: 'Greece Golden Visa Program',
        greece_visa_description: 'Obtain Permanent Residency with an Investment of Just €250,000, Enjoy EU Benefits, Low Immigration Costs, and High Cost-Effectiveness',
        spain_visa_text: 'Spain Golden Visa Program',
        spain_visa_description: 'Obtain a Golden Visa for the Whole Family Quickly and Securely with Just €500,000, Free Choice of Property, Transparent Listings',
        portugal_visa_text: 'Portugal Golden Visa Program',
        portugal_visa_description: 'Obtain a Golden Visa for the Whole Family Quickly and Securely in Europe\'s Financial Center, with Transparent Property Choices',
        uk_visa_text: 'UK Innovator Founder Visa',
        uk_visa_description: 'Achieve immigrant status in the UK by establishing an innovative, feasible, and scalable business with minimum investment of £50,000.',
        italy_visa_text: 'Italy Golden Visa Program',
        italy_visa_description: 'Offers non-EU investors Italian residency through several investment options, enjoy multiple benefits such as the right to live, work, and study in the EU.',
        us_visa_text: 'U.S. E-2 Investor Residence Visa',
        us_visa_description: 'U.S. E-2 Investor Residence Visa is a non-immigrant visa, allows a foreign investor and his or her family to enter and work in the United States.',
        cyprus_visa_text: 'Cyprus Golden Visa Program',
        cyprus_visa_description: 'Obtain Permanent Residency for the Whole Family with Just €300,000, No Continuous Residency Requirement, Benefits for Elderly Parents and Children',
        portugal: 'Portugal',
        uk: 'United Kingdom',
        spain: 'Spain',
        italy: 'Italy',
        us: 'United States',
        cyprus: 'Cyprus',
    },
    property: {
        submit_enquiry: 'Submit Enquiry',
        reviews: 'Reviews',
        buy_new_property: 'Buy New Property',
        buy_second_hand_property: 'Buy Second-Hand Property',
        rent_property: 'Rent Property',
        title: 'Title',
        property: 'Property',
        about_property: 'About Property',
        property_information: 'Property Information',
        open_to_foreigner: 'Open to Foreigners',
        tenure: 'Tenure',
        units: 'Units',
        towers: 'Towers',
        completed_in: 'Completed in',
        furnished: 'Furnished',
        free_car_park: 'Free Car Park Available',
        tite: 'Title',
        airbnb_management: 'Airbnb Management',
        facilities: 'Facilities',
        unit_layout: 'Unit Layout',
        type: 'Type',
        beds: 'Beds',
        baths: 'Baths',
        area: 'Area',
        property_video: 'Property Video',
        location: 'Location',
        bikeable_score: 'Bikeable Score',
        some_bike_infrastructure: 'Some Bike Infrastructure',
        view_detail: 'View Detail',
        walk_scores: 'WalkScore',
        most_errands_accomplished_on_foot: 'Most errands can be accomplished on foot',
        some_transit: 'Some Transit',
        nearby_public_transport_options: 'A few nearby public transportation options',
        nearby: 'Nearby',
        schools: 'Schools',
        walkscore: 'WalkScore',
        hotel_restaurant: 'Hotel & Restaurant',
        health_medical: 'Health & Medical',
        want_to_know_more: 'Want to Know More',
        similar_property: 'Similar Property',
        sale: 'Sale',
        rent: 'Rent',
        sale_price: 'Sale Price',
        down_payment: 'Down Payment',
        loan_term: 'Loan Term',
        interest_rate: 'Interest Rate (%)',
        mortgage_calculator: 'Mortgage Calculator',
        monthly_payment: 'Monthly Payment',
        calculate: 'Calculate',
        for: 'For',
    },
    cta: {
        do_you_have_questions: 'Do You Have Questions ?',
        help_to_find_home: 'We will help you to find your next home.',
        contact_us_now: 'Contact Us Now',
        about_us: 'About Us',
        services: 'Our Services'
    },
    footer: {
        footer: 'Real Estate.',
        hotline: 'Hotline',
        greece: 'Greece',
        singapore: 'Singapore',
        malaysia: 'Malaysia',
        indonesia: 'Indonesia',
        japan: 'Japan',
        germany: 'Germany',
        need_help: 'Do yu need help with anything',
        offices: 'Offices',
        email_address: 'Email Address',
        subscribe: 'Subscribe',
        services: 'Services',
        buy_property: 'Buy Property',
        sell_property: 'Sell Property',
        property_for_rent: 'Property For Rent',
        global_partners: 'Global Partners',
        study_overseas: 'Study Overseas',
        global_visa: 'Global Visa',
        company: 'Company',
        about: 'About',
        countries: 'Countries',
        property_listings: 'Property Listing',
        blog_news: 'Blog or News',
        our_team: 'Our Team',
        contact_us: 'Contact Us',
        email: 'Email',
    },
    savedProperty:{
        propertyCategorySavedProperties: 'Saved Properties',
        propertyCategoryAllSavedProperties: 'Your All Saved Properties',
        propertyCategoryDashboard: 'Dashboard',
        propertyCategoryProfile: 'My Profile',
        propertyCategorySavedProperty: 'Saved Property',
        propertyCategoryMyProperties: 'My Properties',
        propertyCategorySubscription: 'My Subscription',
        propertyCategorySubscriptionDaysLeft: '10 days left',
        propertyCategorySubmitNewProperty: 'Submit New Property',
        propertyCategoryChangePassword: 'Change Password',
        propertyCategoryBookmarkProperty: 'Bookmark Property',
        propertyCategoryProperty: 'Property',
        propertyCategoryDelete: 'Delete',
    },
    userProperty:{
        propertyCountry: 'Country',
        propertyCategoryMyProperties: 'My Properties',
        propertyCategoryAllProperties: 'My All Properties',
        propertyCategoryDashboard: 'Dashboard',
        propertyCategoryProfile: 'My Profile',
        propertyCategorySavedProperty: 'Saved Property',
        propertyCategoryMySubscription: 'My Subscription',
        propertyCategorySubscriptionDaysLeft: '10 days left',
        propertyCategorySubmitNewProperty: 'Submit New Property',
        propertyCategoryChangePassword: 'Change Password',
        propertyCategoryListNewProperty: 'List New Property',
        propertyCategoryProperty: 'Property',
        propertyCategoryLeads: 'Leads',
        propertyCategoryStats: 'Stats',
        propertyCategoryPostedOn: 'Posted On',
        propertyCategoryStatus: 'Status',
        propertyCategoryAction: 'Action',
        propertyCategoryTotalViews: 'Total Views',
        propertyCategoryDaysAgo: 'Days ago',
        propertyCategoryActive: 'Active',
        propertyCategoryExpired: 'Expired',
        propertyCategoryEmailAddress: 'Email Address',
    },
    propertySubmit: {
        propertyCategory: 'Property Category',
        advertiseYourProperty: 'Advertise Your Property',
        submitYourProperty: 'Submit Your Property',
        propertyListingCountries: 'Property Listing Countries',
        propertyCountriesText: 'Our platform empowers property owners to sell their real estate globally, offering unparalleled exposure to international clients and investors, ensuring your property reaches a worldwide audience eager for investment opportunities.',
        malaysia: 'Malaysia',
        singapore: 'Singapore',
        greece: 'Greece',
        basicInformation: 'Basic Information',
        propertyTitle: 'Property Title',
        propertyType: 'Property Type',
        propertyCategoryText: 'If you do not have an account you can create one by ',
        clickHere: 'Click Here',
        greecePropertyBasicInformation: 'Greece Property Basic Information',
        propertyStatus: 'Status',
        forRent: 'For Rent',
        forSale: 'For Sale',
        condominium: 'Condominium',
        apartment: 'Apartment',
        landedHouse: 'Landed House',
        commercial: 'Commercial',
        offices: 'Offices',
        land: 'Land',
        new: 'New',
        secondHand: 'Second-hand',
        surfaceArea: 'Surface Area',
        plotSurfaceArea: 'Plot Surface Area',
        price: 'Price',
        pricePerM2: 'Price per m²',
        bedrooms: 'Bedrooms',
        bathrooms: 'Bathrooms',
        gallery: 'Gallery',
        uploadGallery: 'Upload Gallery',
        dragAndDropImage: 'Drag & drop image here or',
        choose: 'Choose',
        dropImagesHere: 'Drop images here',
        propertyVideo: 'Property Video',
        youtubeEmbedLink: 'Youtube Embed Link',
        locationInGreece: 'Location in Greece',
        address: 'Address',
        city: 'City',
        state: 'State',
        zipCode: 'Zip Code',
        greecePropertyDetailedInformation: 'Greece Property Detailed Information',
        description: 'Description',
        buildingAgeOptional: 'Building Age (optional)',
        years: 'Years',
        garage: 'Garage (optional)',
        rooms: 'Rooms',
        heatingSystemOptional: 'Heating System (optional)',
        centralPetrol: 'Central (Petrol)',
        electricHeating: 'Electric Heating',
        heatPumps: 'Heat Pumps',
        notAvailable: 'Not Available',
        energyClassOptional: 'Energy class (optional)',
        completionYearOptional: 'Completion Year (optional)',
        internalFeaturesOptional: 'Internal Features (optional)',
        fullFurnished: 'Full-furnished',
        partialFurnished: 'Partial-furnished',
        noFurnished: 'No furnished',
        elevator: 'Elevator',
        internalStaircase: 'Internal Staircase',
        nightPower: 'Night Power',
        secureDoor: 'Secure Door',
        doubleGlass: 'Double Glass',
        painted: 'Painted',
        wifi: 'Wi-Fi',
        fireplace: 'Fireplace',
        woodFloorType: 'Wood Floor Type',
        alarm: 'Alarm',
        windowScreens: 'Window Screens',
        airConditioning: 'Air Conditioning',
        petsWelcome: 'Pets Welcome',
        aluminiumFrames: 'Aluminium Frames',
        mannedReception: 'Manned Reception',
        storageSpace: 'Storage Space',
        marbleFloorType: 'Marble Floor Type',
        airy: 'Airy',
        underfloorHeating: 'Underfloor Heating',
        solarWaterHeating: 'Solar Water Heating',
        attic: 'Attic',
        playroom: 'Playroom',
        satelliteReceiver: 'Satellite Receiver',
        chargingFacilitiesForElectricCar: 'Charging Facilities for Electric Car',
        balcony: 'Balcony',
        woodenFrames: 'Wooden Frames',
        waterCloset: 'Water Closet',
        externalFeaturesOptional: 'External Features (optional)',
        privateGarden: 'Private Garden',
        awning: 'Awning',
        sharedGarden: 'Shared Garden',
        facade: 'Facade',
        roadTypeAsphalt: 'Road type asphalt',
        residentialZone: 'Residential Zone',
        accessForPeopleWithDisabilities: 'Access for people with disabilities',
        swimmingPool: 'Swimming pool',
        builtInBBQ: 'Built in BBQ',
        parkingSpace: 'Parking space',
        garden: 'Garden',
        balconyDuplicate: 'Balcony',
        suitableForOptional: 'Suitable For (optional)',
        student: 'Student',
        holidayHome: 'Holiday Home',
        investment: 'Investment',
        shortTermRental: 'Short-term Rental',
        commercialUse: 'Commercial use',
        medicalOffice: 'Medical Office',
        contactInformation: 'Contact Information',
        name: 'Name',
        email: 'Email',
        phone: 'Phone',
        submit: 'Submit',
        malaysiaPropertyBasicInformation: 'Malaysia Property Basic Information',
        lotType: 'Lot Type',
        internationalLot: 'International Lot',
        bumiReleased: 'Bumi-released',
        bumiLot: 'Bumi Lot',
        pricePerFt2: 'Price per ft²',
        locationInMalaysia: 'Location in Malaysia',
        malaysiaPropertyDetailedInformation: 'Malaysia Property Detailed Information',
        conditionOptional: 'Condition (optional)',
        likeNew: 'Like New',
        renovated: 'Renovated',
        needRenovation: 'Need Renovation',
        landTenure: 'Land Tenure',
        freehold: 'Freehold',
        leasehold: 'Leasehold',
        ninetyNineYearsLeasehold: '99-years Leasehold',
        nineHundredNinetyNineYearsLeasehold: '999-years Leasehold',
        landTitle: 'Land Title',
        commercialStrata: 'Commercial Strata',
        residentialStrata: 'Residential Strata',
        individualTitle: 'Individual Title',
        masterTitle: 'Master Title',
        landSizeOptional: 'Land Size (optional)',
        totalUnitsOptional: 'Total Units (optional)',
        totalCarParkOptional: 'Total Car Park (optional)',
        totalTowersOptional: 'Total Towers (optional)',
        totalFloorsOptional: 'Total Floors (optional)',
        startingUnitPriceOptional: 'Starting Unit Price (optional)',
        highestUnitPriceOptional: 'Highest Unit Price (optional)',
        carParkOptional: 'Car Park (optional)',
        furnishingOptional: 'Furnishing (optional)',
        fullFurnishedDuplicate: 'Full Furnished',
        partialFurnishedDuplicate: 'Partial Furnished',
        notFurnished: 'Not Furnished',
        airConditioningDuplicate: 'Air Conditioning',
        fullyFittedKitchen: 'Fully Fitted Kitchen',
        waterHeaters: 'Water Heaters',
        builtInWardrobes: 'Built-in Wardrobes',
        balconyDuplicate2: 'Balcony',
        enSuiteBathrooms: 'En-suite Bathrooms',
        highQualityFlooring: 'High-Quality Flooring',
        openPlanLayout: 'Open Plan Layout',
        highCeilings: 'High Ceilings',
        smartHomeFeatures: 'Smart Home Features',
        utilityStorageRoom: 'Utility/Storage Room',
        privateLiftLobby: 'Private Lift Lobby',
        homeOfficeSpace: 'Home Office Space',
        dualKeyAccess: 'Dual Key Access',
        pantryArea: 'Pantry Area',
        laundryArea: 'Laundry Area',
        highQualityFinishes: 'High-Quality Finishes',
        largeWindowsNaturalLight: 'Large Windows/Natural Light',
        soundproofing: 'Soundproofing',
        walkInCloset: 'Walk-In Closet',
        guestPowderRoom: 'Guest Powder Room',
        smartWiring: 'Smart Wiring',
        privateGardenDuplicate: 'Private Garden',
        emergencyEscapePlan: 'Emergency Escape Plan',
        facilitiesOptional: 'Facilities (optional)',
        swimmingPoolDuplicate: 'Swimming Pool',
        gymnasium: 'Gymnasium',
        twentyFourHourSecurity: '24-Hour Security',
        parkingSpaces: 'Parking Spaces',
        childrensPlayground: 'Children’s Playground',
        multipurposeHall: 'Multipurpose Hall',
        bbqArea: 'BBQ Area',
        joggingTrack: 'Jogging Track',
        wadingPool: 'Wading Pool',
        saunaSteamRoom: 'Sauna/Steam Room',
        readingStudyRoom: 'Reading/Study Room',
        clubhouse: 'Clubhouse',
        managementOffice: 'Management Office',
        gardenOrGreenSpaces: 'Garden or Green Spaces',
        functionRoom: 'Function Room',
        tennisCourt: 'Tennis Court',
        badmintonCourt: 'Badminton Court',
        surau: 'Surau',
        coveredCarPark: 'Covered Car Park',
        liftLobby: 'Lift Lobby',
        airbnbManagement: 'Airbnb Management',
        singaporePropertyBasicInformation: 'Singapore Property Basic Information',
        nearestMRT: 'Nearest MRT',
        locationInSingapore: 'Location in Singapore',
        district: 'District',
        neighbourhood: 'Neighbourhood',
        singaporePropertyDetailedInformation: 'Singapore Property Detailed Information',
        typeOfProperty: 'Type of Property',
        publicHousingHDBFlats: 'Public Housing (HDB Flats)',
        privateResidentialProperties: 'Private Residential Properties',
        executiveCondominiums: 'Executive Condominiums',
        commercialProperties: 'Commercial Properties',
        industrialProperties: 'Industrial Properties',
        mixedUseDevelopments: 'Mixed-Use Developments',
        servicedApartments: 'Serviced Apartments',
        hotels: 'Hotels',
    },
    modals: {
        scan_qr_to_connect: 'Scan QR to Connect',
        dream_home_anywhere: 'Your Dream Home, Anywhere In The World',
        login: 'Login',
        login_with_google: 'Login With Google',
        register: 'Register',
        username: 'Username',
        password: 'Password',
        remember_me: 'Remember Me',
        lost_password: 'Forgot Password?',
        forgot_password: 'Forgot Password',
        send_reset_email: 'Send Reset Email',
        enter_email_address: 'Enter your email address',
        full_name: 'Full Name',
        email: 'Email',
        phone_number: 'Phone Number',
        terms_conditions: 'By using the website, you acept the terms and conditions.',
        enter_phone_number: 'Enter phone number',
        enter_email: 'Enter email',
        enter_full_name: 'Enter full name',
        enter_password: 'Enter password',
        enter_username: 'Enter username',
    },
    form: {
        name: 'Name',
        phone_number: 'Phone Number',
        messages: 'Messages',
        get_latest_offer: 'Get Latest Offer',
    },
    settings: {
        language: 'Language',
        currency: 'Currency',
        measurement: 'Measurement',
        confirm: 'Confirm',
    },
    services: {
        title: 'Globalahome Services',
        greece_property_management: 'Greece Property Management',
        existing_clients: 'More than 100 existing clients',
        malaysia_property_investment: 'Malaysia Property Investment',
        more_listings: 'More than 100 listings',
        singapore_property_investment: 'Singapore Property Investment',
        global_visa_services: 'Global Visa Services',
        successful_visa_cases: 'Handled more than 100 successful visa application cases',
        global_real_estate_investment: 'Global Real Estate Investment',
        real_estate_description: 'We carefully select high-quality real estate projects in popular and potential regions around the world, including but not limited to luxury homes, commercial properties, school district houses, and vacation villas in North America, Europe, Asia, and Oceania. We provide clients with detailed market analysis, project evaluation, and one-stop purchasing services to ensure safe and efficient investments.',
        immigration_planning_services: 'Immigration Planning Services',
        immigration_description: 'With a professional team of immigration consultants, we conduct in-depth research on immigration policies of various countries, covering multiple pathways including investment immigration, skilled immigration, and family reunification. We customize immigration plans tailored to each client, guiding them through the application process to help them successfully obtain overseas status and enjoy broader living space and development opportunities.',
        citizenship_by_investment: 'Citizenship by Investment Services',
        high_net_worth_investor: 'High Net Worth Investor',
        dual_citizenship: 'Dual-citizenship',
        asset_allocation: 'Asset Allocation and Wealth Management',
        asset_allocation_description: 'Based on the clients\' financial situation, risk preference, and future planning, we offer cross-regional, cross-currency, and cross-market asset allocation advice, as well as value-added services such as tax planning and estate planning. Our goal is to help clients achieve steady wealth growth and preservation.',
        contact_us_now: 'Contact Us Now',
        connect_through_apps: 'Connect through Apps',
        app_description: 'Explore our app on a variety of platforms to enjoy a seamless experience wherever you are. Whether you are using our official website, browsing through the Google Play Store, downloading the iOS app, or accessing our WeChat Mini Program, we have got you covered. Stay connected with us and enjoy the convenience of accessing our services on your preferred device.',
        official_website: 'Official Website',
        google_play_store: 'Google Play Store',
        ios_app: 'iOS app',
        wechat_mini_program: 'WeChat Mini Program',
        connect_through_social_media: 'Connect through Social Media',
        social_media_description: 'Follow us on social media to stay updated with the latest real estate investment opportunities across the globe. Whether you are browsing through Facebook, engaging with us on TikTok, or exploring our insights on XiaoHongShu, our platforms keep you informed and connected. Join our community and discover the convenience of accessing the best investment options and property trends wherever you are.',
        facebook: 'Facebook',
        tiktok: 'TikTok',
        xiaohongshu: 'XiaoHongShu',
        explore_by_countries: 'Explore By Countries',
        countries_description: 'Discover diverse real estate opportunities across countries, from bustling urban centers to serene rural landscapes. Whether you are looking to invest or find your next home, explore properties in your desired destination with ease.',
        athens_greece: 'Athens, Greece',
        johor_bahru_malaysia: 'Johor Bahru, Malaysia',
        singapore: 'Singapore',
        how_it_works: 'How It Works?',
        how_it_works_description: 'Follow these simple steps to find and secure your ideal property with ease.',
        create_an_account: 'Create An Account',
        account_description: 'Sign up easily to gain access to personalized property listings and updates.',
        find_and_search_property: 'Find & Search Property',
        search_property_description: 'Explore and filter through a wide range of properties to find the perfect match for your needs.',
        book_your_property: 'Book Your Property',
        book_property_description: 'Secure your chosen property by booking online in just a few clicks.',
        searching_perfect_place: 'Are You Searching for the Perfect Place for Your Dream House?',
        searching_description: 'Finding the perfect place for your dream home is a journey we’re here to make easier for you. Explore a wide range of properties tailored to suit your unique needs, whether you are searching for a peaceful retreat, a vibrant city apartment, or a luxurious villa. Our platform is designed to connect you with the best real estate opportunities, providing detailed listings, expert advice, and personalized recommendations to help you make the right choice with confidence. Let us guide you to your ideal home, where comfort and convenience await.',
        find_properties: 'Find Properties',
        our_services: 'Our Services'
    },
    aboutUs: {
        aboutUs: 'About Us',
        whoWeAre: 'About Us - Who We Are?',
        companyOverview: 'Company Overview',
        visionAndMission: 'Our Vision and Mission',
        companyDescription: 'GlobalAHome is a Global Real Estate and Immigration Investment Company, providing a comprehensive international service organization that integrates global real estate investment, immigration planning, and asset management. Since its establishment, the company has adhered to the core values of "Professionalism, Integrity, Innovation, and Win-win," dedicated to providing one-stop, personalized overseas living and investment solutions for high-net-worth clients worldwide.',
        globalPerspective: 'We have a deep understanding of global real estate market trends, accurately grasp the immigration policies of various countries, and with a global perspective and localized services, we help clients achieve asset preservation and appreciation, family planning upgrades, and a leap in quality of life.',
        moreAboutServices: 'More About Our Services',
        specialAboutUs: 'Special About Us',
        scalableApplication: 'Our scalable application is here to fulfill every need related to home services through collaboration & partnership',
        propertyListings: 'Property Listings',
        homeServices: 'Home Services',
        userDashboard: 'User Dashboard',
        customizedServices: 'Customised Services',
        meetOurTeam: 'Meet Our Team',
        professionalAndDedicatedTeam: 'Professional & Dedicated Team',
        ceo: 'Co-Founder & CEO',
        cto: 'Co-Founder & CTO',
        greecePropertyDirector: 'Greece Property Management Director',
        graphicDesigner: 'Graphic Designer',
        professionalAdvantages: 'Professional Advantages',
        globalNetwork: 'Global Network',
        globalNetworkDescription: 'We have an extensive network of partners around the world, enabling us to quickly obtain first-hand market information and provide clients with the most cutting-edge investment and immigration insights.',
        professionalTeam: 'Professional Team',
        professionalTeamDescription: 'Our team comprises seasoned experts from various fields, including law, finance, taxation, and real estate. With their professional knowledge and extensive experience, we offer clients comprehensive and high-quality services.',
        customizedService: 'Customized Service',
        customizedServiceDescription: 'Adhering to a "client-centered" service philosophy, we deeply understand our clients needs and provide personalized one-on-one service plans, ensuring that every client receives the solution that best suits their individual needs.',
        oneStopService: 'One-Stop Service',
        oneStopServiceDescription: 'From project selection, due diligence, and contract signing to post-management, immigration applications, and tax planning, we provide full-process tracking services, allowing clients to enjoy peace of mind and convenience.',
        lookingToTheFuture: 'Looking to the Future',
        lookingToTheFutureDescription: 'In the face of increasingly globalized economic trends and ever-changing client needs, Global Real Estate and Immigration Investment Company will continue to deepen international cooperation, expand service areas, and enhance service quality. We strive to become the trusted partner for high-net-worth clients worldwide in their overseas living and investment endeavors. We firmly believe that through relentless effort and innovation, we can help clients embark on a more vibrant and exciting chapter of overseas living, and together, create a better future.',
        aboutGlobalahome: 'About Globalahome.com',
        dreamHouseSearch: 'Are You Searching for the Perfect Place for Your Dream House?',
        dreamHouseDescription: 'Finding the perfect place for your dream home is a journey we’re here to make easier for you. Explore a wide range of properties tailored to suit your unique needs, whether you are searching for a peaceful retreat, a vibrant city apartment, or a luxurious villa. Our platform is designed to connect you with the best real estate opportunities, providing detailed listings, expert advice, and personalized recommendations to help you make the right choice with confidence. Let us guide you to your ideal home, where comfort and convenience await.',
        findProperties: 'Find Properties'
    },
    contactUs:{
        getInTouch: 'Get In Touch',
        getHelpSupport: 'Get Help & Friendly Support',
        supportMessage: 'Looking for help or any support? We are available 24 hours a day.',
        contactSupport: 'Contact Support',
        contactSales: 'Contact Sales',
        startLiveChat: 'Start Live Chat',
        fillForm: 'Fill Up The Form',
        startChat: 'Start Live Chat',
        name: 'Name',
        email: 'Email',
        phoneNumber: 'Phone Number',
        subject: 'Subject',
        message: 'Message',
        submitRequest: 'Submit Request',        
    },
    subscription:{
        chooseSubscription: 'Choose Subscription',
        selectSubscription: 'Select Your Subscription',
        dashboard: 'Dashboard',
        myProfile: 'My Profile',
        myProperties: 'My Properties',
        savedProperty: 'Saved Property',
        submitRequest: 'Submit Request',
        submitNewProperty: 'Submit New Property',
        changePassword: 'Change Password',
        mySubscription: 'My Subscription',
        daysLeft: '10 days left',
        goldPackage: 'Gold Package',
        yearlySubscription: 'year',
        unlimitedListings: 'Unlimited listings',
        featured: 'Featured',
        unlimitedImages: 'Unlimited Images',
        helpSupport: 'Help & Support',
        switchPackage: 'Switch to this package',
        premiumPackage: 'Premium Package',
        listings: 'listings',
        imagesPerList: 'Images/ per list',
        paymentMethod: 'Payment Method',
        standardPackage: 'Standard Package',
        payWithPaypal: 'Pay with Paypal',
        payWithWechatPay: 'Pay with WechatPay',
        wireTransfer: 'Wire Transfer',
    },    
};
