<template>
    <div :key="$i18n.locale" class="yellow-skin">

        <!-- This is the preloader -->
        <!-- <div class="preloader"></div> -->
        <div id="main-wrapper">
            <!-- Start Navigation -->
            <Navigation />
            <!-- ============================ End Navigation ================================== -->

            <div class="clearfix"></div>
            <!-- ============================ Page Title Start================================== -->
            <div class="page-title" :style="{ background: `#f4f4f4 url(${bgImage})` }" data-overlay="5">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12 col-md-12">

                            <div class="breadcrumbs-wrap position-relative z-1">
								<ol class="breadcrumb">
									<li class="breadcrumb-item active" aria-current="page">{{ $t('subscription.chooseSubscription') }}</li>
								</ol>
								<h2 class="breadcrumb-title">{{ $t('subscription.selectSubscription') }}</h2>
							</div>

                        </div>
                    </div>
                </div>
            </div>
            <!-- ============================ Page Title End ================================== -->

           <!-- ============================ User Dashboard ================================== -->
			<section class="gray pt-5 pb-5">
				<div class="container-fluid">
								
					<div class="row">
						
						<div class="col-lg-3 col-md-4">
							<div class="property_dashboard_navbar">
								
                                <div class="dash_user_avater">
                                    <img :src="profileLogo" class="img-fluid avater" alt="">
                                    <h4>Globalahome User</h4>
                                    <span>Johor Bahru Malaysia</span>
                                </div>
								
								<div class="dash_user_menues">
									<ul>
										<li><router-link to="/user-dashboard"><i
                                                    class="fa fa-tachometer-alt"></i>{{ $t('subscription.dashboard') }}<span
                                                    class="notti_coun style-1">4</span></router-link></li>
                                        <li><router-link to="/user-profile"><i class="fa fa-user-tie"></i>{{ $t('subscription.myProfile') }}</router-link></li>
                                        <li><router-link to="/user-saved-property"><i class="fa fa-bookmark"></i>{{ $t('subscription.savedProperty') }}<span class="notti_coun style-2">7</span></router-link></li>
                                        <li><router-link to="/user-property"><i
                                            class="fa fa-tasks"></i>{{ $t('subscription.savedProperty') }}</router-link></li>
										<li class="active"><router-link to="/user-subscription"><i class="fa fa-gift"></i>{{ $t('subscription.mySubscription') }}<span class="expiration">{{ $t('subscription.daysLeft') }}</span></router-link></li>
										<li><router-link to="/user-change-password"><i
                                            class="fa fa-unlock-alt"></i>{{ $t('subscription.changePassword') }}</router-link></li>
									</ul>
								</div>
							</div>
						</div>
						
						<div class="col-lg-9 col-md-8">
							<div class="dashboard-body">
								
								<div class="row">
									<div class="col-lg-12 col-md-12 col-sm-12">
										<div id="extraPackages">
											<div class="row">
												
												<!-- Single Package -->
												<div class="col-lg-4 col-md-4 c-l-sm-12">
													<div class="package-box">
														<span class="theme-cl">{{ $t('subscription.goldPackage') }}</span>
														<h4 class="packages-features-title">USD 49 / 1 {{ $t('subscription.yearlySubscription') }}</h4>
														<ul class="packages-lists-list">
															<li>{{ $t('subscription.unlimitedListings') }}</li>
															<li>100 {{ $t('subscription.featured') }}</li>
															<li>{{ $t('subscription.unlimitedImages') }}</li>
															<li>{{ $t('subscription.helpSupport') }}</li>
														</ul>
														<div class="buypackage">
															<div class="switchbtn paying">
																<input id="gold" class="form-check-input" type="radio" value="opt2" checked name="package7">
																<label class="form-check-label" for="gold"></label>
															</div>
															<span>{{ $t('subscription.switchPackage') }}</span>
														</div>
													</div>
												</div>
												
												<!-- Single Package -->
												<div class="col-lg-4 col-md-4 c-l-sm-12">
													<div class="package-box">
														<span class="theme-cl">{{ $t('subscription.premiumPackage') }}</span>
														<h4 class="packages-features-title">USD 39 / 1 {{ $t('subscription.yearlySubscription') }}</h4>
														<ul class="packages-lists-list">
															<li>20 {{ $t('subscription.listings') }}</li>
															<li>5 {{ $t('subscription.featured') }}</li>
															<li>5 {{ $t('subscription.imagesPerList') }}</li>
															<li>{{ $t('subscription.helpSupport') }}</li>
														</ul>
														<div class="buypackage">
															<div class="switchbtn paying">
																<input id="premium" class="form-check-input" type="radio" value="opt1" name="package7">
																<label class="form-check-label" for="premium"></label>
															</div>
															<span>{{ $t('subscription.switchPackage') }}</span>
														</div>
													</div>
												</div>
												
												<!-- Single Package -->
												<div class="col-lg-4 col-md-4 c-l-sm-12">
													<div class="package-box">
														<span class="theme-cl">{{ $t('subscription.standardPackage') }}</span>
														<h4 class="packages-features-title">USD 10 / 1 {{ $t('subscription.yearlySubscription') }}</h4>
														<ul class="packages-lists-list">
															<li>10 {{ $t('subscription.listings') }}</li>
															<li>2 {{ $t('subscription.featured') }}</li>
															<li>2 {{ $t('subscription.imagesPerList') }}</li>
															<li>{{ $t('subscription.helpSupport') }}</li>
														</ul>
														<div class="buypackage">
															<div class="switchbtn paying">
																<input id="standard" class="form-check-input" type="radio" value="opt3" name="package7">
																<label class="form-check-label" for="standard"></label>
															</div>
															<span>{{ $t('subscription.switchPackage') }}</span>
														</div>
													</div>
												</div>
											
											</div>
											
											<div class="row mt-5">
												<div class="col-lg-12 col-md-12">
													<h4>{{ $t('subscription.paymentMethod') }}</h4>
												</div>
												<div class="col-lg-12 col-md-12">
													<a href="#" class="pay-btn paypal">{{ $t('subscription.payWithPaypal') }}</a>
													<a href="#" class="pay-btn stripe">{{ $t('subscription.payWithWechatPay') }}</a>
													<a href="#" class="pay-btn wire-trans">{{ $t('subscription.wireTransfer') }}</a>
												</div>
											</div>
											
										</div>
										
									</div>
								</div>
								<!-- Row -->
								
						
							</div>
						
						</div>
					</div>
				</div>
			</section>
			<!-- ============================ User Dashboard End ================================== -->

            <!-- ============================ Call To Action ================================== -->
            <PageFooter />
            <!-- ============================ Call To Action End ================================== -->
            <!-- ============================ Footer Start ================================== -->
            <!-- ============================ Footer End ================================== -->
        </div>
    </div>
</template>

<script>
import 'magnific-popup';
import Navigation from '@/components/Navigation.vue';
import PageFooter from '@/components/PageFooter.vue';

import globalHomeLogo from '@/assets/img/global_home.png';
import whatsappLogo from '@/assets/img/whatsapp.png';
import wechatLogo from '@/assets/img/wechat.png';
import loginLogo from '@/assets/img/lock.png';
import settingsLogo from '@/assets/img/settings.png';
import qrCodeLogo from '@/assets/img/baizupo_qr.jpeg';
import sampleImage1 from '@/assets/img/p-4.webp';
import oldIcon from '@/assets/img/old.png';
import greeceFlag from '@/assets/img/greece_flag.webp';
import spanyolFlag from '@/assets/img/spanyol_flag.webp';
import portugalFlag from '@/assets/img/portugal_flag.webp';
import ukFlag from '@/assets/img/united-kingdom.webp';
import italyFlag from '@/assets/img/italy.webp';
import usFlag from '@/assets/img/united-states.webp';
import cyprusFlag from '@/assets/img/cyprus_flag.webp';
import profileLogo from '@/assets/img/profile.png';
import menuWidget from '@/assets/img/grid-lines.png';
import addProperty from '@/assets/img/add.png';
import bgImage from '@/assets/img/bg.jpg';


export default {
    name: 'UserDashboard',
    components: {
        Navigation,
        PageFooter,
    },
    props: ['glb_id'],
    data() {
        return {
            bgImage,
            addProperty,
            menuWidget,
            profileLogo,
            globalHomeLogo,
            whatsappLogo,
            wechatLogo,
            loginLogo,
            settingsLogo,
            qrCodeLogo,
            sampleImage1,
            greeceFlag,
            spanyolFlag,
            portugalFlag,
            ukFlag,
            italyFlag,
            usFlag,
            cyprusFlag,
            oldIcon,
            activeSubmenu: null,
            navMenuVisible: false,
            userLoggedIn: false,
            loginError: '',
        };
    },
    methods: {
        toggleNavMenu() {
            this.navMenuVisible = !this.navMenuVisible;
        },
        toggleSubmenu(menu) {
            this.activeSubmenu = this.activeSubmenu === menu ? null : menu;
        },
    },
};
</script>

<style scoped>
.nav-container {
    margin-bottom: 0px;
    display: flex;
    justify-content: flex-end;
    /* Aligns the button to the right */
}

.nav-dropdown-toggle {
    background-color: white;
    /* Set background color to white */
    color: #000000;
    /* Set icon color */
    /* padding: 5px; */
    /* Adjust padding to ensure icon is centered */
    border: none;
    cursor: pointer;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    /* Center the icon */
    width: 50px;
    /* Set a fixed width for the button */
    height: 50px;
    /* Set a fixed height for the button */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    /* Optional: add a subtle shadow */
}

.nav-dropdown-toggle i {
    margin-left: 0;
    /* Remove any left margin */
}

/* Optional: add transition to make it smooth */
.nav-menu {
    transition: max-height 0.3s ease-out;
}

.input-with-icon select.form-control {
    padding-bottom: 1px;
    /* Adjust the value as needed */
}

.hello {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    text-align: center;
    color: #2c3e50;
    margin-top: 60px;
}

.add-listing {
    margin-right: 10px;
}

.add-listing a {
    border-radius: 8px;
    text-align: center;
    white-space: nowrap;
}

.d-flex {
    flex-wrap: nowrap;
}

.bg-danger {
    background-color: #d9534f;
    color: white;
}

a img {
    vertical-align: middle;
}

.header.header-light {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1000;
    background-color: white;
}

#main-wrapper {
    padding-top: 70px;
}

.header.header-light {
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.submenu-indicator {
    display: inline-block;
    margin-left: 5px;
    transition: transform 0.3s;
}

.nav-menu li ul {
    display: none;
}

.nav-menu li ul.nav-submenu {
    display: block;
}

.nav-menu li.active>.submenu-indicator {
    transform: rotate(90deg);
}

.resp_log_thumb {
    margin-top: 100px;
    margin-left: 40px;
    width: 250px;
    height: 110px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.form-check-label {
    margin-left: 10px;
}

.tagline {
    margin-top: 150px;
    margin-left: 50px;
}
</style>
