<template>
    <!-- Start Navigation -->
    <div class="header header-light">
        <div class="container">
            <nav id="navigation" class="navigation navigation-landscape">
                <div class="nav-header">
                    <a class="nav-brand" href="/">
                        <img :src="globalHomeLogo" class="logo" alt="Global Home" />
                    </a>
                    <div class="nav-toggle"></div>

                    <!-- Mobile Navigation -->
                    <div class="mobile_nav d-lg-none">
                        <ul>
                            <li class="add-listing" style="margin-left:50px">
                                <div class="nav-container"><button @click="toggleNavMenu" class="nav-dropdown-toggle">
                                        <img :src="menuWidget" class="logo" alt="Global A Home Login"
                                            style="width: 30px; height: 30px;" /></button></div>
                            </li>
                            <li class="nav-item dropdown">
                                <a href="#" role="button" data-bs-toggle="dropdown">
                                    <img :src="settingsLogo" class="logo" alt="Global Home Settings"
                                        style="width: 30px; height: 30px; margin-right: 8px; margin-top: 20px">
                                </a>
                                <div class="dropdown-menu p-3" aria-labelledby="settingsDropdown">
                                    <div class="mb-2">
                                        <label for="languageSelect" class="form-label">{{
                                            $t('settings.language') }}</label>
                                        <select v-model="selectedLanguage" class="form-select" id="languageSelect">
                                            <option value="en">English</option>
                                            <option value="zh">中文 (简体)</option>
                                        </select>
                                    </div>
                                    <div class="mb-2">
                                        <label for="currencySelect" class="form-label">{{
                                            $t('settings.currency') }}</label>
                                        <select v-model="selectedCurrency" class="form-select" id="currencySelect">
                                            <option value="usd">USD</option>
                                            <option value="eur">EUR</option>
                                            <option value="sgd">SGD</option>
                                            <option value="myr">MYR</option>
                                        </select>
                                    </div>
                                    <div class="mb-2">
                                        <label for="measurementSelect" class="form-label">{{
                                            $t('settings.measurement') }}</label>
                                        <select v-model="selectedMeasurement" class="form-select"
                                            id="measurementSelect">
                                            <option value="m2">m²</option>
                                            <option value="ft2">ft²</option>
                                        </select>
                                    </div>
                                    <a href="#" @click.prevent="saveUserPreferences" class="btn btn-dark w-100">
                                        {{ $t('settings.confirm') }}
                                    </a>
                                </div>
                            </li>

                            <li v-if="!userLoggedIn" class="add-listing">
                                <a href="submit-property" class="d-flex align-items-center" data-bs-toggle="modal"
                                    data-bs-target="#login">
                                    <img :src="loginLogo" class="logo" alt="Global A Home Login"
                                        style="width: 30px; height: 30px; margin-right: 8px;" />
                                    {{ $t('header.login') }}
                                </a>
                            </li>

                            <li v-else @click="toggleSubmenu('user')" class="nav-item dropdown">
                                <a href="#" role="button" class="d-flex align-items-center">
                                    <img :src="profileLogo" class="logo" alt="Global A Home Profile"
                                        style="width: 30px; height: 30px; margin-right: 8px;" />
                                    Welcome User
                                    <span class="submenu-indicator"></span>
                                </a>
                                <ul v-if="activeSubmenu === 'user'" class="dropdown-menu">
                                    <li><router-link to="/user-profile" class="dropdown-item">{{ $t('header.my_profile')
                                            }}</router-link></li>
                                    <li><router-link to="/user-dashboard" class="dropdown-item">{{
                                        $t('header.my_dashboard')
                                            }}</router-link></li>
                                    <li><router-link to="/user-saved-property" class="dropdown-item">{{
                                        $t('header.saved_property')
                                            }}</router-link></li>
                                    <li><router-link to="/user-change-password" class="dropdown-item">{{
                                        $t('header.change_my_password')
                                            }}</router-link></li>
                                    <li><a @click.prevent="handleLogout" href="#" class="dropdown-item">{{
                                        $t('header.logout') }}</a></li>
                                </ul>
                            </li>
                            <ul class="nav-menu" v-if="navMenuVisible">
                                <li @click="toggleSubmenu('country')">
                                    <a href="#">{{ $t('header.country') }}<span class="submenu-indicator"></span></a>
                                    <ul v-if="activeSubmenu === 'country'" class="nav-dropdown nav-submenu">
                                        <li><router-link to="/greece-listing">{{
                                            $t('header.greece') }}</router-link></li>
                                        <li><router-link to="/malaysia-listing">{{
                                            $t('header.malaysia') }}</router-link></li>
                                        <li><router-link to="/singapore-listing">{{
                                            $t('header.singapore') }}</router-link></li>
                                    </ul>
                                </li>
                                <li @click="toggleSubmenu('residential')">
                                    <a href="#">{{ $t('header.residential') }}<span
                                            class="submenu-indicator"></span></a>
                                    <ul v-if="activeSubmenu === 'residential'" class="nav-dropdown nav-submenu">
                                        <li><router-link to="/greece-listing">{{
                                            $t('header.greece') }}</router-link></li>
                                        <li><router-link to="/malaysia-listing">{{
                                            $t('header.malaysia') }}</router-link></li>
                                        <li><router-link to="/singapore-listing">{{
                                            $t('header.singapore') }}</router-link></li>
                                    </ul>
                                </li>
                                <li>
                                    <router-link to="/global-visa">{{ $t('header.global_visa') }}</router-link>
                                </li>
                                <li @click="toggleSubmenu('commercial')">
                                    <a href="#">{{ $t('header.commercial') }}<span class="submenu-indicator"></span></a>
                                    <ul v-if="activeSubmenu === 'commercial'" class="nav-dropdown nav-submenu">
                                        <li><router-link to="/greece-listing">{{
                                            $t('header.greece') }}</router-link></li>
                                        <li><router-link to="/malaysia-listing">{{
                                            $t('header.malaysia') }}</router-link></li>
                                        <li><router-link to="/singapore-listing">{{
                                            $t('header.singapore') }}</router-link></li>
                                    </ul>
                                </li>
                                <li @click="toggleSubmenu('land')">
                                    <a href="#">{{ $t('header.land') }}<span class="submenu-indicator"></span></a>
                                    <ul v-if="activeSubmenu === 'land'" class="nav-dropdown nav-submenu">
                                        <li><router-link to="/greece-listing">{{
                                            $t('header.greece') }}</router-link></li>
                                        <li><router-link to="/malaysia-listing">{{
                                            $t('header.malaysia') }}</router-link></li>
                                        <li><router-link to="/singapore-listing">{{
                                            $t('header.singapore') }}</router-link></li>
                                    </ul>
                                </li>
                                <li @click="toggleSubmenu('contact_us')">
                                    <a href="#">{{ $t('home.contact_us') }}<span class="submenu-indicator"></span></a>
                                    <ul v-if="activeSubmenu === 'contact_us'" class="nav-dropdown nav-submenu">
                                        <li class="add-listing"><router-link to="/contact-us">{{
                                            $t('property.submit_enquiry') }}</router-link></li>
                                        <li class="add-listing">
                                            <a href="#scan-wechat" class="d-flex align-items-center"
                                                data-bs-toggle="modal" data-bs-target="#scan-wechat">
                                                <img :src="wechatLogo" class="logo" alt="Global Home Wechat"
                                                    style="width: 20px; height: 20px; margin-right: 8px;">
                                                {{ $t('header.wechat') }}
                                            </a>
                                        </li>
                                        <li class="add-listing">
                                            <a href="https://wa.link/fw20eo" class="d-flex align-items-center">
                                                <img :src="whatsappLogo" class="logo" alt="Global Home Whatsapp"
                                                    style="width: 20px; height: 20px; margin-right: 8px;">
                                                {{ $t('header.whatsapp') }}
                                            </a>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </ul>
                    </div>

                    <!-- Desktop Navigation -->
                    <div class="nav-menus-wrapper d-none d-lg-flex" style="transition-property: none;">
                        <ul class="nav-menu">
                            <li @click="toggleSubmenu('country')">
                                <a href="#">{{ $t('header.country') }}<span class="submenu-indicator"></span></a>
                                <ul v-if="activeSubmenu === 'country'" class="nav-dropdown nav-submenu">
                                    <li><router-link to="/greece-listing">{{
                                        $t('header.greece') }}</router-link></li>
                                    <li><router-link to="/malaysia-listing">{{
                                        $t('header.malaysia') }}</router-link></li>
                                    <li><router-link to="/singapore-listing">{{
                                        $t('header.singapore') }}</router-link></li>
                                </ul>
                            </li>
                            <li @click="toggleSubmenu('residential')">
                                <a href="#">{{ $t('header.residential') }}<span class="submenu-indicator"></span></a>
                                <ul v-if="activeSubmenu === 'residential'" class="nav-dropdown nav-submenu">
                                    <li><router-link to="/greece-listing">{{
                                        $t('header.greece') }}</router-link></li>
                                    <li><router-link to="/malaysia-listing">{{
                                        $t('header.malaysia') }}</router-link></li>
                                    <li><router-link to="/singapore-listing">{{
                                        $t('header.singapore') }}</router-link></li>
                                </ul>
                            </li>
                            <li @click="toggleSubmenu('commercial')">
                                <a href="#">{{ $t('header.commercial') }}<span class="submenu-indicator"></span></a>
                                <ul v-if="activeSubmenu === 'commercial'" class="nav-dropdown nav-submenu">
                                    <li><router-link to="/greece-listing">{{
                                        $t('header.greece') }}</router-link></li>
                                    <li><router-link to="/malaysia-listing">{{
                                        $t('header.malaysia') }}</router-link></li>
                                    <li><router-link to="/singapore-listing">{{
                                        $t('header.singapore') }}</router-link></li>
                                </ul>
                            </li>
                            <li @click="toggleSubmenu('land')">
                                <a href="#">{{ $t('header.land') }}<span class="submenu-indicator"></span></a>
                                <ul v-if="activeSubmenu === 'land'" class="nav-dropdown nav-submenu">
                                    <li><router-link to="/greece-listing">{{
                                        $t('header.greece') }}</router-link></li>
                                    <li><router-link to="/malaysia-listing">{{
                                        $t('header.malaysia') }}</router-link></li>
                                    <li><router-link to="/singapore-listing">{{
                                        $t('header.singapore') }}</router-link></li>
                                </ul>
                            </li>
                            <li>
                                <router-link to="/global-visa">{{ $t('header.global_visa') }}</router-link>
                            </li>
                            <li @click="toggleSubmenu('contact_us')">
                                <a href="#">{{ $t('home.contact_us') }}<span class="submenu-indicator"></span></a>
                                <ul v-if="activeSubmenu === 'contact_us'" class="nav-dropdown nav-submenu">
                                    <li class="add-listing"><router-link to="/contact-us">{{
                                        $t('property.submit_enquiry') }}</router-link></li>
                                    <li class="add-listing">
                                        <a href="#scan-wechat" class="d-flex align-items-center" data-bs-toggle="modal"
                                            data-bs-target="#scan-wechat">
                                            <img :src="wechatLogo" class="logo" alt="Global Home Wechat"
                                                style="width: 20px; height: 20px; margin-right: 8px;">
                                            {{ $t('header.wechat') }}
                                        </a>
                                    </li>
                                    <li class="add-listing">
                                        <a href="https://wa.link/fw20eo" class="d-flex align-items-center">
                                            <img :src="whatsappLogo" class="logo" alt="Global Home Whatsapp"
                                                style="width: 20px; height: 20px; margin-right: 8px;">
                                            {{ $t('header.whatsapp') }}
                                        </a>
                                    </li>
                                </ul>
                            </li>
                            <ul class="nav-menu nav-menu-social align-to-right">
                                <li class="nav-item dropdown">
                                    <a href="#" role="button" data-bs-toggle="dropdown">
                                        <img :src="settingsLogo" class="logo" alt="Global Home Settings"
                                            style="width: 30px; height: 30px; margin-left: 100px;">
                                    </a>
                                    <div class="dropdown-menu p-3" aria-labelledby="settingsDropdown">
                                        <div class="mb-2">
                                            <label for="languageSelect" class="form-label">{{ $t('settings.language')
                                                }}</label>
                                            <select v-model="selectedLanguage" class="form-select" id="languageSelect">
                                                <option value="en">English</option>
                                                <option value="zh">中文 (简体)</option>
                                            </select>
                                        </div>
                                        <div class="mb-2">
                                            <label for="currencySelect" class="form-label">{{ $t('settings.currency')
                                                }}</label>
                                            <select v-model="selectedCurrency" class="form-select" id="currencySelect">
                                                <option value="usd">USD</option>
                                                <option value="eur">EUR</option>
                                                <option value="sgd">SGD</option>
                                                <option value="myr">MYR</option>
                                            </select>
                                        </div>
                                        <div class="mb-2">
                                            <label for="measurementSelect" class="form-label">{{
                                                $t('settings.measurement') }}</label>
                                            <select v-model="selectedMeasurement" class="form-select"
                                                id="measurementSelect">
                                                <option value="m2">m²</option>
                                                <option value="ft2">ft²</option>
                                            </select>
                                        </div>
                                        <a href="#" @click.prevent="saveUserPreferences" class="btn btn-dark w-100">
                                            {{ $t('settings.confirm') }}
                                        </a>
                                    </div>
                                </li>

                                <li v-if="!userLoggedIn" class="add-listing">
                                    <a href="submit-property" class="bg-danger d-flex align-items-center"
                                        data-bs-toggle="modal" data-bs-target="#login" style="margin-left: 15px;">
                                        <img :src="loginLogo" class="logo" alt="Global Home Sell Property"
                                            style="width: 20px; height: 20px; margin-right: 8px; " />
                                        {{ $t('header.login') }}
                                    </a>
                                </li>

                                <li v-else @click="toggleSubmenu('user')">
                                    <a href="#"> <img :src="profileLogo" class="logo" alt="Global A Home Profile"
                                            style="width: 30px; height: 30px; margin-right: 8px;" />
                                        Welcome User
                                        <span class="submenu-indicator"></span><span
                                            class="submenu-indicator"></span></a>
                                    <ul v-if="activeSubmenu === 'user'" class="nav-dropdown nav-submenu">
                                        <li><router-link to="/user-profile" class="dropdown-item">{{
                                            $t('header.my_profile')
                                                }}</router-link></li>
                                        <li><router-link to="/user-dashboard" class="dropdown-item">{{
                                            $t('header.my_dashboard')
                                                }}</router-link></li>
                                        <li><router-link to="/user-saved-property" class="dropdown-item">{{
                                            $t('header.saved_property')
                                                }}</router-link></li>
                                        <li><router-link to="/user-change-password" class="dropdown-item">{{
                                            $t('header.change_my_password')
                                                }}</router-link></li>
                                        <li><a @click.prevent="handleLogout" href="#" class="dropdown-item">{{
                                            $t('header.logout') }}</a></li>
                                    </ul>
                                </li>
                                <li class="add-listing">
                                    <a href="#" class="bg-danger d-flex align-items-center"
                                        @click.prevent="handleAddPropertyClick" style="margin-left: 8px;">
                                        <img :src="addProperty" class="logo" alt="Global Home Sell Property"
                                            style="width: 20px; height: 20px; margin-right: 8px;" />
                                        {{ $t('header.add_property') }}
                                    </a>
                                </li>
                                <li>
                                    <a href="#" class="text-primary" data-bs-toggle="offcanvas"
                                        data-bs-target="#offcanvasScrolling" aria-controls="offcanvasScrolling">
                                        <span class="svg-icon svg-icon-2hx">
                                            <svg width="22" height="22" viewBox="0 0 16 15" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <rect y="6" width="16" height="3" rx="1.5" fill="currentColor" />
                                                <rect opacity="0.3" y="12" width="8" height="3" rx="1.5"
                                                    fill="currentColor" />
                                                <rect opacity="0.3" width="12" height="3" rx="1.5"
                                                    fill="currentColor" />
                                            </svg>
                                        </span>
                                    </a>
                                </li>
                            </ul>
                        </ul>
                    </div>
                </div>
            </nav>
        </div>
    </div>
    <!-- ============================ End Navigation ================================== -->

    <!-- Off Canvas Scrolling -->

    <div class="offcanvas offcanvas-end" data-bs-scroll="true" data-bs-backdrop="false" tabindex="-1"
        id="offcanvasScrolling" aria-labelledby="offcanvasScrollingLabel">
        <div class="offcanvas-header">
            <h5 class="offcanvas-title" id="offcanvasScrollingLabel">{{ $t('header.compareSelectedProperty') }}</h5>
            <a href="#" data-bs-dismiss="offcanvas" aria-label="Close">
                <span class="svg-icon text-primary svg-icon-2hx">
                    <svg width="32" height="32" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="currentColor" />
                        <rect x="7" y="15.3137" width="12" height="2" rx="1" transform="rotate(-45 7 15.3137)"
                            fill="currentColor" />
                        <rect x="8.41422" y="7" width="12" height="2" rx="1" transform="rotate(45 8.41422 7)"
                            fill="currentColor" />
                    </svg>
                </span>
            </a>
        </div>
        <div class="offcanvas-body">
            <ul class="nav nav-pills sider_tab mb-3" id="pills-tab" role="tablist">
                <li class="nav-item" role="presentation">
                    <button class="nav-link active" id="pills-compare-tab" data-bs-toggle="pill"
                        data-bs-target="#pills-compare" type="button" role="tab" aria-controls="pills-compare"
                        aria-selected="true">{{ $t('header.compareProperty') }}</button>
                </li>
                <li class="nav-item" role="presentation">
                    <button class="nav-link" id="pills-saved-tab" data-bs-toggle="pill" data-bs-target="#pills-saved"
                        type="button" role="tab" aria-controls="pills-saved" aria-selected="false">{{
                            $t('header.savedProperty') }}</button>
                </li>
            </ul>
            <div class="tab-content" id="pills-tabContent">
                <div class="tab-pane fade show active" id="pills-compare" role="tabpanel"
                    aria-labelledby="pills-compare-tab" tabindex="0">
                    <div class="sidebar_featured_property">

                        <!-- List Sibar Property -->
                        <div class="sides_list_property p-2" v-for="(property, index) in properties" :key="index">
                            <div class="sides_list_property_thumb">
                                <img :src="property.image_link[0]" class="img-fluid" alt="">
                            </div>
                            <div class="sides_list_property_detail">
                                <h4><a :href="'/property/' + property.glb_id">{{ property.property_name }}</a></h4>
                                <span class="text-muted-2"><i class="fa-solid fa-location-dot"></i>{{ property.city }},
                                    {{ property.country }}</span>
                                <div class="lists_property_price">
                                    <div class="lists_property_types">
                                        <div
                                            :class="'property_types_vlix ' + (property.category === 'Rent' ? 'rent' : 'sale')">
                                            {{ property.category === 'Rent' ? 'For Rent' : 'For Sale' }}
                                        </div>
                                    </div>
                                    <div class="lists_property_price_value">
                                        <h4 class="text-primary">{{ property.currency }}{{ property.price }}</h4>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="input-group">
                            <a href="/compare-property" class="btn btn-light-primary fw-medium full-width">{{
                                $t('header.viewCompareProperty') }}</a>
                        </div>
                    </div>
                </div>
                <div class="tab-pane fade" id="pills-saved" role="tabpanel" aria-labelledby="pills-saved-tab"
                    tabindex="0">
                    <div class="sidebar_featured_property">

                        <!-- List Sibar Property -->
                        <div class="sides_list_property p-2" v-for="(property, index) in my_properties" :key="index">
                            <div class="sides_list_property_thumb">
                                <img :src="property.image_link[0]" class="img-fluid" alt="">
                            </div>
                            <div class="sides_list_property_detail">
                                <h4><a :href="'/property/' + property.glb_id">{{ property.property_name }}</a></h4>
                                <span class="text-muted-2"><i class="fa-solid fa-location-dot"></i>{{ property.city }},
                                    {{ property.country }}</span>
                                <div class="lists_property_price">
                                    <div class="lists_property_types">
                                        <div
                                            :class="'property_types_vlix ' + (property.category === 'Rent' ? 'rent' : 'sale')">
                                            {{ property.category === 'Rent' ? 'For Rent' : 'For Sale' }}
                                        </div>
                                    </div>
                                    <div class="lists_property_price_value">
                                        <h4 class="text-primary">{{ property.currency }}{{ property.price }}</h4>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="input-group">
                            <a href="#" class="btn btn-light-primary fw-medium full-width">{{
                                $t('header.viewSavedProperty') }}</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Send Message -->
    <div class="modal fade" id="autho-message" tabindex="-1" role="dialog" aria-labelledby="authomessage"
        aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered login-pop-form" role="document">
            <div class="modal-content" id="authomessage">
                <span class="mod-close" data-bs-dismiss="modal" aria-hidden="true"><i class="ti-close"></i></span>
                <div class="modal-body">
                    <h4 class="modal-header-title">{{ $t('header.dropMessage') }}</h4>
                    <div class="login-form">
                        <form>

                            <div class="form-group mb-3">
                                <label>{{ $t('header.subject') }}</label>
                                <div class="input-with-icons">
                                    <input type="text" class="form-control" placeholder="Message Title">
                                </div>
                            </div>

                            <div class="form-group mb-3">
                                <label>{{ $t('header.messages') }}</label>
                                <div class="input-with-icons">
                                    <textarea class="form-control ht-80"></textarea>
                                </div>
                            </div>

                            <div class="form-group">
                                <button type="submit" class="btn full-width fw-medium btn-danger">{{
                                    $t('header.sendMessage') }}</button>
                            </div>

                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Scan QR code Modal-->
    <div class="modal fade" id="scan-wechat" tabindex="-1" role="dialog" aria-labelledby="authomessage"
        aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered login-pop-form" role="document">
            <div class="modal-content" id="scan-wechat">
                <span class="mod-close" data-bs-dismiss="modal" aria-hidden="true"><i class="ti-close"></i></span>
                <div class="modal-body">
                    <h4 class="modal-header-title">{{ $t('modals.scan_qr_to_connect') }}</h4>
                    <div class="qr-code-container" style="text-align: center;">
                        <img :src="qrCodeLogo" class="logo" alt="Global Home Wechat QR"
                            style="max-width: 100%; height: auto;">
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- End Scan QR code Modal -->
    <!-- Forgot Password Modal -->
    <div class="modal fade" id="forgotPassword" tabindex="-1" role="dialog" aria-labelledby="forgotPasswordModal"
        aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">{{ $t('modals.forgot_password') }}</h5>
                    <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <form @submit.prevent="handleForgotPassword">
                        <div class="form-group">
                            <label>{{ $t('modals.email') }}</label>
                            <input type="email" class="form-control" v-model="forgotPasswordEmail" required
                                :placeholder="$t('modals.enter_email_address')">
                        </div>
                        <div v-if="resetPasswordError" class="alert alert-danger">
                            {{ resetPasswordError }}
                        </div>
                        <div v-if="resetPasswordSuccess" class="alert alert-success">
                            {{ resetPasswordSuccess }}
                        </div>
                        <div class="form-group">
                            <button type="submit" class="btn btn-danger">{{ $t('modals.send_reset_email')
                                }}</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
    <!-- End Forgot Password Modal -->

    <!-- Log In Modal -->
    <div class="modal fade" id="login" tabindex="-1" role="dialog" aria-labelledby="registermodal" aria-hidden="true">
        <div class="modal-dialog modal-xl login-pop-form" role="document">
            <div class="modal-content overli" id="registermodal">
                <div class="modal-body p-0">
                    <div class="resp_log_wrap">
                        <div class="resp_log_thumb"
                            :style="{ backgroundImage: `url(${require('@/assets/img/global_home.png')})` }">
                        </div>
                        <div class="resp_log_caption">
                            <span class="mod-close" data-bs-dismiss="modal" aria-hidden="true" ref="closeBtn"><i
                                    class="ti-close"></i></span>
                            <div class="edlio_152">
                                <ul class="nav nav-pills tabs_system center" id="pills-tab" role="tablist">
                                    <li class="nav-item">
                                        <a class="nav-link active" id="pills-login-tab" data-bs-toggle="pill"
                                            href="#pills-login" role="tab" aria-controls="pills-login"
                                            aria-selected="true"><i class="fas fa-sign-in-alt me-2"></i>{{
                                                $t('modals.login') }}</a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link" id="pills-signup-tab" data-bs-toggle="pill"
                                            href="#pills-signup" role="tab" aria-controls="pills-signup"
                                            aria-selected="false"><i class="fas fa-user-plus me-2"></i>{{
                                                $t('modals.register') }}</a>
                                    </li>
                                </ul>
                            </div>
                            <div class="tab-content" id="pills-tabContent">
                                <div class="tab-pane fade show active" id="pills-login" role="tabpanel"
                                    aria-labelledby="pills-login-tab">
                                    <div class="login-form">
                                        <div v-if="loginError" class="alert alert-danger">
                                            {{ loginError }}
                                        </div>
                                        <form @submit.prevent="handleLogin">
                                            <div class="form-group">
                                                <label>{{ $t('modals.username') }}</label>
                                                <div class="input-with-icon">
                                                    <input type="email" class="form-control" v-model="loginEmail"
                                                        required :placeholder="$t('modals.enter_username')">
                                                    <i class="ti-user"></i>
                                                </div>
                                            </div>

                                            <div class="form-group">
                                                <label>{{ $t('modals.password') }}</label>
                                                <div class="input-with-icon">
                                                    <input type="password" class="form-control" v-model="loginPassword"
                                                        required :placeholder="$t('modals.enter_password')">
                                                    <i class="ti-unlock"></i>
                                                </div>
                                            </div>

                                            <div class="form-group">
                                                <div class="eltio_ol9">
                                                    <div class="eltio_k1">
                                                        <input id="dd" class="form-check-input" name="dd"
                                                            type="checkbox">
                                                        <label for="dd" class="form-check-label"> Remember
                                                            Me</label>
                                                    </div>
                                                    <div class="eltio_k2">
                                                        <a href="#" data-bs-toggle="modal"
                                                            data-bs-target="#forgotPassword">{{
                                                                $t('modals.lost_password') }}</a>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="form-group">
                                                <button type="submit" class="btn btn-danger fw-medium full-width">{{
                                                    $t('modals.login') }}
                                                </button>
                                            </div>

                                            <div class="form-group">
                                                <button @click.prevent="handleGoogleSignIn"
                                                    class="btn btn-outline-danger fw-medium full-width">
                                                    <i class="fab fa-google me-2"></i> {{ $t('modals.login_with_google')
                                                    }}
                                                </button>
                                            </div>

                                        </form>
                                    </div>
                                </div>
                                <div class="tab-pane fade" id="pills-signup" role="tabpanel"
                                    aria-labelledby="pills-signup-tab">
                                    <div class="login-form">
                                        <form @submit.prevent="handleRegister">

                                            <div class="form-group">
                                                <label>{{ $t('modals.full_name') }}</label>
                                                <div class="input-with-icon">
                                                    <input type="text" class="form-control" v-model="fullName" required
                                                        :placeholder="$t('modals.enter_full_name')">
                                                    <i class="ti-user"></i>
                                                </div>
                                            </div>

                                            <div class="form-group">
                                                <label>{{ $t('modals.email') }}</label>
                                                <div class="input-with-icon">
                                                    <input type="email" class="form-control" v-model="registerEmail"
                                                        required :placeholder="$t('modals.enter_email')">
                                                    <i class="ti-user"></i>
                                                </div>
                                            </div>

                                            <div class="form-group">
                                                <label>{{ $t('modals.phone_number') }}</label>
                                                <div class="input-with-icon">
                                                    <input type="tel" class="form-control" v-model="phoneNumber"
                                                        required :placeholder="$t('modals.enter_phone_number')">
                                                    <i class="ti-mobile"></i>
                                                </div>
                                            </div>

                                            <div class="form-group">
                                                <label>{{ $t('modals.password') }}</label>
                                                <div class="input-with-icon">
                                                    <input type="password" class="form-control"
                                                        v-model="registerPassword" required
                                                        :placeholder="$t('modals.enter_password')">
                                                    <i class="ti-unlock"></i>
                                                </div>
                                            </div>

                                            <div class="form-group">
                                                <div class="eltio_ol9">
                                                    <div class="eltio_k1">
                                                        <input id="dds" class="form-check-input" name="dds"
                                                            type="checkbox">
                                                        <label for="dds" class="form-check-label">{{
                                                            $t('modals.terms_conditions') }}</label>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="form-group">
                                                <button type="submit" class="btn btn-danger fw-medium full-width">{{
                                                    $t('modals.register') }}
                                                </button>
                                            </div>

                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- End Modal -->
</template>

<script>
import { auth } from '../../firebase'; // Import the auth object
import {
    signInWithEmailAndPassword,
    createUserWithEmailAndPassword,
    signOut,
    onAuthStateChanged,
    sendPasswordResetEmail,
    getAuth,
    signInWithPopup,
    GoogleAuthProvider,
} from 'firebase/auth';

import globalHomeLogo from '@/assets/img/global_home.png';
import whatsappLogo from '@/assets/img/whatsapp.png';
import wechatLogo from '@/assets/img/wechat.png';
import loginLogo from '@/assets/img/lock.png';
import settingsLogo from '@/assets/img/settings.png';
import qrCodeLogo from '@/assets/img/baizupo_qr.jpeg';
import profileLogo from '@/assets/img/profile.png';
import menuWidget from '@/assets/img/grid-lines.png';
import addProperty from '@/assets/img/add.png';

import { db } from '../../firebase';
import { collection, getDocs, query, where, limit } from 'firebase/firestore';


export default {
    name: 'HomePage',
    data() {
        return {
            addProperty,
            menuWidget,
            profileLogo,
            forgotPasswordEmail: '',
            resetPasswordError: '',
            resetPasswordSuccess: '',
            selectedLanguage: this.$i18n.locale,
            selectedCurrency: 'usd',
            selectedMeasurement: 'm2',
            phoneNumber: '',
            loginEmail: '',
            loginPassword: '',
            fullName: '',
            registerEmail: '',
            registerPassword: '',
            globalHomeLogo,
            whatsappLogo,
            wechatLogo,
            loginLogo,
            settingsLogo,
            qrCodeLogo,
            navMenuVisible: false,
            activeSubmenu: null,
            selectedCategory: 'Buy',
            selectedLocation: '',
            selectedPropertyType: '',
            selectedPriceRange: '',
            properties: [],
            my_properties: [],
            sg_properties: [],
            message: 'Hello, World!',
            userLoggedIn: false,
            loginError: '',
            modal: false,
        };
    },
    async mounted() {
        await this.fetchProperties();
    },
    watch: {
        '$route.query.location': {
            immediate: true,
            handler() {
                this.refreshComponent();
            },
        },
    },
    methods: {
        async handleGoogleSignIn() {
            const auth = getAuth();  // Initialize Firebase Auth
            const provider = new GoogleAuthProvider();  // Set up Google provider

            try {
                const result = await signInWithPopup(auth, provider);  // Trigger Google Sign-In popup
                // Google Access Token
                const credential = GoogleAuthProvider.credentialFromResult(result);
                const token = credential.accessToken;
                console.log("Access Token: ", token);

                // The signed-in user info
                const user = result.user;
                console.log("User Info: ", user);

                // Handle user login state
                this.userLoggedIn = true;
                this.$refs.closeBtn.click(); // Close the modal
            } catch (error) {
                // Handle Errors
                console.error("Error during Google Sign-In:", error);
                alert("Google sign-in failed. Please try again.");
            }
        },
        navigateTo(location) {
            this.$router.push({ path: '/property-listing', query: { location } });
        },
        applyFiltersBasedOnLocation(location) {
            // Your logic to filter properties based on location
            this.filteredProperties = this.properties.filter(
                (property) => property.country.toLowerCase() === location.toLowerCase()
            );
        },
        saveUserPreferences() {
            // Save language preference
            console.log("Language changing to:", this.selectedLanguage);
            localStorage.setItem('userLanguage', this.selectedLanguage);
            this.$i18n.locale = this.selectedLanguage;

            // Save currency preference
            console.log("Currency changing to:", this.selectedCurrency);
            localStorage.setItem('userCurrency', this.selectedCurrency);

            console.log("Measurement changing to:", this.selectedMeasurement);
            localStorage.setItem('userMeasurement', this.selectedMeasurement);

            // Emit an event to notify other components if needed
            this.$emit('preferencesChanged', {
                language: this.selectedLanguage,
                currency: this.selectedCurrency,
                measurement: this.selectedMeasurement,
            });

            this.$forceUpdate(); // Force re-render to apply changes immediately
            this.$router.go(0);  // This reloads the current route
        },
        refreshComponent() {
            this.applyFiltersBasedOnLocation(this.$route.query.location);
        },
        toggleNavMenu() {
            console.log("Toggling navMenuVisible:", this.navMenuVisible);
            this.navMenuVisible = !this.navMenuVisible;
            console.log("New navMenuVisible state:", this.navMenuVisible);
        },
        toggleSubmenu(menu) {
            this.activeSubmenu = this.activeSubmenu === menu ? null : menu;
        },
        async fetchProperties() {
            // Fetch 3 properties from Greece
            const greeceQuery = query(
                collection(db, 'property_listings'),
                where('glb_id', '>=', 'GLB_GR_'),
                where('glb_id', '<', 'GLB_GR_\uf8ff'),
                limit(3)
            );
            const greeceQuerySnapshot = await getDocs(greeceQuery);

            // Map the querySnapshot to extract the data
            this.properties = greeceQuerySnapshot.docs.map(doc => doc.data());

            // Fetch 3 properties from Malaysia
            const malaysiaQuery = query(
                collection(db, 'property_listings'),
                where('glb_id', '>=', 'GLB_MY_SALE_'),
                where('glb_id', '<', 'GLB_MY_SALE_\uf8ff'),
                limit(3)
            );
            const malaysiaQuerySnapshot = await getDocs(malaysiaQuery);
            this.my_properties = malaysiaQuerySnapshot.docs.map(doc => doc.data());

            // Fetch 3 properties from Singapore
            const singaporeQuery = query(
                collection(db, 'property_listings'),
                where('glb_id', '>=', 'GLB_SG_SALE_'),
                where('glb_id', '<', 'GLB_SG_SALE_\uf8ff'),
                limit(3)
            );
            const singaporeQuerySnapshot = await getDocs(singaporeQuery);
            this.sg_properties = singaporeQuerySnapshot.docs.map(doc => doc.data());
        },
        async handleForgotPassword() {
            try {
                await sendPasswordResetEmail(auth, this.forgotPasswordEmail);
                this.resetPasswordSuccess = 'A password reset email has been sent to your email address.';
                this.resetPasswordError = '';
            } catch (error) {
                console.error('Error sending password reset email:', error.message);
                this.resetPasswordError = 'Failed to send password reset email. Please try again.';
                this.resetPasswordSuccess = '';
            }
        },
        handleAddPropertyClick() {
            if (this.userLoggedIn) {
                // If the user is logged in, navigate to the property submission form
                this.$router.push('/submit-property-form');
            } else {
                // If the user is not logged in, show the login modal
                const loginModal = new window.bootstrap.Modal(document.getElementById('login'), {
                    keyboard: false
                });
                loginModal.show();
            }
        },
        async handleLogin() {
            try {
                const userCredential = await signInWithEmailAndPassword(
                    auth,
                    this.loginEmail,
                    this.loginPassword
                );
                // Handle successful login
                console.log('User logged in:', userCredential.user);
                this.userLoggedIn = true; // Set login state to true
                this.loginError = '';
                this.$refs.closeBtn.click();
            } catch (error) {
                console.error('Error logging in:', error.message);
                this.loginError = 'Invalid username or password. Please try again.';
            }
        },
        async handleRegister() {
            try {
                const userCredential = await createUserWithEmailAndPassword(
                    auth,
                    this.registerEmail,
                    this.registerPassword
                );
                // Handle successful registration
                console.log('User registered:', userCredential.user);
                this.userLoggedIn = true; // Set login state to true
                this.$refs.closeBtn.click();
            } catch (error) {
                console.error('Error registering:', error.message);
                // Handle errors (e.g., show an error message)
            }
        },
        async handleLogout() {
            try {
                await signOut(auth);
                this.userLoggedIn = false; // Set login state to false
                console.log('User logged out');
            } catch (error) {
                console.error('Error logging out:', error.message);
            }
        },
        getRouteNameByGlbId(glb_id) {
            if (glb_id.startsWith('GLB_GR_')) {
                return 'GreeceListing';
            } else if (glb_id.startsWith('GLB_MY_SALE_NEW_')) {
                return 'MalaysiaListing';
            } else if (glb_id.startsWith('GLB_MY_SALE_SECOND_')) {
                return 'MalaysiaSubsaleListing';
            } else if (glb_id.startsWith('GLB_MY_RENT_')) {
                return 'MalaysiaRentListing';
            } else if (glb_id.startsWith('GLB_SG_SALE_NEW_')) {
                return 'SingaporeListing';
            } else if (glb_id.startsWith('GLB_SG_SALE_SECOND_')) {
                return 'SingaporeSubsaleListing';
            } else {
                return 'NotFound';
            }
        },
        updateCategory(category) {
            this.selectedCategory = category;
            // this.searchProperties(); // Trigger the search when the category changes
        },
        searchProperties() {
            const queryParams = {
                category: this.selectedCategory,
                location: this.selectedLocation || undefined,  // Avoid empty params
                propertyType: this.selectedPropertyType || undefined,
                priceRange: this.selectedPriceRange || undefined,
            };

            // Remove undefined properties from queryParams
            Object.keys(queryParams).forEach(key => queryParams[key] === undefined && delete queryParams[key]);

            this.$router.push({ path: '/property-listing', query: queryParams });
        },
    },
    created() {
        onAuthStateChanged(auth, (user) => {
            if (user) {
                this.userLoggedIn = true;
            } else {
                this.userLoggedIn = false;
            }
        });

        // Load saved preferences from localStorage
        const savedLanguage = localStorage.getItem('userLanguage');
        const savedCurrency = localStorage.getItem('userCurrency');
        const savedMeasurement = localStorage.getItem('userMeasurement');

        if (savedLanguage) {
            this.selectedLanguage = savedLanguage;
            this.$i18n.locale = savedLanguage;
        }
        if (savedCurrency) {
            this.selectedCurrency = savedCurrency;
        }
        if (savedMeasurement) {
            this.selectedMeasurement = savedCurrency;
        }
    },
};

</script>

<style scoped>
.nav-container {
    margin-bottom: 0px;
    display: flex;
    justify-content: flex-end;
    /* Aligns the button to the right */
}

.nav-dropdown-toggle {
    background-color: white;
    /* Set background color to white */
    color: #000000;
    /* Set icon color */
    padding: 5px;
    /* Adjust padding to ensure icon is centered */
    border: none;
    cursor: pointer;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    /* Center the icon */
    width: 30px;
    /* Set a fixed width for the button */
    height: 30px;
    /* Set a fixed height for the button */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    /* Optional: add a subtle shadow */
}

.nav-dropdown-toggle i {
    margin-left: 0;
    /* Remove any left margin */
}

/* Optional: add transition to make it smooth */
.nav-menu {
    transition: max-height 0.3s ease-out;
}

.hello {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    text-align: center;
    color: #2c3e50;
    margin-top: 60px;
}

.add-listing {
    margin-right: 10px;
    /* Add space between list items */
}

.add-listing a {
    border-radius: 8px;
    /* Optional: Add some border radius for better visual spacing */
    text-align: center;
    /* Center align the text and image */
    white-space: nowrap;
    /* Ensure text doesn't wrap to the next line */
}

.d-flex {
    flex-wrap: nowrap;
    /* Prevent items from wrapping onto the next line */
}

.bg-danger {
    background-color: #d9534f;
    /* Ensure consistent background color */
    color: white;
    /* Text color for contrast */
}

a img {
    vertical-align: middle;
    /* Align the images with text */
}

.input-with-icon select.form-control {
    padding-bottom: 1px;
    /* Adjust the value as needed */
}

.header.header-light {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1000;
    /* Ensures it stays on top of other elements */
    background-color: white;
    /* Keep the background color to avoid transparency issues */
}

#main-wrapper {
    padding-top: 70px;
    /* Adjust this based on your header's height */
}

/* Optional: Add a box-shadow to the fixed navigation for better visual appeal */
.header.header-light {
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.submenu-indicator {
    display: inline-block;
    margin-left: 5px;
    transition: transform 0.3s;
}

.nav-menu li ul {
    display: none;
}

.nav-menu li ul.nav-submenu {
    display: block;
}

.nav-menu li.active>.submenu-indicator {
    transform: rotate(90deg);
}

.resp_log_thumb {
    margin-top: 100px;
    margin-left: 40px;
    width: 250px;
    height: 110px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.form-check-label {
    margin-left: 10px;
}

.tagline {
    margin-top: 150px;
    margin-left: 50px;
}
</style>
