<template>
    <div :key="$i18n.locale" class="yellow-skin">
        <div id="main-wrapper">
           <!-- Start Navigation -->
           <Navigation />
			<!-- ============================ End Navigation ================================== -->
            <div class="clearfix"></div>

            <section class="gallery_parts pt-2 pb-2 d-none d-sm-none d-md-none d-lg-none d-xl-block">
                <div class="container">
                    <div class="row align-items-center">
                        <!-- First Image -->
                        <div class="col-lg-8 col-md-7 col-sm-12 pr-1" v-if="propertyImages.length > 0">
                            <div class="gg_single_part left">
                                <a :href="propertyImages[0]" class="mfp-gallery rounded">
                                    <img :src="propertyImages[0]" class="img-fluid mx-auto rounded" alt="" />
                                </a>
                            </div>
                        </div>
                        <!-- Next 3 Images -->
                        <div class="col-lg-4 col-md-5 col-sm-12 pl-1" v-if="propertyImages.length > 3">
                            <div class="gg_single_part-right min">
                                <a :href="propertyImages[1]" class="mfp-gallery h-100">
                                    <img :src="propertyImages[1]" class="img-fluid full-width rounded object-fit h-100"
                                        alt="" />
                                </a>
                            </div>
                            <div class="gg_single_part-right min mt-3 mb-3">
                                <a :href="propertyImages[2]" class="mfp-gallery h-100">
                                    <img :src="propertyImages[2]" class="img-fluid full-width rounded object-fit h-100"
                                        alt="" />
                                </a>
                            </div>
                            <div class="gg_single_part-right min">
                                <a :href="propertyImages[3]" class="mfp-gallery h-100">
                                    <img :src="propertyImages[3]" class="img-fluid full-width rounded object-fit h-100"
                                        alt="" />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <!-- Featured Slick Gallery Section -->
            <div class="featured_slick_gallery gray d-block d-md-block d-lg-block d-xl-none">
                <div class="featured_slick_gallery-slide">
                    <div v-for="(image, index) in propertyImages" :key="index" class="featured_slick_padd">
                        <a :href="image" class="mfp-gallery">
                            <img :src="image" class="img-fluid mx-auto" alt="" />
                        </a>
                    </div>
                </div>
            </div>

            <!-- ============================ Property Detail Start ================================== -->
            <section class="pt-4">
                <div class="container">
                    <div class="row">

                        <!-- property main detail -->
                        <div class="col-lg-8 col-md-12 col-sm-12">

                            <div class="property_info_detail_wrap mb-4">

                                <div class="property_info_detail_wrap_first">
                                    <div class="pr-price-into">
                                        <ul class="prs_lists">
                                            <li><span class="bed fw-medium rounded">{{ property.country }}</span>
                                            </li>
                                            <li><span class="bath fw-medium rounded">{{ property.city }}</span></li>
                                            <li><span class="gar fw-medium rounded">{{ property.housing }}
                                                    {{ $t('property.property') }}</span></li>
                                        </ul>

                                        <h2>{{ property.property_name }} {{ property.area_address }}</h2>
                                        <span><i class="lni-map-marker"></i> {{ property.area_address }} {{
                                            property.country }}</span>
                                    </div>
                                </div>

                                <div class="property_detail_section">
                                    <div class="prt-sect-pric">
                                        <ul class="_share_lists">
                                            <li><a href="#"><i class="fa fa-bookmark"></i></a></li>
                                            <li><a href="#"><i class="fa fa-share"></i></a></li>
                                        </ul>
                                    </div>
                                </div>

                            </div>

                            <!-- Single Block Wrap -->
                            <div class="property_block_wrap">

                                <div class="property_block_wrap_header">
                                    <h4 class="property_block_title">{{ $t('property.about_property') }}</h4>
                                </div>

                                <div class="block-body">
                                    <p>{{ property.description }}</p>
                                </div>

                            </div>

                            <!-- Single Block Wrap -->
                            <div class="property_block_wrap">

                                <div class="property_block_wrap_header">
                                    <h4 class="property_block_title">{{ $t('property.property_information') }}</h4>
                                </div>

                                <div class="block-body">
                                    <ul class="row p-0 m-0">
                                        <li class="col-lg-4 col-md-6 mb-3 p-0">
                                            <i class="fas fa-users me-1"></i> {{ $t('property.open_to_foreigner') }}
                                        </li>
                                        <li class="col-lg-4 col-md-6 mb-3 p-0">
                                            <i class="fas fa-building me-1"></i> {{ property.property_type }}
                                        </li>
                                        <li class="col-lg-4 col-md-6 mb-3 p-0">
                                            <i class="fas fa-landmark me-1"></i> {{ property.land_tenure }} {{
                                                $t('property.tenure') }}
                                        </li>
                                        <li class="col-lg-4 col-md-6 mb-3 p-0">
                                            <i class="fas fa-ruler me-1"></i> {{ property.land_size }}
                                        </li>
                                        <li class="col-lg-4 col-md-6 mb-3 p-0">
                                            <i class="fas fa-house me-1"></i> {{ property.total_units }} {{
                                                $t('property.units') }}
                                        </li>
                                        <li class="col-lg-4 col-md-6 mb-3 p-0">
                                            <i class="fas fa-archway me-1"></i> {{ property.total_towers }} {{
                                                $t('property.towers') }}
                                        </li>
                                        <li class="col-lg-4 col-md-6 mb-3 p-0">
                                            <i class="fas fa-building me-1"></i> {{ $t('property.completed_in') }} {{
                                                property.completion_year }}
                                        </li>
                                        <li class="col-lg-4 col-md-6 mb-3 p-0">
                                            <i class="fas fa-couch me-1"></i> {{ property.furnishing }} {{
                                                $t('property.furnished') }}
                                        </li>
                                        <li class="col-lg-4 col-md-6 mb-3 p-0">
                                            <i class="fas fa-car me-1"></i> {{ $t('property.free_car_park') }}
                                        </li>
                                        <li class="col-lg-4 col-md-6 mb-3 p-0">
                                            <i class="fas fa-layer-group me-1"></i> {{ property.title_type }} {{
                                                $t('property.title') }}
                                        </li>
                                        <li class="col-lg-4 col-md-6 mb-3 p-0">
                                            <i class="fas fa-star me-1"></i> {{ property.airbnb_management }} {{
                                                $t('property.airbnb_management') }}
                                        </li>
                                    </ul>
                                </div>

                            </div>

                            <!-- Single Block Wrap -->
                            <div class="property_block_wrap">
                                <div class="property_block_wrap_header">
                                    <h4 class="property_block_title">{{ $t('property.facilities') }}</h4>
                                </div>
                                <div class="block-body">
                                    <ul class="avl-features third">
                                        <li v-for="(value, key) in property.facilities" :key="key"
                                            :class="{ active: value }">
                                            {{ formatFeatureLabel(key) }}
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <!-- Single Unit Plan Wrap -->
                            <div v-if="property.unit_info && property.unit_info.unit_type" class="property_block_wrap">
                                <div class="property_block_wrap_header">
                                    <h4 class="property_block_title">{{ $t('property.unit_layout') }}</h4>
                                </div>
                                <div class="block-body">
                                    <div class="accordion" id="floor-option">
                                        <div v-for="(unit, index) in property.unit_info.unit_type" :key="index"
                                            class="card">
                                            <div class="card-header" :id="'floor' + index">
                                                <h2 class="mb-0">
                                                    <button type="button" class="btn btn-link" data-bs-toggle="collapse"
                                                        :data-bs-target="'#collapse' + index">
                                                        {{ $t('property.type') }} {{ unit.type }} - <span>{{ unit.sqft
                                                            }}</span>
                                                    </button>
                                                </h2>
                                                <div class="floor_listeo">
                                                    <ul>
                                                        <li>{{ $t('property.beds') }}: {{ unit.bedroom }}</li>
                                                        <li>{{ $t('property.baths') }}: {{ unit.bathroom }}</li>
                                                        <li>{{ $t('property.area') }}: {{ unit.sqft }}</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div :id="'collapse' + index" class="collapse"
                                                :class="{ show: index === 0 }" :aria-labelledby="'floor' + index"
                                                data-parent="#floor-option">
                                                <div class="card-body">
                                                    <img :src="unit.unit_image_link" class="img-fluid"
                                                        :alt="'Unit ' + unit.type" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!-- Single Property Video Wrap -->
                            <div class="property_block_wrap">
                                <div class="property_block_wrap_header">
                                    <h4 class="property_block_title">{{ $t('property.property_video') }}</h4>
                                </div>
                                <div class="block-body">
                                    <div class="property_video">
                                        <div class="thumb">
                                            <img class="pro_img img-fluid w100" :src="propertyImages[3]" alt="7.jpg">
                                            <div class="overlay_icon">
                                                <div class="bb-video-box">
                                                    <div class="bb-video-box-inner">
                                                        <div class="bb-video-box-innerup">
                                                            <a href="https://www.youtube.com/bVpVjuZAfv0"
                                                                data-bs-toggle="modal" data-bs-target="#popup-video"
                                                                class="theme-cl">
                                                                <i class="ti-control-play"></i>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- End Video Modal -->

                            <!-- Single Block Wrap -->
                            <div class="property_block_wrap">

                                <div class="property_block_wrap_header">
                                    <h4 class="property_block_title">{{ $t('property.location') }}</h4>
                                </div>

                                <div class="block-body">
                                    <div class="map-container">
                                        <iframe :src="property.embed_map_link" class="full-width" height="450"
                                            style="border:0;" allowfullscreen="" loading="lazy"></iframe>
                                    </div>

                                </div>

                            </div>

                            <!-- Single Block Wrap -->
                            <div class="property_block_wrap">

                                <div class="property_block_wrap_header">
                                    <ul class="nav nav-pills tabs_system" id="pill-tab" role="tablist">
                                        <li class="nav-item">
                                            <a class="nav-link active" id="pills-walk-tab" data-bs-toggle="pill"
                                                href="#pills-walk" role="tab" aria-controls="pills-walk"
                                                aria-selected="true">{{ $t('property.walkscore') }}</a>
                                        </li>
                                        <li class="nav-item">
                                            <a class="nav-link" id="pills-nearby-tab" data-bs-toggle="pill"
                                                href="#pills-nearby" role="tab" aria-controls="pills-nearby"
                                                aria-selected="false">{{ $t('property.nearby') }}</a>
                                        </li>
                                    </ul>
                                </div>

                                <div class="block-body">
                                    <div class="sidetab-content">
                                        <div class="tab-content" id="pill-tabContent">
                                            <!-- Book Now Tab -->
                                            <div class="tab-pane fade show active" id="pills-walk" role="tabpanel"
                                                aria-labelledby="pills-walk-tab">
                                                <div class="_walk_score_wrap">

                                                    <!-- Single Item -->
                                                    <div class="_walk_score_list">
                                                        <div class="_walk_score_flex">
                                                            <div class="_walk_score_view">
                                                                <h4 class="view_walk">72</h4>
                                                            </div>
                                                            <div class="_walk_score_caption">
                                                                <h5>{{ $t('property.bikeable_score') }}</h5>
                                                                <span>{{ $t('property.some_bike_infrastructure')
                                                                    }}</span>
                                                            </div>
                                                        </div>
                                                        <div class="_walk_score_last">
                                                            <a href="#" class="_walk_view_btn">{{
                                                                $t('property.view_detail') }}</a>
                                                        </div>
                                                    </div>

                                                    <!-- Single Item -->
                                                    <div class="_walk_score_list">
                                                        <div class="_walk_score_flex">
                                                            <div class="_walk_score_view">
                                                                <h4 class="view_walk">55</h4>
                                                            </div>
                                                            <div class="_walk_score_caption">
                                                                <h5>{{ $t('property.walk_scores') }}</h5>
                                                                <span>{{
                                                                    $t('property.most_errands_accomplished_on_foot')
                                                                    }}</span>
                                                            </div>
                                                        </div>
                                                        <div class="_walk_score_last">
                                                            <a href="#" class="_walk_view_btn">{{
                                                                $t('property.view_detail') }}</a>
                                                        </div>
                                                    </div>

                                                    <!-- Single Item -->
                                                    <div class="_walk_score_list">
                                                        <div class="_walk_score_flex">
                                                            <div class="_walk_score_view">
                                                                <h4 class="view_walk">67</h4>
                                                            </div>
                                                            <div class="_walk_score_caption">
                                                                <h5>{{ $t('property.some_transit') }}</h5>
                                                                <span>{{ $t('property.nearby_public_transport_options')
                                                                    }}</span>
                                                            </div>
                                                        </div>
                                                        <div class="_walk_score_last">
                                                            <a href="#" class="_walk_view_btn">{{
                                                                $t('property.view_detail') }}</a>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>

                                            <!-- Appointment Now Tab -->
                                            <div class="tab-pane fade" id="pills-nearby" role="tabpanel"
                                                aria-labelledby="pills-nearby-tab">
                                                <!-- Schools -->
                                                <div class="nearby-wrap">
                                                    <h5>{{ $t('property.schools') }}</h5>
                                                    <div class="neary_section_list">

                                                        <div class="neary_section">
                                                            <div class="neary_section_first">
                                                                <h4 class="nearby_place_title">Wikdom Senior High Scool
                                                                </h4>
                                                                <span class="location">2455 Cambridge Drive Peoria, AZ
                                                                    85382</span>
                                                            </div>
                                                            <div class="neary_section_last">
                                                                <div class="nearby_place_rate good">4.2</div>
                                                                <div class="rate_starts">
                                                                    <div class="rates_iuol">
                                                                        <i class="fa fa-star filled"></i>
                                                                        <i class="fa fa-star filled"></i>
                                                                        <i class="fa fa-star filled"></i>
                                                                        <i class="fa fa-star filled"></i>
                                                                        <i class="fa fa-star"></i>
                                                                    </div>
                                                                    <span>15 {{ $t('property.reviews') }}</span>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="neary_section">
                                                            <div class="neary_section_first">
                                                                <h4 class="nearby_place_title">Reena Secondary High
                                                                    Scool
                                                                </h4>
                                                                <span>347 Sycamore Circle Grand Forks</span>
                                                            </div>
                                                            <div class="neary_section_last">
                                                                <div class="nearby_place_rate mid">4.0</div>
                                                                <div class="rate_starts">
                                                                    <div class="rates_iuol">
                                                                        <i class="fa fa-star filled"></i>
                                                                        <i class="fa fa-star filled"></i>
                                                                        <i class="fa fa-star filled"></i>
                                                                        <i class="fa fa-star filled"></i>
                                                                        <i class="fa fa-star"></i>
                                                                    </div>
                                                                    <span>19 {{ $t('property.reviews') }}</span>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="neary_section">
                                                            <div class="neary_section_first">
                                                                <h4 class="nearby_place_title">Victory Primary Scool
                                                                </h4>
                                                                <span class="location">4771 Marshall Street Churchville,
                                                                    MD
                                                                    21028</span>
                                                            </div>
                                                            <div class="neary_section_last">
                                                                <div class="nearby_place_rate high">4.8</div>
                                                                <div class="rate_starts">
                                                                    <div class="rates_iuol">
                                                                        <i class="fa fa-star filled"></i>
                                                                        <i class="fa fa-star filled"></i>
                                                                        <i class="fa fa-star filled"></i>
                                                                        <i class="fa fa-star filled"></i>
                                                                        <i class="fa fa-star"></i>
                                                                    </div>
                                                                    <span>32 {{ $t('property.reviews') }}</span>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>

                                                <!-- Hotel & Restaurant -->
                                                <div class="nearby-wrap">
                                                    <h5>{{ $t('property.hotel_restaurant') }}</h5>
                                                    <div class="neary_section_list">

                                                        <div class="neary_section">
                                                            <div class="neary_section_first">
                                                                <h4 class="nearby_place_title">Hotel Singhmind Alite
                                                                </h4>
                                                                <span class="location">492 Buckhannan Liverpool, NY
                                                                    13088</span>
                                                            </div>
                                                            <div class="neary_section_last">
                                                                <div class="nearby_place_rate poor">3.2</div>
                                                                <div class="rate_starts">
                                                                    <div class="rates_iuol">
                                                                        <i class="fa fa-star filled"></i>
                                                                        <i class="fa fa-star filled"></i>
                                                                        <i class="fa fa-star filled"></i>
                                                                        <i class="fa fa-star filled"></i>
                                                                        <i class="fa fa-star"></i>
                                                                    </div>
                                                                    <span>41 {{ $t('property.reviews') }}</span>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="neary_section">
                                                            <div class="neary_section_first">
                                                                <h4 class="nearby_place_title">Wiksy Bar & Restaurant
                                                                </h4>
                                                                <span class="location">3914 Paul Wayne Road Kenner, LA
                                                                    70065</span>
                                                            </div>
                                                            <div class="neary_section_last">
                                                                <div class="nearby_place_rate high">4.9</div>
                                                                <div class="rate_starts">
                                                                    <div class="rates_iuol">
                                                                        <i class="fa fa-star filled"></i>
                                                                        <i class="fa fa-star filled"></i>
                                                                        <i class="fa fa-star filled"></i>
                                                                        <i class="fa fa-star filled"></i>
                                                                        <i class="fa fa-star"></i>
                                                                    </div>
                                                                    <span>21 {{ $t('property.reviews') }}</span>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>

                                                <!-- Hotel & Restaurant -->
                                                <div class="nearby-wrap">
                                                    <h5>{{ $t('property.health_medical') }}</h5>
                                                    <div class="neary_section_list">

                                                        <div class="neary_section">
                                                            <div class="neary_section_first">
                                                                <h4 class="nearby_place_title">Hotel Singhmind Alite
                                                                </h4>
                                                                <span class="location">2600 Avenue Brooklyn, NY
                                                                    11227</span>
                                                            </div>
                                                            <div class="neary_section_last">
                                                                <div class="nearby_place_rate poor">3.2</div>
                                                                <div class="rate_starts">
                                                                    <div class="rates_iuol">
                                                                        <i class="fa fa-star filled"></i>
                                                                        <i class="fa fa-star filled"></i>
                                                                        <i class="fa fa-star filled"></i>
                                                                        <i class="fa fa-star filled"></i>
                                                                        <i class="fa fa-star"></i>
                                                                    </div>
                                                                    <span>14 {{ $t('property.reviews') }}</span>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="neary_section">
                                                            <div class="neary_section_first">
                                                                <h4 class="nearby_place_title">Wiksy Bar & Restaurant
                                                                </h4>
                                                                <span class="location">Peck Street Manchester, NH
                                                                    03101</span>
                                                            </div>
                                                            <div class="neary_section_last">
                                                                <div class="nearby_place_rate high">4.9</div>
                                                                <div class="rate_starts">
                                                                    <div class="rates_iuol">
                                                                        <i class="fa fa-star filled"></i>
                                                                        <i class="fa fa-star filled"></i>
                                                                        <i class="fa fa-star filled"></i>
                                                                        <i class="fa fa-star filled"></i>
                                                                        <i class="fa fa-star"></i>
                                                                    </div>
                                                                    <span>20 {{ $t('property.reviews') }}</span>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="neary_section">
                                                            <div class="neary_section_first">
                                                                <h4 class="nearby_place_title">Wiksy Bar & Restaurant
                                                                </h4>
                                                                <span class="location">Washington Street Port Lavaca, TX
                                                                    77979</span>
                                                            </div>
                                                            <div class="neary_section_last">
                                                                <div class="nearby_place_rate high">4.9</div>
                                                                <div class="rate_starts">
                                                                    <div class="rates_iuol">
                                                                        <i class="fa fa-star filled"></i>
                                                                        <i class="fa fa-star filled"></i>
                                                                        <i class="fa fa-star filled"></i>
                                                                        <i class="fa fa-star filled"></i>
                                                                        <i class="fa fa-star"></i>
                                                                    </div>
                                                                    <span>36 {{ $t('property.reviews') }}</span>
                                                                </div>

                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>

                                            </div>

                                        </div>
                                    </div>
                                </div>

                            </div>


                            <!-- Single Write a Review -->
                            <div class="property_block_wrap">

                                <div class="property_block_wrap_header">
                                    <h4 class="property_block_title">{{ $t('property.want_to_know_more') }}</h4>
                                </div>

                                <div class="block-body">
                                    <div class="row">

                                        <div class="col-lg-6 col-md-6 col-sm-12">
                                            <div class="form-group">
                                                <label>{{ $t('form.name') }}</label>
                                                <input type="text" class="form-control">
                                            </div>
                                        </div>

                                        <div class="col-lg-6 col-md-6 col-sm-12">
                                            <div class="form-group">
                                                <label>{{ $t('form.phone_number') }}</label>
                                                <input type="email" class="form-control">
                                            </div>
                                        </div>

                                        <div class="col-lg-12 col-md-12 col-sm-12">
                                            <div class="form-group">
                                                <label>{{ $t('form.messages') }}</label>
                                                <textarea class="form-control ht-80"></textarea>
                                            </div>
                                        </div>

                                        <div class="col-lg-12 col-md-12 col-sm-12">
                                            <div class="form-group">
                                                <button class="btn btn-danger rounded" type="submit">{{
                                                    $t('form.get_latest_offer') }}</button>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                            </div>

                        </div>

                        <!-- property Sidebar -->
                        <div class="col-lg-4 col-md-12 col-sm-12">
                            <div class="property-sidebar">

                                <!-- Mortgage Calculator -->
                                <div class="sider_blocks_wrap">
                                    <div class="side-booking-header">
                                        <div class="sb-header-left">
                                            <h3 class="price">RM{{ property.price }}<sub> RM{{ property.psf
                                                    }}/ft²</sub><span class="offs"></span></h3>
                                        </div>
                                        <div class="price_offer bg-seegreen">{{ $t('property.sale') }}</div>
                                    </div>
                                    <div class="side-booking-header">
                                        <h4 class="m-0">{{ $t('property.mortgage_calculator') }}</h4>
                                    </div>

                                    <div class="sider-block-body p-3">
                                        <div class="form-group">
                                            <div class="input-with-icon">
                                                <input v-model.number="salePrice" type="text" class="form-control light"
                                                    :placeholder="$t('property.sale_price')">
                                                <i class="ti-money"></i>
                                            </div>
                                        </div>

                                        <div class="form-group">
                                            <div class="input-with-icon">
                                                <input v-model.number="downPayment" type="text"
                                                    class="form-control light"
                                                    :placeholder="$t('property.down_payment')">
                                                <i class="ti-money"></i>
                                            </div>
                                        </div>

                                        <div class="form-group">
                                            <div class="input-with-icon">
                                                <input v-model.number="loanTerm" type="text" class="form-control light"
                                                    :placeholder="$t('property.loan_term')">
                                                <i class="ti-calendar"></i>
                                            </div>
                                        </div>

                                        <div class="form-group">
                                            <div class="input-with-icon">
                                                <input v-model.number="interestRate" type="text"
                                                    class="form-control light"
                                                    :placeholder="$t('property.interest_rate')">
                                                <i class="fa fa-percent"></i>
                                            </div>
                                        </div>

                                        <div class="mortgage mb-2">{{ $t('property.monthly_payment') }}: {{
                                            formattedMonthlyPayment }}</div>
                                        <button @click="calculateMortgage"
                                            class="btn btn-light-danger fw-medium full-width">{{
                                                $t('property.calculate') }}</button>
                                    </div>
                                </div>

                                <!-- Similar Property -->
                                <div class="sidebar-widgets">

                                    <h4>{{ $t('property.similar_property') }}</h4>

                                    <div class="sidebar_featured_property">

                                        <!-- List Sibar Property -->
                                        <div v-for="(similarProperty, index) in similarProperties" :key="index"
                                            class="sides_list_property">
                                            <div class="sides_list_property_thumb">
                                                <img :src="similarProperty.image_link[0]" class="img-fluid" alt="" />
                                            </div>
                                            <div class="sides_list_property_detail">
                                                <h4><a :href="'/malaysia-property-detail/' + similarProperty.glb_id">
                                                        {{ similarProperty.property_name }}</a></h4>
                                                <span><i class="ti-location-pin"></i>{{ similarProperty.area_address }},
                                                    {{ similarProperty.country }}</span>
                                                <div class="lists_property_price">
                                                    <div class="lists_property_types">
                                                        <div
                                                            :class="['property_types_vlix', (similarProperty.sale_rent || 'sale') === $t('property.sale') ? $t('property.sale') : $t('property.rent')]">
                                                            {{ $t('property.for') }} {{ (similarProperty.sale_rent ||
                                                                'sale').charAt(0).toUpperCase() + (similarProperty.sale_rent
                                                                    || 'sale').slice(1) }}
                                                        </div>

                                                    </div>
                                                    <div class="lists_property_price_value">
                                                        <h4>RM{{ similarProperty.price }}</h4>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            </section>
            <!-- ============================ Property Detail End ================================== -->
            <!-- ============================ Call To Action ================================== -->
            <PageFooter />
            <!-- ============================ Call To Action End ================================== -->

            <!-- ============================ Footer Start ================================== -->
            
            <!-- ============================ Footer End ================================== -->

            <!-- Video Modal -->
            <div class="modal fade" id="popup-video" tabindex="-1" role="dialog" aria-labelledby="popup-video"
                aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                    <div class="modal-content">
                        <div class="modal-body p-0">
                            <div class="embed-responsive embed-responsive-16by9">
                                <iframe width="1020" height="630" :src="property.video_link" frameborder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowfullscreen>
                                </iframe>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- End Video Modal -->

        </div>
    </div>
</template>

<script>
import $ from 'jquery';
import 'magnific-popup';
import Navigation from '@/components/Navigation.vue';
import PageFooter from './PageFooter.vue';
import globalHomeLogo from '@/assets/img/global_home.png';
import whatsappLogo from '@/assets/img/whatsapp.png';
import wechatLogo from '@/assets/img/wechat.png';
import loginLogo from '@/assets/img/lock.png';
import settingsLogo from '@/assets/img/settings.png';
import qrCodeLogo from '@/assets/img/baizupo_qr.jpeg';
import sampleImage1 from '@/assets/img/p-4.webp';
import profileLogo from '@/assets/img/profile.png';
import menuWidget from '@/assets/img/grid-lines.png';
import addProperty from '@/assets/img/add.png';

// Firebase imports
import { db } from '../../firebase';
import { doc, getDoc, query, collection, where, getDocs } from 'firebase/firestore'; // Add this line
import { nextTick } from 'vue';


export default {
    name: 'MalaysiaListing',
    props: ['glb_id'],
    components: {
		Navigation,
        PageFooter,
	},
    data() {
        return {
            addProperty,
            menuWidget,
            profileLogo,
            globalHomeLogo,
            whatsappLogo,
            wechatLogo,
            loginLogo,
            settingsLogo,
            qrCodeLogo,
            sampleImage1,
            navMenuVisible: false,
            activeSubmenu: null,
            loginError: '',
            property: {},
            propertyImages: [],
            similarProperties: [],
            salePrice: null,
            downPayment: null,
            loanTerm: null,
            interestRate: null,
            monthlyPayment: 0,
            userLoggedIn: false,
            unit_type: [],
        };
    },
    computed: {
        formattedMonthlyPayment() {
            return this.monthlyPayment ? `$${this.monthlyPayment.toFixed(2)}` : '$0.00';
        }
    },
    methods: {
        toggleNavMenu() {
            this.navMenuVisible = !this.navMenuVisible;
        },
        toggleSubmenu(menu) {
            this.activeSubmenu = this.activeSubmenu === menu ? null : menu;
        },
        async fetchProperty(glb_id) {
            try {
                const docRef = doc(db, "property_listings", glb_id);
                const docSnap = await getDoc(docRef);
                if (docSnap.exists()) {
                    this.property = docSnap.data();
                    this.propertyImages = this.property.image_link || []; // Assign the images array
                    // Reinitialize the gallery after images are loaded
                    await nextTick();
                    this.initGallery();
                } else {
                    console.log("No such property!");
                }
            } catch (error) {
                console.error("Error fetching property:", error);
            }
        },
        async fetchSimilarProperties() {
            try {
                const q = query(
                    collection(db, "property_listings"),
                    where("glb_id", ">=", "GLB_MY_"),
                    where("glb_id", "<", "GLB_MY_~")
                );
                const querySnapshot = await getDocs(q);
                this.similarProperties = querySnapshot.docs
                    .map(doc => doc.data())
                    .filter(property => property.glb_id !== this.glb_id); // Exclude the current property
            } catch (error) {
                console.error("Error fetching similar properties:", error);
            }
        },
        formatFeatureLabel(key) {
            return key.replace(/_/g, ' ').replace(/\b\w/g, char => char.toUpperCase());
        },
        initGallery() {
            if ($ && $.fn.magnificPopup) {
                $('.mfp-gallery').magnificPopup({
                    type: 'image',
                    gallery: {
                        enabled: true
                    },
                    mainClass: 'mfp-fade',
                    removalDelay: 300,
                });
            } else {
                console.error("jQuery or Magnific Popup not found.");
            }
        },
        calculateMortgage() {
            const principal = this.salePrice - this.downPayment;
            const monthlyInterestRate = this.interestRate / 100 / 12;
            const numberOfPayments = this.loanTerm * 12;

            if (monthlyInterestRate === 0) {
                this.monthlyPayment = principal / numberOfPayments;
            } else {
                this.monthlyPayment = (principal * monthlyInterestRate) / (1 - Math.pow(1 + monthlyInterestRate, -numberOfPayments));
            }
        },
    },
    created() {
        // const glb_id = 'GLB_GR_000001'; // Replace with actual glb_id
        this.fetchProperty(this.glb_id);
        this.fetchSimilarProperties(); // Fetch similar properties when the component is created
    },

    mounted() {
        this.initGallery();
    },
    updated() {
        this.initGallery();
    }
};
</script>

<style scoped>
.nav-container {
    margin-bottom: 0px;
    display: flex;
    justify-content: flex-end;
    /* Aligns the button to the right */
}

.nav-dropdown-toggle {
    background-color: white;
    /* Set background color to white */
    color: #000000;
    /* Set icon color */
    padding: 5px;
    /* Adjust padding to ensure icon is centered */
    border: none;
    cursor: pointer;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    /* Center the icon */
    width: 30px;
    /* Set a fixed width for the button */
    height: 30px;
    /* Set a fixed height for the button */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    /* Optional: add a subtle shadow */
}

.nav-dropdown-toggle i {
    margin-left: 0;
    /* Remove any left margin */
}

/* Optional: add transition to make it smooth */
.nav-menu {
    transition: max-height 0.3s ease-out;
}

.hello {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    text-align: center;
    color: #2c3e50;
    margin-top: 60px;
}

.add-listing {
    margin-right: 10px;
}

.add-listing a {
    border-radius: 8px;
    text-align: center;
    white-space: nowrap;
}

.d-flex {
    flex-wrap: nowrap;
}

.bg-danger {
    background-color: #d9534f;
    color: white;
}

a img {
    vertical-align: middle;
}

.header.header-light {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1000;
    background-color: white;
}

#main-wrapper {
    padding-top: 70px;
}

.header.header-light {
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.submenu-indicator {
    display: inline-block;
    margin-left: 5px;
    transition: transform 0.3s;
}

.nav-menu li ul {
    display: none;
}

.nav-menu li ul.nav-submenu {
    display: block;
}

.nav-menu li.active>.submenu-indicator {
    transform: rotate(90deg);
}

.resp_log_thumb {
    margin-top: 100px;
    margin-left: 40px;
    width: 250px;
    height: 110px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.form-check-label {
    margin-left: 10px;
}

.tagline {
    margin-top: 150px;
    margin-left: 50px;
}
</style>
